import { Grid, LinearProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetMemberByIdQuery } from '../../redux/api/api'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import BorderSelectButton from '../buttons/BorderSelectButton'
import TitleC from '../text/TitleC'

export default function FitnessGoals(props) {
  const { onboarding } = props

  const dispatch = useDispatch()

  const cognitoUser = useSelector((state) => state.auth.cognitoUser)

  const fitnessList = [
    'Be More Active',
    'Improve Endurance',
    'Lose Weight',
    'Injury Recovery',
    'Improve Flexibility',
    'Build Muscle',
    'Toning',
    'Body Recomposition',
    'Train for Competition',
  ]

  const { data, isLoading, isSuccess, isError, error } = useGetMemberByIdQuery(
    cognitoUser?.sub,
    { skip: !cognitoUser?.sub }
  )

  const [fitnessGoals, setFitnessGoals] = useState([])

  useEffect(() => {
    if (onboarding) {
      setFitnessGoals([])
      return
    }
    if (isSuccess) {
      setFitnessGoals([])
      //setFitnessGoals(JSON.parse(data?.fitnessGoals))
    }
  }, [data])

  const handleFitnessGoalsClick = (fitness) => {
    const copy = [...fitnessGoals]
    const filtered = copy.includes(fitness)
      ? fitnessGoals.filter(function (item) {
          return item !== fitness
        })
      : [...fitnessGoals, fitness]
    console.log('filtered', filtered)
    setFitnessGoals(() => {
      return fitnessGoals.includes(fitness)
        ? fitnessGoals.filter(function (item) {
            return item !== fitness
          })
        : [...fitnessGoals, fitness]
    })
    dispatch(
      setUserProfileModalField({ field: 'fitnessGoals', value: filtered })
    )
  }

  if (isLoading) {
    return <LinearProgress />
  }

  return (
    <>
      <Grid item xs={12}>
        <TitleC text="What are your fitness goals?" />
      </Grid>
      <Grid
        container
        style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
        spacing={'12px'}
      >
        {fitnessList.map((fitness) => {
          return (
            <Grid item>
              <BorderSelectButton
                active={fitnessGoals.includes(fitness)}
                text={fitness}
                onClick={() => handleFitnessGoalsClick(fitness)}
              />
            </Grid>
          )
        })}
      </Grid>
      <Grid container xs={12}>
        <Typography
          align="center"
          sx={{
            paddingTop: '32px',
            paddingBottom: '32px',
            width: 290,
            color: 'primary.main',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '15px',
          }}
        >
          The more information you provide, the more personalized your workouts
          will be.
        </Typography>
      </Grid>
    </>
  )
}
