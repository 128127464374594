import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    becomeTrainerModalOpen: false,
    trainerAvailabilityModalOpen: false,
    onboardOpen: false,
    onboardStep: 1,
    sessionRequestOpen: false,
    generalModalOpen: false,
    generalModalComponent: null,
    editMode: false,
    authModalOpen: false,
    virtualSessionModalOpen: false,
    errorAlertOpen: false,
    errorAlertMessage: '',
    viewTrainerId: null,
    viewMemberId: null,
    headerSearch: {
      city: null,
      state: null,
      date: null,
      time: null,
      workoutTypes: [],
    },
    savedUrls: [],
  },
  reducers: {
    setBecomeTrainerModalOpen: (state, action) => {
      state.becomeTrainerModalOpen = action.payload
    },
    setHeaderSearchData: (state, action) => {
      state.headerSearch[action.payload.field] = action.payload.value
    },
    closeErrorAlert: (state, action) => {
      state.errorAlertMessage = ''
      state.errorAlertOpen = false
    },
    setErrorAlert: (state, action) => {
      state.errorAlertMessage = action.payload
      state.errorAlertOpen = true
    },
    setAuthModalOpen: (state, action) => {
      state.authModalOpen = action.payload
    },
    setOnboardOpen: (state, action) => {
      state.onboardOpen = action.payload
    },
    setOnboardStep: (state, action) => {
      state.onboardStep = action.payload
    },
    setSessionRequestOpen: (state, action) => {
      state.sessionRequestOpen = !state.sessionRequestOpen
    },
    setTrainerAvailabilityModalVisible: (state, action) => {
      state.trainerAvailabilityModalOpen = action.payload
    },
    setGeneralModalOpen: (state, action) => {
      state.generalModalOpen = action.payload
    },
    setVirtualSessionModalOpen: (state, action) => {
      state.virtualSessionModalOpen = action.payload
    },
    setGeneralModalComponent: (state, action) => {
      state.generalModalComponent = action.payload
    },
    setEditMode: (state, action) => {
      state.editMode = !state.editMode
    },
    todoAdded2(state, action) {
      state.push({
        id: action.payload.id,
        text: action.payload.text,
        completed: false,
      })
    },
    todoToggled2(state, action) {
      const todo = state.find((todo) => todo.id === action.payload)
      todo.completed = !todo.completed
    },
    setViewTrainerId(state, action) {
      state.viewTrainerId = action.payload
    },
    setViewMemberId(state, action) {
      state.viewMemberId = action.payload
    },
    updateSavedUrls(state, action) {
      if (state.savedUrls[action.payload.imagePath]) {
        state.savedUrls[action.payload.imagePath] = action.payload.url
      } else {
        state.savedUrls = {
          ...state.savedUrls,
          [action.payload.imagePath]: action.payload.url,
        }
      }
    },
  },
})

export const {
  setBecomeTrainerModalOpen,
  closeErrorAlert,
  setErrorAlert,
  setAuthModalOpen,
  todoAdded2,
  todoToggled2,
  setOnboardOpen,
  setOnboardBOpen,
  setOnboardStep,
  setSessionRequestOpen,
  setTrainerAvailabilityModalVisible,
  setGeneralModalOpen,
  setGeneralModalComponent,
  setEditMode,
  setViewTrainerId,
  setViewMemberId,
  setHeaderSearchData,
  updateSavedUrls,
  setVirtualSessionModalOpen,
} = uiSlice.actions
export default uiSlice.reducer
