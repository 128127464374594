import { Button, styled } from '@mui/material'

const FitnessGoal = (props) => {
  const { text } = props
  const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '25.5px',
    padding: '10.5px 16px',
    marginRight: '16px',
    '&:disabled': {
      backgroundColor: theme.palette.primary.light,
      color: 'gunMetal.main',
    },
  }))
  return (
    <StyledButton variant="contained" disabled>
      {text}
    </StyledButton>
  )
}

export default FitnessGoal
