import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import DashboardOverview from './DashboardOverview'
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import PaymentsHistoryTable from './PaymentsHistoryTable'
import { useDispatch, useSelector } from 'react-redux'
import BorderColorButton from '../buttons/BorderColorButton'
import { Amplify, Storage } from 'aws-amplify'
import { Add, Download, Upload } from '@mui/icons-material'
import PaymentMethodCard from '../payments/PaymentMethodCard'
import GeneralModal from '../modals/GeneralModal'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../redux/slices/uiSlice'
import {
  setStripeIntent,
  setStripeClientSecret,
} from '../../redux/slices/paymentsSlice'
import axios from 'axios'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function DashboardPage() {
  const isTrainer = useSelector((state) => state.auth.isTrainer)
  const stripeCustomerId = useSelector((state) => state.auth.stripeCustomerId)
  const [value, setValue] = React.useState(0)
  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [month, setMonth] = React.useState('')

  const handleChangeMonth = (event) => {
    setMonth(event.target.value)
  }

  const contractorFields = [
    'Full legal name or business entity',
    'SS number or EIN',
    'Street address',
    'Unit number',
    'City',
    'State',
    'Zip code',
  ]

  const handleW9Upload = async (e) => {
    console.debug('handle w9 upload', e)
    const file = e.target.files[0]
    try {
      await Storage.put('Sample W9', file)
      console.debug('Successful')
    } catch (error) {
      console.log('Error uploading file: ', error)
    }
  }

  const addPaymentMethod = async () => {
    try {
      const stripeIntent = await axios.post(
        'https://auibul7a6pvvdywpofvw5rkkgy0kfduu.lambda-url.us-east-2.on.aws/',
        {
          //customerId: stripeCustomerId,
          customerId: stripeCustomerId,
        }
      )
      console.log('stripeIntent.data', stripeIntent.data)

      const secret = stripeIntent.data.client_secret
      console.log('secret', secret)
      //dispatch(setStripeIntent(stripeIntent.data.id))
      dispatch(setStripeClientSecret(secret))
      dispatch(setGeneralModalComponent('payment_method'))
      dispatch(setGeneralModalOpen(true))
    } catch (error) {}
  }

  return (
    <Box sx={{ width: '100%', bgcolor: 'rgba(246, 247, 248, 1)' }}>
      <GeneralModal />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Banking" {...a11yProps(1)} />
          <Tab
            label={isTrainer ? 'Transactions' : 'Payment History'}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DashboardOverview />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 26,
            color: 'gunMetal.main',
            lineHeight: '39px',
          }}
        >
          Payment Information
        </Typography>
        <Box sx={{ bgcolor: 'white', borderRadius: '20px' }}>
          <Grid container justifyContent="left" sx={{ p: 2, m: 1 }}>
            <Grid item sx={{ p: 1, m: 1 }}>
              <Button
                variant="outlined"
                onClick={addPaymentMethod}
                sx={{ color: 'gunMetal.main' }}
              >
                <Add sx={{ color: 'primary.main', pr: 1 }} /> Payment Method
              </Button>
            </Grid>
            <Grid item sx={{ p: 1, m: 1 }}>
              <Button variant="outlined" disabled>
                {' '}
                <Add sx={{ pr: 1 }} /> Payment Method 2nd Payment Method
              </Button>
            </Grid>
          </Grid>
        </Box>
        {isTrainer && (
          <Grid container>
            <Grid container justifyContent="flex-start" sx={{ pb: 2 }}>
              <Typography
                sx={{
                  color: 'gunMetal.main',
                  fontWeight: 700,
                  fontSize: '26px',
                  lineHeight: '39px',
                }}
              >
                {' '}
                Independent Contractor
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              sx={{ bgcolor: 'white', borderRadius: '20px', p: 2 }}
            >
              <Typography
                sx={{
                  color: 'gunMetal.main',
                  fontWeight: 700,
                  fontSize: '17px',
                  lineHeight: '25.5px',
                  p: 2,
                }}
              >
                {' '}
                You are considered an independent contractor, and therefore will
                recieve a 1099 form once per quarter.
              </Typography>

              {contractorFields.map((field, index) => (
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Grid container justifyContent="flex-end">
                      <Typography>{field}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      placeholder={field}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              ))}
            </Grid>
            <Grid container justifyContent="flex-start" sx={{ pb: 2, pt: 2 }}>
              <Typography
                sx={{
                  color: 'gunMetal.main',
                  fontWeight: 700,
                  fontSize: '26px',
                  lineHeight: '39px',
                }}
              >
                {' '}
                Tax Forms
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              sx={{ bgcolor: 'white', borderRadius: '20px', p: 2 }}
            >
              <Typography
                sx={{
                  color: 'gunMetal.main',
                  fontWeight: 700,
                  fontSize: '17px',
                  lineHeight: '25.5px',
                  p: 2,
                }}
              >
                {' '}
                Download, fill out, and upload our W9 form
              </Typography>
              <Grid container justifyContent="flex-start" spacing={4}>
                <Grid item>
                  <Grid container sx={{ p: 2 }}>
                    <Typography> Download here</Typography>
                  </Grid>
                  <Grid container>
                    <Button variant="outlined" sx={{ p: 2 }}>
                      <Download />
                      W9 Form
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container sx={{ p: 2 }}>
                    <Typography> Upload here</Typography>
                  </Grid>
                  <Grid container>
                    <input
                      id="w9-upload"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleW9Upload}
                    />
                    <Button
                      variant="outlined"
                      sx={{ p: 2 }}
                      onClick={() =>
                        document.getElementById('w9-upload').click()
                      }
                    >
                      <Upload />
                      W9 Form
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {!isTrainer && (
          <Grid container justifyContent="space-between">
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 26,
                color: 'gunMetal.main',
                lineHeight: '39px',
              }}
            >
              Payments
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 26,
                color: 'gunMetal.main',
                lineHeight: '39px',
              }}
            >
              Available Statements{' '}
            </Typography>
          </Grid>
        )}
        {isTrainer && (
          <Grid container>
            <Grid container justifyContent="flex-start" spacing={3}>
              <Grid item>
                <Grid container>
                  <Typography>Available Statements</Typography>
                </Grid>
                <Grid container justifyContent="flex-start">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={month}
                    label="Month"
                    onChange={handleChangeMonth}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>June</MenuItem>
                    <MenuItem value={20}>July</MenuItem>
                    <MenuItem value={30}>August</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Typography>Sort Payouts</Typography>
                </Grid>
                <Grid container justifyContent="flex-start">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={month}
                    label="Month"
                    onChange={handleChangeMonth}
                  >
                    <MenuItem value={10}>All</MenuItem>
                    <MenuItem value={20}>July</MenuItem>
                    <MenuItem value={30}>August</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" sx={{ pb: 2 }}>
              <Typography>Total Income (YTD): $521.52</Typography>
            </Grid>
          </Grid>
        )}

        {!isTrainer && (
          <Grid container justifyContent="right" sx={{ pb: 2 }}>
            <Select
              sx={{ bgcolor: 'white', borderRadius: '20px' }}
              displayEmpty
              labelId="demo-simple-select-label2"
              id="demo-simple-select"
              value={month}
              label="Month"
              onChange={handleChangeMonth}
            >
              <MenuItem value="">
                <em>Month</em>
              </MenuItem>
              <MenuItem value={10}>June</MenuItem>
              <MenuItem value={20}>July</MenuItem>
              <MenuItem value={30}>August</MenuItem>
            </Select>
          </Grid>
        )}

        <PaymentsHistoryTable />
      </TabPanel>
    </Box>
  )
}
