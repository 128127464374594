import * as React from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { Grid, Typography, Divider, Chip, TextField } from '@mui/material'
import BorderSelectButton from '../../buttons/BorderSelectButton'
import { Close, FilterListOutlined, InfoOutlined } from '@mui/icons-material'
import { useState } from 'react'
import FilterTextField from '../FilterTextField'

export default function FilterDrawer() {
  const [open, setOpen] = React.useState(false)
  const [how, setHow] = useState([])
  const [where, setWhere] = useState([])
  const [who, setWho] = useState([])
  const [gear, setGear] = useState([])
  const toggleDrawer = () => (event) => {
    setOpen(!open)
  }

  const list = () => (
    <Box
      sx={{ width: 400, pt: '75px' }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <Grid container sx={{ p: 1, pb: 0 }}>
        <Grid item sx={{ ml: 'auto' }}>
          <Close onClick={toggleDrawer()} />
        </Grid>
      </Grid>
      <Grid container sx={{ p: 2, pt: 0 }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: '26px', color: 'primary.main' }}
        >
          Filters and Preferences
        </Typography>
      </Grid>
      <Divider textAlign="left">
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '17px' }}
        >
          HOW
        </Typography>
      </Divider>
      <Grid
        container
        sx={{ justifyContent: 'space-around', mt: 1, pl: 3, pr: 3 }}
        spacing={1}
      >
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="In Person"
            active={how.inPerson}
            onClick={(e) => {
              setHow({ ...how, inPerson: !how.inPerson })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Virtual"
            active={how.virtual}
            onClick={(e) => {
              setHow({ ...how, virtual: !how.virtual })
            }}
          />
        </Grid>
      </Grid>

      <Divider textAlign="left">
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '17px' }}
        >
          WHERE
        </Typography>
      </Divider>

      <Grid
        container
        sx={{ justifyContent: 'space-around', mt: 1, pl: 3, pr: 3 }}
        spacing={1}
      >
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Outdoors"
            active={where.outdoors}
            onClick={(e) => {
              setWhere({ ...where, outdoors: !where.outdoors })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Indoors"
            active={where.indoors}
            onClick={(e) => {
              setWhere({ ...where, indoors: !where.indoors })
            }}
          />
        </Grid>
        <Grid item xs={6} justifyContent="flex-start" sx={{ pt: 2, pl: 5 }}>
          <FilterTextField fullWidth placeholder={'MAX DISTANCE ->'} />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>

      <Divider textAlign="left">
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '17px' }}
        >
          HOW MUCH
        </Typography>
      </Divider>

      <Grid
        container
        sx={{ justifyContent: 'space-around', mt: 1, p: 1, pl: 3, pr: 3 }}
        spacing={1}
      >
        <Grid item xs={6}>
          <FilterTextField fullWidth placeholder={'MIN PRICE'} />
        </Grid>
        <Grid item xs={6}>
          <FilterTextField fullWidth placeholder={'MAX PRICE'} />
        </Grid>
      </Grid>

      <Divider textAlign="left">
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '17px' }}
        >
          WHO
        </Typography>
      </Divider>

      <Grid
        container
        spacing={1}
        sx={{ justifyContent: 'space-between', mt: 1, p: 1, pl: 3, pr: 3 }}
      >
        <Grid item xs={12}>
          <Typography>Trainer Preference</Typography>
        </Grid>
        <Grid item justifyContent="flex-start" xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Any"
            active={who.any}
            onClick={(e) => {
              setWho({
                ...who,
                any: !who.any,
                male: !who.any,
                female: !who.any,
              })
            }}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Female"
            active={who.female}
            onClick={(e) => {
              setWho({
                ...who,
                female: !who.female,
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Male"
            active={who.male}
            onClick={(e) => {
              setWho({
                ...who,
                male: !who.male,
              })
            }}
          />
        </Grid>
        <Grid container justifyContent="flex-start" sx={{ p: 2, pl: 0 }}>
          <Typography>Trainer Style</Typography>
          <InfoOutlined color="error" />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Cheerleader"
            active={who.cheerleader}
            onClick={(e) => {
              setWho({
                ...who,
                cheerleader: !who.cheerleader,
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Drill Sargeant"
            active={who.sargeant}
            onClick={(e) => {
              setWho({
                ...who,
                sargeant: !who.sargeant,
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Workout Buddy"
            active={who.buddy}
            onClick={(e) => {
              setWho({
                ...who,
                buddy: !who.buddy,
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Professor"
            active={who.professor}
            onClick={(e) => {
              setWho({
                ...who,
                professor: !who.professor,
              })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Trainer by name</Typography>
        </Grid>
        <Grid item xs={12}>
          <FilterTextField
            fullWidth
            placeholder={'Search trainer by first name, last initial'}
          />
        </Grid>
      </Grid>

      <Divider textAlign="left">
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '17px' }}
        >
          GEAR
        </Typography>
      </Divider>

      <Grid
        container
        sx={{ justifyContent: 'space-around', mt: 1, pl: 3, pr: 3 }}
        spacing={1}
      >
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Have Some"
            active={gear.has}
            onClick={(e) => {
              setGear({
                ...gear,
                has: !gear.has,
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <BorderSelectButton
            light
            fullWidth
            text="Need Some"
            active={gear.need}
            onClick={(e) => {
              setGear({
                ...gear,
                need: !gear.need,
              })
            }}
          />
        </Grid>
      </Grid>

      <Divider textAlign="left">
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '17px' }}
        >
          WHAT
        </Typography>
      </Divider>

      <Grid container sx={{ justifyContent: 'space-around', mt: 1, p: 1 }}>
        <Grid item xs={12}>
          <Typography>Sub type/specialties</Typography>
        </Grid>
        <Grid container justifyContent="flex-start" spacing={1} sx={{ pt: 1 }}>
          <Grid item>
            <Chip label="ashtanga" />
          </Grid>
          <Grid item>
            <Chip label="ashtanga" />
          </Grid>
          <Grid item>
            <Chip label="ashtanga" />
          </Grid>
          <Grid item>
            <Chip label="ashtanga" />
          </Grid>
          <Grid item>
            <Chip label="ashtanga" />
          </Grid>
          <Grid item>
            <Chip label="ashtanga" />
          </Grid>
          <Grid item>
            <Chip label="ashtanga" />
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 1, p: 1 }}>
        <Button variant="contained" sx={{ color: 'white' }}>
          Show Results
        </Button>
      </Grid>
    </Box>
  )

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleDrawer()}
        sx={{ color: 'white' }}
      >
        <FilterListOutlined sx={{ pr: 1 }} />
        Filters
      </Button>
      <SwipeableDrawer
        anchor={'left'}
        open={open}
        onClose={toggleDrawer()}
        onOpen={toggleDrawer}
      >
        {list()}
      </SwipeableDrawer>
    </>
  )
}
