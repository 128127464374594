import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import DashboardOverview from './DashboardOverview'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import PaymentsHistoryTable from './PaymentsHistoryTable'
import BrowseSessions from '../browser_sessions/BrowseSessions'
import ChatPreview from '../messages/ChatPreview'
import {
  Search,
  SearchOffOutlined,
  SearchOutlined,
  SendOutlined,
} from '@mui/icons-material'
import Calendar from '../calendar/Calendar'
import CalendarFull from '../calendar/CalendarFull'

export default function DashboardCalendarPage() {
  return (
    <Grid container sx={{ p: 2, bgcolor: '#F6F7F8' }}>
      <CalendarFull />
    </Grid>
  )
}
