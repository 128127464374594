import { Button, Grid, Typography } from '@mui/material'
import Header from '../components/header/Header'
import Carousel from '../components/carousel/Carousel'
import SessionCard from '../components/session_card/SessionCard'
import LandingWorkouts from '../components/landing_workouts/LandingWorkouts'
import FeaturedSessionsRow from '../components/landing_featured/FeaturedSessionsRow'
import LandingFooterTrainer from '../components/landing_footer/LandingFooterTrainer'
import Footer from '../components/footer/Footer'
import { useSelector, useDispatch } from 'react-redux'
import FilterDrawer from '../components/filters/FilterDrawerButton/FilterDrawer'
import FilterBar from '../components/filters/FilterBar'
import BrowseSessions from '../components/browser_sessions/BrowseSessions'
import BrowseSessionsFooter from '../components/browser_sessions/BrowseSessionsFooter'
function SessionSearch() {
  const dispatch = useDispatch()
  return (
    <Grid container>
      <Header />
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <FilterBar />
      </Grid>
      <Grid container sx={{ m: 2 }}>
        <BrowseSessions />
        <BrowseSessionsFooter />
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  )
}

export default SessionSearch
