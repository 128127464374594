import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import {
  setOnboardOpen,
  setOnboardStep,
  setSessionRequestOpen,
} from '../../redux/slices/uiSlice'

import BorderSelectButton from '../buttons/BorderSelectButton'
import SolidColorButton from '../buttons/SolidColorButton'
import GreyTextButton from '../buttons/GreyTextButton'
import { ReactComponent as LocationIcon } from '../../assets/icons/location_pin.svg'

import { ReactComponent as UploadProfileIcon } from '../../assets/icons/upload_profile.svg'

import { borderRadius, Box } from '@mui/system'
import TitleC from '../text/TitleC'
import FitnessGoals from './FitnessGoals'
import AboutMember from './AboutMember'
import ChooseWorkouts from './ChooseWorkouts'
import PreferredTrainingDaysTime from './PreferredTrainingDaysTime'
import ChooseLocation from './ChooseLocation'
import MemberInfoA from './MemberInfoA'
import MemberInfoB from './MemberInfoB'
import BackgroundCheck from './BackgroundCheck'
import EmergencyContacts from './EmergencyContacts'
import IntroduceMember from './IntroduceMember'
import {
  useGetMemberByIdQuery,
  useGetMemberTrainingPreferenceByIdQuery,
  useUpdateMemberProfileMutation,
} from '../../redux/api/api'
import { useEffect } from 'react'
import { useState } from 'react'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import ProfileImageComponent from '../profile/ProfileImageComponent'
import Pages from './Pages'
import store from '../../redux/store'

export default function Onboard() {
  const dispatch = useDispatch()
  const onboardOpen = useSelector((state) => state.ui.onboardOpen)
  const onboardStep = useSelector((state) => state.ui.onboardStep)
  // const userProfileFormFields = useSelector(
  //    (state) => state.forms.userProfileModal
  // )
  const userId = useSelector((state) => state.auth.cognitoUser?.sub)

  const [updateMemberTrigger, updateMemberResponse] =
    useUpdateMemberProfileMutation()
  const stepsA = [1, 2, 3, 4, 5, 6, 7, 8]
  const stepsB = [9, 10, 11, 12, 13]
  function lastPage() {
    dispatch(setOnboardStep(onboardStep - 1))
  }
  const handleNextClick = () => {
    if (onboardStep === 13) {
      dispatch(setOnboardOpen(false))
    } else {
      nextPage()
    }
  }
  function nextPage() {
    dispatch(setOnboardStep(onboardStep + 1))
  }

  const handleFinish = () => {
    // Update Profile Info Here In The DB
    const {
      about,
      birthyear,
      city,
      fitnessGoals,
      gender,
      homeLocation,
      id,
      infoForTrainers,
      initialLoad,
      phoneNumber,
      profilePhotoUrl, // currently ""
      profilePicture, // currently "blob:http://localhost:3000/501911c4-d140-47a8-970f-dd5547679c0c"
      pronouns,
      state,
      streetAddress,
      trainingDayPreferences,
      trainingTimePreferences,
      trainingTypes,
      unitNumber,
      uploadedImage, // currently "blob:http://localhost:3000/37db3413-34e3-4aff-9a0b-e8d4e1070f72"
      userStatsId,
      username,
      workoutPreferences,
      zipCode,
    } = store.getState().forms.userProfileModal //userProfileFormFields
    console.log(
      'userProfileFormFields',
      store.getState().forms.userProfileModal
    )
    const formatted = {
      id: userId,
      // username: String
      pronouns,
      // profilePhotoUrl: String
      homeLocation: city + ' ' + state,
      about,
      infoForTrainers,
      fitnessGoals,
      workoutPreferences,
      trainingTypes,
      trainingTimePreferences,
      trainingDayPreferences,
      //userStatsId: ID
    }
    updateMemberTrigger(formatted)
    //dispatch(setOnboardOpen())
  }

  useEffect(() => {
    if (updateMemberResponse.data) {
      dispatch(setOnboardOpen())
    }
  }, [updateMemberResponse])

  return (
    <Dialog
      open={onboardOpen}
      PaperProps={{
        sx: {
          overflow: 'hidden',
          width: '100%',
          maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '95%' }}>
        <Grid container justifyContent={'space-between'} xs={12}>
          <IconButton
            onClick={() => {
              lastPage()
            }}
            style={{ width: '20px', height: '20px', marginLeft: '20px' }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch(setOnboardOpen())
            }}
            style={{ width: '20px', height: '20px', marginRight: '20px' }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          style={{
            maxWidth: '555px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid container xs={12}>
            {onboardStep < 9 &&
              [...Array(stepsA.length)].map((x, i) => (
                <Paper
                  onClick={() => {
                    dispatch(setOnboardStep(i + 1))
                  }}
                  sx={{
                    cursor: 'pointer',
                    margin: 0.5,
                    height: 10,
                    width: 10,
                    backgroundColor:
                      i + 1 === onboardStep ? 'primary.main' : 'gunMetal.light',
                    borderRadius: '50%',
                  }}
                />
              ))}
            {onboardStep > 8 &&
              [...Array(stepsB.length)].map((x, i) => (
                <Paper
                  onClick={() => {
                    dispatch(setOnboardStep(i + 9))
                  }}
                  sx={{
                    cursor: 'pointer',
                    margin: 0.5,
                    height: 10,
                    width: 10,
                    backgroundColor:
                      i + 9 === onboardStep ? 'primary.main' : 'gunMetal.light',
                    borderRadius: '50%',
                  }}
                />
              ))}
          </Grid>
          <Pages />

          <Grid
            container
            xs={12}
            style={{ paddingTop: '16px', justifyContent: 'center' }}
          >
            {onboardStep < 13 ? (
              <>
                <Grid container>
                  <SolidColorButton text="Next" onClick={handleNextClick} />
                </Grid>
                <Grid container>
                  <GreyTextButton
                    text="Skip for now"
                    onClick={() => {
                      nextPage()
                    }}
                  />
                </Grid>
              </>
            ) : (
              <Grid container>
                <SolidColorButton text="Finish" onClick={handleFinish} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
