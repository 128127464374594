import { Button, Grid, Input, Typography } from '@mui/material'
import InfoText from '../text/InfoText'
import SubTitleA from '../text/SubTitleA'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
export default function MemberInfoA(props) {
  const dispatch = useDispatch()
  const [authAttributes, setAuthAttributes] = useState()
  // const getAuth = async () => {
  //   try {
  //     const auth = await Auth.currentAuthenticatedUser()
  //     setAuthAttributes(auth.attributes)
  //   } catch (error) {
  //     console.log('error getting auth: ', error)
  //   }
  // }
  // useEffect(() => {

  //   getAuth()
  // }, [])

  const handleChangePhoneNumber = (e) => {
    console.log('e.target.value: ', e.target.value)
    dispatch(
      setUserProfileModalField({ value: e.target.value, field: 'phoneNumber' })
    )
  }
  return (
    <>
      {' '}
      <Grid
        container
        style={{
          width: '50%',
          justifyContent: 'space-around',
          rowGap: '16px',
        }}
      >
        <Grid item xs={6}>
          <SubTitleA text="First name:" />
        </Grid>
        <Grid item xs={6}>
          <InfoText text={authAttributes && authAttributes.given_name} />
        </Grid>
        <Grid item xs={6}>
          <SubTitleA text="Last name:" />
        </Grid>
        <Grid item xs={6}>
          <InfoText text={authAttributes && authAttributes.family_name} />
        </Grid>
        <Grid item xs={6}>
          <SubTitleA text="Email address:" />
        </Grid>
        <Grid item xs={6}>
          <InfoText text={authAttributes && authAttributes.email} />
        </Grid>
        <Grid tem xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="Date of birth" />
          <Button
            variant="outlined"
            onClick={() => {}}
            sx={{
              width: 258,
              height: 64,
              color: 'gunMetal.main',
              borderColor: 'transparent',
              boxShadow: 1,
            }}
          >
            <Grid container sx={{ p: '20px' }} justifyContent={'left'}>
              <CalendarIcon style={{ width: '20px' }} stroke="white" />
              <Grid item sx={{ ml: 1 }}>
                <Grid container sx={{ textAlign: 'left' }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        //textTransform: 'none',
                        fontWeight: 700,
                        fontSize: '13px',
                      }}
                    >
                      Jun 16 1997
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 400, fontSize: '9px' }}>
                      Birthdate
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid tem xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="Phone number" />
          <Input
            fullWidth
            placeholder="mobile"
            onChange={handleChangePhoneNumber}
          ></Input>
        </Grid>
      </Grid>
    </>
  )
}
