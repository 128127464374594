import { Button, Grid, Typography } from '@mui/material'
import Header from '../components/header/Header'
import Carousel from '../components/carousel/Carousel'
import SessionCard from '../components/session_card/SessionCard'
import LandingWorkouts from '../components/landing_workouts/LandingWorkouts'
import FeaturedSessionsRow from '../components/landing_featured/FeaturedSessionsRow'
import LandingFooterTrainer from '../components/landing_footer/LandingFooterTrainer'
import Footer from '../components/footer/Footer'
import Onboard from '../components/onboarding_modals/Onboard'
import { useSelector, useDispatch } from 'react-redux'
import { setOnboardOpen } from '../redux/slices/uiSlice'
import FilterDrawer from '../components/filters/FilterDrawerButton/FilterDrawer'
import FilterBar from '../components/filters/FilterBar'
import BrowseSessions from '../components/browser_sessions/BrowseSessions'
import BrowseSessionsFooter from '../components/browser_sessions/BrowseSessionsFooter'
import MemberDashboard from '../components/member-dashboard.js/MemberDashboardDrawer'
import SessionRequest from '../components/session_request/SessionRequest'
function Components() {
  const dispatch = useDispatch()
  return (
    <Grid container>
      <Header />
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Typography variant="h3">Dashboard Components</Typography>
      </Grid>
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <MemberDashboard />
      </Grid>
      {/* <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Typography variant="h3">Search Components</Typography>
      </Grid>
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Typography variant="h6"> Filters Button</Typography>
      </Grid>
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <FilterBar />
      </Grid>
      <Grid container sx={{ m: 2 }}>
        <BrowseSessions />
        <BrowseSessionsFooter />
      </Grid> */}

      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Typography variant="h6"> Sort By</Typography>
      </Grid>
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Typography variant="h6"> Related to Search</Typography>
      </Grid>
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Typography variant="h6"> More to Explore</Typography>
      </Grid>
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Typography variant="h3">Onboarding Components</Typography>
      </Grid>
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Button onClick={() => {dispatch(setOnboardOpen())}}>Onboard</Button>
        <Onboard />
        <SessionRequest />
      </Grid>
      {/* <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Typography variant="h3">Basic Components</Typography>
      </Grid>
      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Button variant="contained" color="primary" sx={{ m: 2 }}>
          Hello
        </Button>
        <Typography> TESTING FONT: Poppins</Typography>
      </Grid>

      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Typography variant="h3">Landing Page Components</Typography>
      </Grid>
      <Grid item xs={3} sx={{ p: 1 }}>
        <Typography>Session Card</Typography>
      </Grid>
      <Grid item xs={12} sx={{ p: 1 }}>
        <Grid container>
          <SessionCard />
        </Grid>
      </Grid>
      <Grid item xs={3} sx={{ p: 1 }}>
        <Typography>Carousel</Typography>
      </Grid>
      <Grid item xs={12} sx={{ p: 1 }}>
        <Grid container>
          <Carousel />
        </Grid>
      </Grid>

      <Grid item xs={3} sx={{ p: 1 }}>
        <Typography>Find Your Workout Search</Typography>
      </Grid>
      <Grid item xs={3} sx={{ p: 1 }}>
        <Typography>Get Traind Workouts Row</Typography>
      </Grid>
      <Grid item xs={12}>
        <LandingWorkouts />
      </Grid>
      <Grid item xs={3} sx={{ p: 1 }}>
        <Typography>Featured Sessions Row</Typography>
      </Grid>
      <Grid item xs={12}>
        <FeaturedSessionsRow />
      </Grid>
      <Grid item xs={3} sx={{ p: 1 }}>
        <Typography>Become a Trainer Card</Typography>
      </Grid>
      <Grid item xs={12}>
        <LandingFooterTrainer />
      </Grid>
      <Grid item xs={3} sx={{ p: 1 }}>
        <Typography>Footer Links</Typography>
      </Grid>
      <Grid item xs={3} sx={{ p: 1 }}>
        <Typography>Footer Social</Typography>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>

      <Grid container style={{ justifyContent: 'left' }} sx={{ m: 2 }}>
        <Typography variant="h3">OnboardingComponents</Typography>
      </Grid>
      <Grid item xs={3} sx={{ p: 1 }}>
        <Typography>Footer Social</Typography>
      </Grid> */}
    </Grid>
  )
}

export default Components
