import * as React from 'react'
import Box from '@mui/material/Box'

import { Grid, Typography, Divider, Chip } from '@mui/material'

import Profile from '../../assets/profile_picture.png'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export default function UpcomingSessionPreview(props) {
  const { colored, disabled, workout } = props
  return (
    <Grid
      sx={{
        border: colored ? 0 : 1,
        borderRadius: '16px',
        p: 1,
        borderColor: 'gunMetal.main',
        bgcolor: colored ? 'primary.light' : 'white',
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <Grid container justifyContent="space-between" alignItems="start">
        <Grid item sx={{ p: 1, m: 0 }}>
          <Grid container>
            <img
              src={Profile}
              style={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
              }}
            />

            <Grid item sx={{ p: 1, m: 1 }}>
              <Grid container justifyContent="left">
                <Typography
                  onClick={() => console.log('Workout: ', workout)}
                  sx={{
                    color: 'gunMetal.main',
                    fontWeight: 700,
                    fontSize: 15,
                    lineHeight: '22.5px',
                  }}
                >
                  {workout?.trainer?.displayName
                    ? workout?.trainer?.displayName
                    : workout?.trainer?.username}
                </Typography>
              </Grid>
              <Grid container justifyContent="left">
                <Typography
                  sx={{
                    color: 'gunMetal.main',
                    fontWeight: 300,
                    fontSize: 15,
                    lineHeight: '22.5px',
                  }}
                >
                  Los Angeles, CA
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ p: 1 }}>
          <Chip
            sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
            label={workout?.session?.category}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid container>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
            }}
          >
            {dayjs(workout?.startTime).format('MMM Do YYYY, h:mm A z')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
