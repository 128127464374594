import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'

const PaymentMethodCard = ({ onSubmit, onNext }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const handleSubmit = async (event) => {
    setSubmitting(true)
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://example.com/account/payments/setup-complete',
      },
    })

    if (error) {
      console.log('error', error)
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
      setSubmitting(false)
      window.alert('error adding payment method but continuing')
      onSubmit()
    } else {
      console.log('success!')
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setSubmitting(false)
      window.alert('successfully added payment method!')
      onSubmit()
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Typography>Add Payment Method</Typography>
      </Grid>
      <PaymentElement />
      {errorMessage && (
        <Grid container style={{ color: 'red' }}>
          <Typography>{errorMessage}</Typography>
        </Grid>
      )}
      {submitting ? (
        <CircularProgress />
      ) : (
        <Grid container sx={{ p: 2 }}>
          <Grid item sx={{ p: 2 }}>
            <Button
              variant="outlined"
              sx={{
                color: 'gunMetal.main',
                fontWeight: 700,
                p: 2,
                pl: 3,
                pr: 3,
                fontSize: '15px',
              }}
              onClick={() => console.log('closeModal')}
            >
              {' '}
              Cancel
            </Button>
          </Grid>
          <Grid item sx={{ p: 2 }}>
            <Button
              variant="contained"
              sx={{
                color: 'white',
                fontWeight: 700,
                p: 2,
                pl: 3,
                pr: 3,
                fontSize: '15px',
              }}
              disabled={!stripe}
              onClick={handleSubmit}
            >
              {' '}
              Save Payment Method
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  )
}
export default PaymentMethodCard
