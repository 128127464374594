import {
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import TitleC from '../text/TitleC'
import { ReactComponent as LocationIcon } from '../../assets/icons/location_pin.svg'
import { useState } from 'react'
import { CheckCircle, SaveOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'

const ChooseLocation = () => {
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)
  const [location, setLocation] = useState('home')
  const formHomeLocation = useSelector(
    (state) => state.forms.userProfileModal.homeLocation
  )
  const handleClickLocation = () => {
    console.log('clicked')
    setEditing(!editing)
  }
  const handleChangeHomeLocation = (e) => {
    console.log('changed: ', e.target.value)
    // Check Regex for city, ST
    dispatch(
      setUserProfileModalField({ field: 'homeLocation', value: e.target.value })
    )
  }
  return (
    <>
      <Grid item xs={12}>
        <TitleC text="Where is home?" />
      </Grid>
      {editing ? (
        <Grid container>
          {/* Should also submit and change editing on enter press */}
          <TextField
            onChange={handleChangeHomeLocation}
            label="City, ST"
            variant="outlined"
          />
          <Tooltip title="Save" placement="top">
            <IconButton onClick={handleClickLocation}>
              <CheckCircle />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <Grid container>
          <Button
            variant="outlined"
            onClick={handleClickLocation}
            sx={{
              width: 258,
              height: 64,
              color: 'gunMetal.main',
              borderColor: 'transparent',
              boxShadow: 1,
            }}
          >
            <Grid container sx={{ p: '20px' }} justifyContent={'left'}>
              <LocationIcon />
              <Grid item sx={{ ml: 1 }}>
                <Grid container sx={{ textAlign: 'left' }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textTransform: 'none',
                        fontWeight: 700,
                        fontSize: '13px',
                      }}
                    >
                      {formHomeLocation ? formHomeLocation : 'City, State'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 400, fontSize: '9px' }}>
                      Location
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      )}
    </>
  )
}

export default ChooseLocation
