import { Grid } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import MemberDashboard from '../components/member-dashboard.js/MemberDashboardDrawer'
function MemberPage() {
  return (
    <Grid container>
      <Header />
      <MemberDashboard />
      <Footer />
    </Grid>
  )
}

export default MemberPage
