import { Button, Grid, Input, Typography } from '@mui/material'
import InfoText from '../text/InfoText'
import SubTitleA from '../text/SubTitleA'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload_up.svg'
import { ReactComponent as CheckCircle } from '../../assets/icons/CheckCircle.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
export default function BackgroundCheck(props) {
  const handleDLFrontClick = async (e) => {
async function onChange(e) {
  const file = e.target.files[0]
  try {
    await Storage.put(file.name, file, {
      contentType: 'image/png', // contentType is optional
    })
  } catch (error) {
    console.log('Error uploading file: ', error)
  }
}
  }
  const handleDLBackClick = () => {}
  return (
    <>
      <Grid
        container
        sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
      >
        <Grid item>
          <CheckCircle />
        </Grid>
        <Grid item>
          <Typography
            align="center"
            sx={{
              padding: '32px 0 32px 16px',
              color: 'primary.main',
              fontWeight: 400,
              fontSize: 15,
              lineHeight: '15px',
            }}
          >
            Background Check Completed
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SubTitleA text="You will be limited to virtual sessions only without a background check being completed." />
      </Grid>
      <Grid container sx={{ justifyContent: 'left' }}>
        <Grid item style={{ padding: '5px' }}>
          <Button variant="outlined" onClick={() => {}}>
            <PlusIcon style={{ padding: '5px' }} />
            Background Check
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: 'left' }}>
        <Button
          variant="contained"
          style={{ margin: '10px 0', padding: '5px', color: 'white' }}
          onClick={handleDLFrontClick}
        >
          <UploadIcon stroke='white' style={{ padding: '5px' }} />
          FRONT OF driver’s license or STATE ID
        </Button>
        <Grid container sx={{ justifyContent: 'left' }}>
          <SubTitleA text="Expiration Date" />
          <Input style={{ marginLeft: '20px' }} placeholder="MM/YYYY" />
        </Grid>

        <Button
          variant="contained"
          style={{ margin: '10px 0', padding: '5px', color: 'white' }}
        >
          <UploadIcon stroke='white' style={{ padding: '5px' }} />
          Back OF driver’s license or STATE ID
        </Button>
      </Grid>
    </>
  )
}
