import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CardActionArea, Chip, Grid, IconButton } from '@mui/material'
import {
  DriveEta,
  Favorite,
  HeartBrokenTwoTone,
  LocationOn,
  LocationOnOutlined,
  Phone,
  PhoneAndroidOutlined,
  RocketLaunchOutlined,
  Star,
} from '@mui/icons-material'

export default function SessionCard(props) {
  const { session } = props
  const [favorited, setFavorited] = React.useState(false)
  return (
    <Box sx={{ maxWidth: '345px' }}>
      <IconButton
        style={{
          position: 'absoulte',
          top: '35px',
          left: '90%',
          height: 0,
          width: 0,
          zIndex: 2,
          padding: 0,
          margin: 0,
        }}
        onClick={() => setFavorited(!favorited)}
      >
        <Favorite
          fontSize="large"
          sx={{
            color: favorited ? 'primary.main' : 'gunMetal.main',
            opacity: favorited ? 1 : 0.75,
          }}
        />
      </IconButton>
      <Card sx={{ maxWidth: 345, borderRadius: '20px' }}>
        <CardActionArea onClick={props.onClick}>
          <Box style={{ backgroundColor: 'white' }}>
            <CardMedia
              component="img"
              height="140"
              src={
                session ? 'https://via.placeholder.com/160' : session?.imgUrl
              }
              alt="green iguana"
              style={{
                zIndex: 1,
              }}
            ></CardMedia>
          </Box>

          <CardContent
            sx={{ p: 0, m: 0, width: '100%' }}
            style={{ padding: 0 }}
          >
            <Box sx={{ backgroundColor: 'primary.light', p: 0, pl: 1 }}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ fontWeight: 700, color: 'gunMetal.main' }}
              >
                {session ? session?.title : 'Title with Trainer'}
              </Typography>
            </Box>
            <Grid
              container
              sx={{ width: '100%', m: 0, p: 1 }}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Grid
                  container
                  style={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item>
                    <Chip
                      label={session ? session.category : 'Running'}
                      sx={{
                        backgroundColor: 'gunMetal.main',
                        color: 'white',
                        ml: 1,
                        fontWeight: 600,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Grid container style={{ justifyContent: 'left' }}>
                      <Star
                        fontSize="small"
                        sx={{ mr: 0.5, color: 'gunMetal.main' }}
                      />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ fontWeight: 700 }}
                      >
                        4.8
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ p: 1 }}>
                  <Grid container style={{ justifyContent: 'left' }}>
                    <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      Location , ST - 2 miles away
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ justifyContent: 'space-between', p: 1, pt: 0, pr: 0 }}
              >
                <Grid item>
                  <Box>
                    <Typography>
                      <strong>${session ? session?.defaultPrice : 47}</strong>
                      /hour
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container>
                    <DriveEta sx={{ mr: 1 }} />
                    <RocketLaunchOutlined sx={{ mr: 1 }} />
                    <PhoneAndroidOutlined />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  )
}
