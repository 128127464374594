import { useDispatch, useSelector } from 'react-redux'
import { useGetMemberByIdQuery } from '../../../redux/api/api'
import { useEffect } from 'react'
import { setInitialProfileModalState } from '../../../redux/slices/formsSlice'
import { Grid, LinearProgress, Switch, Typography } from '@mui/material'
import EditIconButtonBorder from '../../buttons/EditIconButtonBorder'
import {
  setEditMode,
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../../redux/slices/uiSlice'
import MemberGeneralInfo from './MemberGeneralInfo'
import MemberOtherInfo from './MemberOtherInfo'

const MemberProfile = (props) => {
  const dispatch = useDispatch()
  const editMode = useSelector((state) => state.ui.editMode)
  const profileFormData = useSelector((state) => state.forms.userProfileModal)
  const { cognitoUser } = useSelector((state) => state.auth)
  const { data, isLoading, isSuccess, isError, error } = useGetMemberByIdQuery(
    cognitoUser?.sub,
    { skip: !cognitoUser?.sub }
  )

  useEffect(() => {
    if (data && isSuccess && profileFormData.initialLoad) {
      dispatch(setInitialProfileModalState(data))
    }
  }, [data])

  if (isLoading) {
    return <LinearProgress />
  }

  if (isSuccess) {
    const memberName = `${cognitoUser?.given_name} ${cognitoUser?.family_name}`
    return (
      <Grid container sx={{ p: '32px' }}>
        <Grid
          container
          style={{ width: '100%', justifyContent: 'space-between' }}
        >
          <Grid item>
            <Grid container style={{ justifyContent: 'flex-start' }}>
              <Grid item>
                <Typography
                  display="inline"
                  sx={{
                    fontWeight: 700,
                    fontSize: '34px',
                    lineHeight: '25.5px',
                  }}
                >
                  {memberName}'s Fitness Profile
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="inline"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 700,
                    fontSize: '17px',
                    lineHeight: '25.5px',
                    pl: 2,
                  }}
                  onClick={() => console.log('clicked', data)}
                >
                  ({data.pronouns})
                </Typography>
              </Grid>
              <Grid item>
                <EditIconButtonBorder
                  onClick={() => {
                    dispatch(setGeneralModalComponent('introduce_member'))
                    dispatch(setGeneralModalOpen(true))
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography display="inline" sx={{}}>
              Edit Mode
            </Typography>
            <Switch
              checked={!editMode}
              onChange={() => {
                dispatch(setEditMode())
              }}
            />
            <Typography display="inline" sx={{ color: 'primary.main' }}>
              Trainer View
            </Typography>
          </Grid>
        </Grid>
        <MemberGeneralInfo />
        <MemberOtherInfo memberName={memberName} />
      </Grid>
    )
  }
}

export default MemberProfile
