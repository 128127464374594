import { Button, styled } from '@mui/material'

const Day = (props) => {
  const { text } = props
  const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '15px',
    padding: '0 8x',
    margin: '8px 16px 8px 0px',
    '&:disabled': {
      backgroundColor: theme.palette.gunMetal.main,
      color: 'white',
    },
  }))
  return (
    <StyledButton variant="contained" disabled>
      {text}
    </StyledButton>
  )
}

export default Day
