import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setGeneralModalOpen } from '../../redux/slices/uiSlice'
import TitleB from '../text/TitleB'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import FitnessGoals from '../onboarding_modals/FitnessGoals'
import AboutMember from '../onboarding_modals/AboutMember'
import ChooseWorkouts from '../onboarding_modals/ChooseWorkouts'
import PreferredTrainingDaysTime from '../onboarding_modals/PreferredTrainingDaysTime'
import ChooseLocation from '../onboarding_modals/ChooseLocation'
import FitnessStats from '../onboarding_modals/FitnessStats'
import IntroduceMember from '../onboarding_modals/IntroduceMember'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentMethodCard from '../payments/PaymentMethodCard'
import { API, graphqlOperation } from 'aws-amplify'
import { updateSession, updateUser } from '../../graphql/mutations'
import { useGetMemberByIdQuery } from '../../redux/api/api'
import PhotoCropper from '../profile/PhotoCropper'
import SessionIntro from './SessionIntro'
import SessionPricingCard from './SessionPricingCard'
export default function GeneralModal(props) {
  const { title, content } = props
  const open = useSelector((state) => state.ui.generalModalOpen)
  const clientSecret = useSelector((state) => state.payments.stripeClientSecret)
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)

  const currentUser = useSelector((state) => state.auth.cognitoUser)
  const { data, isLoading, isSuccess, isError, error } = useGetMemberByIdQuery(
    cognitoUser?.sub,
    { skip: !cognitoUser?.sub }
  )
  const generalModalComponent = useSelector(
    (state) => state.ui.generalModalComponent
  )
  const userFormFields = useSelector((state) => state.forms.userProfileModal)
  const sessionFormFields = useSelector(
    (state) => state.forms.createSessionForm
  )
  const dispatch = useDispatch()
  const stripePromise = loadStripe(
    'pk_test_51LgBIIAyyHgh37gn8AyPxTtv0BqKlHTBShSdToXtoNhJdigLiIdI3tR8cu2U37y8wQOtK1u8kg9CAszzF2jXcCAN00jEdxoOyx'
  )
  const options = {
    // passing the client secret obtained from the server
    clientSecret,
  }

  let component
  let onClose = () => dispatch(setGeneralModalOpen(false))

  switch (generalModalComponent) {
    case 'fitness_goals':
      component = <FitnessGoals />
      break
    case 'about_member':
      component = <AboutMember />
      break
    case 'choose_workouts':
      component = <ChooseWorkouts />
      break
    case 'preferred_date_time':
      component = <PreferredTrainingDaysTime />
      break
    case 'choose_location':
      component = <ChooseLocation />
      break
    case 'session_intro':
      component = <SessionIntro />
      break
    case 'session_pricing':
      component = <SessionPricingCard />
      break
    case 'fitness_stats':
      component = <FitnessStats />
      break
    case 'introduce_member':
      component = <IntroduceMember />
      break
    case 'payment_method':
      if (clientSecret !== '') {
        component = (
          <Elements stripe={stripePromise} options={options}>
            <PaymentMethodCard onSubmit={() => console.log('ONSUBMIT')} />
          </Elements>
        )
      } else {
        component = <div>Loading...</div>
      }

      break
    case 'cropper':
      component = <PhotoCropper />
    case 'session_cropper':
      component = <PhotoCropper sessionMode />
      break
  }

  const handleCloseModal = async () => {
    var input = {
      id: cognitoUser?.sub,
    }
    let sessionInput
    switch (generalModalComponent) {
      case 'fitness_goals':
        input.fitnessGoals = userFormFields.fitnessGoals
        break
      case 'about_member':
        input.about = userFormFields.about
        input.infoForTrainers = userFormFields.infoForTrainers
        break
      case 'choose_workouts':
        input.workoutPreferences = userFormFields.workoutPreferences
        break
      case 'preferred_date_time':
        input.trainingTimePreferences = userFormFields.trainingTimePreferences
        input.trainingDayPreferences = userFormFields.trainingDayPreferences
        break
      case 'choose_location':
        input.homeLocation = userFormFields.homeLocation
        break
      case 'session_intro':
        sessionInput = {
          id: cognitoUser?.sub,
          title: sessionFormFields.title,
          description: sessionFormFields.description,
          category: sessionFormFields.category,
          city: sessionFormFields.city,
          state: sessionFormFields.state,
          tagline: sessionFormFields.tagline,
        }
        break
      case 'fitness_stats':
        console.log('TODO, SHOULD BE DONE IN FITNESS STATS COMPONENT')
        //field = 'userStatsId' // also trainingDayPreferences
        //value = ''
        break
      case 'introduce_member':
        input.pronouns = userFormFields.pronouns
        break
      case 'cropper':
        input = null
        break
      case 'session_cropper':
        input = null
        break
      default:
        break
    }

    if (input) {
      try {
        // dispatch user update and save profile
        console.log('input: ', input)
        const userUpdate = await API.graphql(
          graphqlOperation(updateUser, {
            input: input,
          })
        )
        const response = userUpdate.data.updateUser
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    }
    if (sessionInput) {
      console.log('sessionInput: ', sessionInput)
      try {
        console.log('sessionInput: ', sessionInput)
        // const sessionCreate = await API.graphql(
        //   graphqlOperation(updateSession, {
        //     input: sessionInput,
        //   })
        // )
        // const response = userUpdate.data.updateUser
        // console.log(response)
      } catch (error) {
        console.log(error)
      }
    }
    dispatch(setGeneralModalOpen(false))
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      PaperProps={{
        sx: {
          overflow: 'hidden',
          //width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '95%' }}>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            {' '}
            <IconButton
              onClick={() => {
                //lastPage()
              }}
              style={{ width: '20px', height: '20px', marginLeft: '20px' }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              style={{ width: '20px', height: '20px', marginRight: '20px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{}}>
        <Grid container>{component}</Grid>
      </DialogContent>
    </Dialog>
  )
}
