import * as React from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  IconButton,
} from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import TwitterIcon from '@mui/icons-material/Twitter'

export default function Footer() {
  return (
    <Grid container sx={{ p: 5 }}>
      <Grid
        container
        style={{
          textAlign: 'center',
        }}
      >
        <Grid item sx={{ p: 2, m: 2 }}>
          <Typography sx={{ fontWeight: 700 }}>Learn more</Typography>
          <Typography>About Us</Typography>
          <Typography>Become a trainer</Typography>
          <Typography>Business address</Typography>
        </Grid>
        <Grid item sx={{ p: 2, m: 2 }}>
          <Typography sx={{ fontWeight: 700 }}>Support</Typography>
          <Typography>Help</Typography>
          <Typography>Customer service</Typography>
          <Typography>Contact us</Typography>
        </Grid>
      </Grid>
      <Grid container style={{}}>
        <IconButton
          sx={{
            m: 1,
            backgroundColor: 'primary.main',
            height: 50,
            width: 50,
            borderRadius: '50%',
            color: 'white',
          }}
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          sx={{
            m: 1,
            backgroundColor: 'primary.main',
            height: 50,
            width: 50,
            borderRadius: '50%',
            color: 'white',
          }}
        >
          <FacebookOutlinedIcon />
        </IconButton>
        <IconButton
          sx={{
            m: 1,
            backgroundColor: 'primary.main',
            height: 50,
            width: 50,
            borderRadius: '50%',
            color: 'white',
          }}
        >
          <TwitterIcon />
        </IconButton>
      </Grid>
      <Grid container>
        <Grid item sx={{ m: 2 }}>
          <Typography>&copy;Copyright GET TRAIND 2022</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
