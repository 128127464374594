import React, { useState, useRef, useEffect } from 'react'

// Maps
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

const geocoder = new MapboxGeocoder({
  accessToken: mapboxgl.accessToken,
  mapboxgl: mapboxgl,
})

mapboxgl.accessToken =
  'pk.eyJ1IjoiZ2V0dHJhaW5kIiwiYSI6ImNsZGJ2amZ0MzA0Mm8zdXF6cmRqNXV4cGgifQ.NI6wljazlfT3Brxec1Mzgg'

function MapBox({ setDestinationData, searchable, initialLocation }) {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const [lng, setLng] = useState(initialLocation.lng)
  const [lat, setLat] = useState(initialLocation.lat)
  const [zoom, setZoom] = useState(15)
  //const dispatch = useDispatch()
  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom,
    })
    if (searchable) {
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      })
      geocoder.on('result', function (result) {
        //SET GEOCODING HERE
        setDestinationData(result.result)
        console.log('GEOCODER RESULT: ', result.result.place_name)
        console.log('GEOCODER RESULT: ', result)
      })
      map.current.addControl(geocoder)
    }
    // Create default markers
    new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map.current)
    // const geocoder = new MapboxGeocoder({
    //   accessToken: mapboxgl.accessToken,
    //   mapboxgl: mapboxgl,
    // })
    // geocoder.on('result', function (result) {
    //   //SET GEOCODING HERE
    //   setDestinationData(result.result.place_name)
    //   console.log('GEOCODER RESULT: ', result.result.place_name)
    // })
    // map.current.addControl(geocoder)
  }, [])

  useEffect(() => {
    if (!map.current) return // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4))
      setLat(map.current.getCenter().lat.toFixed(4))
      setZoom(map.current.getZoom().toFixed(2))
    })
  }, [])

  return <div ref={mapContainer} className="map-container" />
}

export default MapBox
