import { Box, Grid, Paper, Typography, useTheme } from '@mui/material'
import SubTitleB from '../../text/SubTitleB'
import Workout from './Workout'
import SubTitleA from '../../text/SubTitleA'

const EntryCard = (props) => {
  const { active = false } = props
  const theme = useTheme()
  return (
    <Paper
      style={{
        height: 122,
        width: 480,
        borderRadius: 20,
        backgroundColor: active ? theme.palette.primary.light : 'transparent',
      }}
    >
      <Box style={{ padding: 20 }}>
        <Grid container style={{ alignItems: 'flex-start' }}>
          <Grid item sx={{ pr: 1 }}>
            <img
              style={{ borderRadius: '50%', width: 48, height: 48 }}
              src={
                'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318'
              }
            />
          </Grid>
          <Grid item style={{ paddingRight: '12px' }}>
            <SubTitleB text="Chris T" />
            <Typography>Los Angeles, CA</Typography>
          </Grid>
          <Grid item>
            <Workout text="Sports Performance Training" />
          </Grid>
        </Grid>
        <Grid container>
          <SubTitleA text=" Jun 12th 2021, 11:00 AM PDT" />
        </Grid>
      </Box>
    </Paper>
  )
}

export default EntryCard
