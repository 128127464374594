import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import DashboardOverview from './DashboardOverview'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import PaymentsHistoryTable from './PaymentsHistoryTable'
import BrowseSessions from '../browser_sessions/BrowseSessions'
import ChatPreview from '../messages/ChatPreview'
import {
  Search,
  SearchOffOutlined,
  SearchOutlined,
  SendOutlined,
} from '@mui/icons-material'
import {
  useCreateMessageMutation,
  useGetChatRoomByMemberIdQuery,
  useGetChatsQuery,
  useGetMessageByChatRoomIdQuery,
  useGetMessageByReceiverSenderIdQuery,
  useGetMessagesQuery,
} from '../../redux/api/api'
import { API, graphqlOperation } from 'aws-amplify'
import { createMessage, createMessageRoom } from '../../graphql/mutations'
import { useSelector } from 'react-redux'

export default function DashboardMessagesPage() {
  const handleClickChat = (chat) => {
    console.log('clicked chat', chat)
    setSelectedChat(chat)
  }
  const userId = useSelector((state) => state.auth.cognitoUser.sub)
  const messageEnd = React.useRef(null)
  React.useEffect(() => {
    if (messageEnd.current) {
      messageEnd.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const messages = [
    {
      sent: false,
      message: 'Hi tim blah blach blach',
      time: '12:20',
    },
    {
      sent: false,
      message: 'Hi tim blah blach blach',
      time: '12:20',
    },
    {
      sent: true,
      message: 'Hi tim blah blach blach',
      time: '12:20',
    },
    {
      sent: false,
      message: 'Hi tim blah blach blach',
      time: '12:20',
    },
    {
      sent: true,
      message: 'Hi tim blah blach blach',
      time: '12:20',
    },
    {
      sent: false,
      message:
        'Hi tim blah blach blach Hi tim blah blach blach Hi tim blah blach blach Hi tim blah blach blach',

      time: '12:20',
    },
    {
      sent: true,
      message: 'Hi tim blah blach blach testing here again',
      time: '12:20',
    },
    {
      sent: false,
      message: 'Hi tim blah blach blach',
      time: '12:20',
    },
    {
      sent: true,
      message:
        'Hi tim blah blach blach Hi tim blah blach blach Hi tim blah blach blach Hi tim blah blach blach',
      time: '12:20',
    },
  ]
  const trainers = [456, 789]

  const {
    data: chatRooms,
    isLoading: isLoadingChatRoom,
    isSuccess: isSuccessChatRoom,
  } = useGetChatsQuery(userId, { skip: !userId })

  const [selectedChat, setSelectedChat] = React.useState(
    chatRooms ? chatRooms[0] : null
  )
  const Chat = (props) => {
    const [message, setMessage] = React.useState('')

    const [
      createMessageTrigger, // This is the mutation trigger
      { isLoading: isUpdating }, // This is the destructured mutation result
    ] = useCreateMessageMutation()

    const handleSendMessage = async () => {
      if (message.trim() === '') {
        return
      }
      try {
        // const newMessageRoom = {
        //   messageRoomMemberId: '95da19a8-b876-4d50-aaef-e06d0f7416b7',
        //   messageRoomTrainerId: 'b6dc0151-a2ba-4aca-8660-3cc6f0d1f834',
        // }
        const newMessage = {
          content: message.trim(),
          read: 'false',
          messageRoomMessagesId: '7744d602-c9b2-4b35-90b4-ac03e91b49fc',
          messageSenderId: userId,
        }
        setMessage('')
        // const messageRoomCreate = await API.graphql(
        //   graphqlOperation(createMessageRoom, {
        //     input: newMessageRoom,
        //   })
        // )
        return createMessageTrigger(newMessage)

        //console.log(messageRoomCreate.data.createMessageRoom)
      } catch (error) {
        console.log('error creating message: ', error)
      }
    }
    return (
      <Grid item sx={{ bgcolor: '#F6F7F8', height: '100%' }} xs={8}>
        <Grid
          container
          alignItems="flex-end"
          sx={{ height: '100%', pl: 3, pr: 3 }}
        >
          <Box
            style={{
              maxHeight: '45vh',
              overflow: 'auto',
              width: '100%',
            }}
          >
            {selectedChat &&
              selectedChat.messages.items.map((message) => (
                <Grid
                  container
                  justifyContent={
                    message.messageSenderId !== userId ? 'left' : 'right'
                  }
                >
                  <Grid item sx={{ p: 1, maxWidth: '70%' }}>
                    <Grid
                      container
                      justifyContent={'left'}
                      sx={{
                        p: 2,
                        bgcolor:
                          message.messageSenderId !== userId
                            ? 'white'
                            : 'primary.light',
                        borderRadius:
                          message.messageSenderId !== userId
                            ? '20px 20px 20px 0px'
                            : '20px 20px 0px 20px',
                      }}
                    >
                      <Typography>{message.content}</Typography>
                      <Grid
                        container
                        justifyContent={
                          message.messageSenderId !== userId ? 'left' : 'right'
                        }
                      >
                        <Typography>{message.createdAt}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      float: 'left',
                      clear: 'both',
                      overscrollBehavior: 'none',
                    }}
                    ref={messageEnd}
                  ></div>
                </Grid>
              ))}
          </Box>
          {selectedChat && (
            <Grid container sx={{ minHeight: '5vh' }}>
              <Grid container sx={{ bgcolor: 'white', borderRadius: '100px' }}>
                <InputBase
                  sx={{ ml: 2, flex: 1 }}
                  placeholder="Message Your Trainer if needed"
                  inputProps={{
                    'aria-label': 'Message Your Trainer if needed',
                  }}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <IconButton
                  color="primary"
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  onClick={handleSendMessage}
                >
                  <SendOutlined />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '50vh',
        bgcolor: 'white',
        mr: '60px',
        overscrollBehavior: 'none',
      }}
    >
      <Grid container sx={{ bgcolor: '#EBEBEB', height: '70vh' }}>
        <Grid item sx={{ height: '100%', p: 2 }} xs={4}>
          <Grid container sx={{ minHeight: '5vh', p: 1, mb: 2 }}>
            <Grid container sx={{ bgcolor: 'white', borderRadius: '100px' }}>
              <InputBase
                sx={{ ml: 2, flex: 1 }}
                placeholder="Search Messages"
                inputProps={{ 'aria-label': 'search Messages' }}
              />
              <IconButton
                color="primary"
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
              >
                <Search />
              </IconButton>
            </Grid>
          </Grid>
          <Box style={{ maxHeight: '60vh', overflow: 'auto', width: '100%' }}>
            {isSuccessChatRoom &&
              chatRooms.map((chatRoom) => {
                return (
                  <Grid container>
                    <ChatPreview
                      trainer={chatRoom?.messageRoomTrainerId}
                      selected={selectedChat?.id === chatRoom?.id}
                      onClick={() => {
                        handleClickChat(chatRoom)
                      }}
                    />
                  </Grid>
                )
              })}
          </Box>
        </Grid>
        <Chat />
      </Grid>
    </Box>
  )
}
