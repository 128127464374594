import * as React from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  Popover,
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import Grow from '@mui/material/Grow'
import {
  CalendarMonthOutlined,
  LocationOnOutlined,
  Search,
  TripOrigin,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import CalendarHeaderSearch from '../calendar/CalendarHeaderSearch'
import { ReactComponent as MagnifyingGlass } from '../../assets/icons/magnifying_glass.svg'
import { ReactComponent as LocationIcon } from '../../assets/icons/location_pin.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as WorkoutIcon } from '../../assets/icons/workout.svg'
import { ReactComponent as Circle } from '../../assets/icons/circle.svg'
import ChooseWorkouts from '../onboarding_modals/ChooseWorkouts'
import HeaderWorkoutTypeSelect from './HeaderWorkoutTypeSelect'
import { useDispatch, useSelector } from 'react-redux'
import { setHeaderSearchData } from '../../redux/slices/uiSlice'
import { Navigate, useNavigate } from 'react-router-dom'

export default function HeaderSearch() {
  const navigate = useNavigate()
  const demoCities = [
    { city: 'Los Angeles', state: 'CA' },
    { city: 'New York', state: 'NY' },
  ]
  const dispatch = useDispatch()
  const headerSearchParams = useSelector((state) => state.ui.headerSearch)
  const [selected, setSelected] = React.useState([false, false, false, false])

  const [locationSearchAnchorEl, setLocationSearchAnchorEl] =
    React.useState(null)

  const handleOpenLocationSearchClick = (event) => {
    setLocationSearchAnchorEl(event.currentTarget)
  }

  const handleCloseLocationSearch = () => {
    setLocationSearchAnchorEl(null)
  }

  const locationSearchOpen = Boolean(locationSearchAnchorEl)
  const locSearchId = locationSearchOpen ? 'location-search-popover' : undefined

  const [dateSearchAnchorEl, setDateSearchAnchorEl] = React.useState(null)
  const handleOpenDateSearchClick = (event) => {
    setDateSearchAnchorEl(event.currentTarget)
  }

  const handleCloseDateSearch = () => {
    setDateSearchAnchorEl(null)
  }

  const dateSearchOpen = Boolean(dateSearchAnchorEl)
  const dateSearchId = dateSearchOpen ? 'date-search-popover' : undefined

  // Type
  const [typeSearchAnchorEl, setTypeSearchAnchorEl] = React.useState(null)
  const handleOpenTypeSearchClick = (event) => {
    setTypeSearchAnchorEl(event.currentTarget)
  }

  const handleCloseTypeSearch = () => {
    setTypeSearchAnchorEl(null)
  }

  const typeSearchOpen = Boolean(typeSearchAnchorEl)
  const typeSearchId = typeSearchOpen ? 'type-search-popover' : undefined

  const [expanded, setExpanded] = React.useState(false)
  const handleChange = () => {
    console.log('change')
  }
  const handleClickSearch = () => {
    setExpanded(!expanded)
  }
  const handleClickLocation = (city, state) => {
    dispatch(
      setHeaderSearchData({
        field: 'city',
        value: city,
      })
    )
    dispatch(
      setHeaderSearchData({
        field: 'state',
        value: state,
      })
    )
  }

  return (
    <Box
      onClick={() => console.log('Testing')}
      sx={{
        cursor: 'pointer',
        borderRadius: 10,
        backgroundColor: 'white',
        boxShadow:
          '-2px -2px 10px rgba(206, 206, 206, 0.35), 2px 2px 4px rgba(206, 206, 206, 0.15)',

        border: expanded ? 1 : 0,
        borderColor: 'primary.main',
        m: 0,
        p: 0,
      }}
      style={{ margin: 0, padding: 0, marginLeft: 25 }}
    >
      <Grid
        container
        style={{
          height: '100%',
          margin: 0,
          padding: 0,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grow in={!expanded} orientation="horizontal">
          <Grid
            item
            sx={{
              height: expanded ? 0 : '100%',
              width: expanded ? 0 : '100%',
              p: expanded ? 0 : 0,
              mr: expanded ? 0 : 3,
            }}
          >
            <Button
              onClick={handleClickSearch}
              sx={{
                height: expanded ? 0 : '100%',
                width: expanded ? 0 : '100%',
                p: 0,
                pl: expanded ? 0 : 1,
                pr: expanded ? 0 : 1,
                textTransform: 'none',
              }}
            >
              <Grid container alignItems="center">
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '13px',
                      color: 'gunMetal.main',
                      mr: 1,
                    }}
                  >
                    Find your Workout
                  </Typography>
                </Grid>
                <Grid item sx={{ pt: 0.5 }}>
                  <MagnifyingGlass />
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grow>

        <Grow orientation="horizontal" in={expanded}>
          <Grid
            container
            sx={{
              height: expanded ? null : 0,
              width: expanded ? null : 0,
            }}
            spacing={3}
          >
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleOpenLocationSearchClick}
                sx={{ color: 'gunMetal.main' }}
              >
                <Grid container sx={{ width: '140px', alignItems: 'center' }}>
                  <Grid item>
                    <LocationIcon style={{ width: '22px' }} />
                  </Grid>
                  <Grid item sx={{ ml: 1 }}>
                    <Grid container sx={{ textAlign: 'left' }}>
                      <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 700, fontSize: '13px' }}>
                          {headerSearchParams.city
                            ? headerSearchParams.city
                            : 'Where'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 400, fontSize: '9px' }}>
                          {headerSearchParams.state
                            ? headerSearchParams.state
                            : 'Location'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Button>
              <Popover
                id={locSearchId}
                open={locationSearchOpen}
                anchorEl={locationSearchAnchorEl}
                onClose={handleCloseLocationSearch}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                PaperProps={{ sx: { borderRadius: '30px' } }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{
                  mt: 1,
                  b: 1,
                  borderColor: 'rgba(205,210,213,1',
                  p: 2,
                  borderRadius: '30px',
                }}
              >
                <Grid
                  container
                  sx={{ p: 2, width: '265px' }}
                  justifyContent="flex-start"
                >
                  <Grid container sx={{ pb: 1 }} justifyContent="flex-start">
                    <Circle
                      fill={selected[0] ? '#99E9E9' : 'none'}
                      style={{ cursor: 'pointer', paddingRight: 5 }}
                      onClick={() => {
                        setSelected({
                          ...selected,
                          0: !selected[0],
                          1: selected[0],
                        })
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '13px',
                        lineHeight: '19.5px',
                      }}
                    >
                      Find my location
                    </Typography>
                  </Grid>
                  <Grid container sx={{ pb: 1 }} justifyContent="flex-start">
                    <Circle
                      fill={selected[1] ? '#99E9E9' : 'none'}
                      style={{ cursor: 'pointer', paddingRight: 5 }}
                      onClick={() => {
                        setSelected({
                          ...selected,
                          1: !selected[1],
                          0: selected[1],
                        })
                      }}
                    />

                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '13px',
                        lineHeight: '19.5px',
                      }}
                    >
                      Use saved location
                    </Typography>
                  </Grid>
                  {demoCities.map((location, index) => (
                    <Grid
                      key={location.city}
                      container
                      sx={{ pb: 1 }}
                      justifyContent="flex-start"
                    >
                      <Button
                        onClick={() =>
                          handleClickLocation(location.city, location.state)
                        }
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: '15px',
                            lineHeight: '22.5px',
                            letterSpacing: '1.5px',
                            color: selected[2]
                              ? 'primary.dark'
                              : 'gunMetal.main',
                          }}
                        >
                          {location.city}, {location.state}
                        </Typography>
                      </Button>
                    </Grid>
                  ))}

                  <Grid container sx={{ pb: 1 }} justifyContent="center">
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '10px',
                        textAlign: 'center',
                      }}
                    >
                      More cities coming soon. Contact suport if you'd like your
                      city represented!
                    </Typography>
                  </Grid>
                </Grid>
              </Popover>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                onClick={handleOpenDateSearchClick}
                sx={{ color: 'gunMetal.main' }}
              >
                <Grid container sx={{ width: '140px', alignItems: 'center' }}>
                  <Grid item>
                    <CalendarIcon style={{ width: '22px' }} />
                  </Grid>

                  <Grid item sx={{ ml: 1 }}>
                    <Grid container sx={{ textAlign: 'left' }}>
                      <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 700, fontSize: '13px' }}>
                          {headerSearchParams.date
                            ? headerSearchParams.date
                            : 'When'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 400, fontSize: '9px' }}>
                          {headerSearchParams.time
                            ? headerSearchParams.time
                            : headerSearchParams.date
                            ? 'ALL DAY'
                            : 'Select Date'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Button>
              <Popover
                id={dateSearchId}
                open={dateSearchOpen}
                anchorEl={dateSearchAnchorEl}
                onClose={handleCloseDateSearch}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                PaperProps={{ sx: { borderRadius: '30px' } }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{
                  mt: 1,
                  b: 1,
                  borderColor: 'rgba(205,210,213,1',
                  p: 2,
                  borderRadius: '30px',
                }}
              >
                <Grid container sx={{ p: 2 }} justifyContent="flex-start">
                  <CalendarHeaderSearch />
                </Grid>
              </Popover>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleOpenTypeSearchClick}
                sx={{ color: 'gunMetal.main' }}
              >
                <Grid container sx={{ width: '140px' }}>
                  <WorkoutIcon style={{ width: '22px' }} />

                  <Grid item sx={{ ml: 1 }}>
                    <Grid container sx={{ textAlign: 'left' }}>
                      <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 700, fontSize: '13px' }}>
                          What
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 400, fontSize: '9px' }}>
                          Workout Type
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Button>
              <Popover
                id={typeSearchId}
                open={typeSearchOpen}
                anchorEl={typeSearchAnchorEl}
                onClose={handleCloseTypeSearch}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                PaperProps={{ sx: { borderRadius: '30px', width: '50%' } }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{
                  mt: 1,
                  b: 1,
                  borderColor: 'rgba(205,210,213,1',
                  p: 2,
                  borderRadius: '30px',
                }}
              >
                <Grid container sx={{ p: 2 }} justifyContent="flex-start">
                  <HeaderWorkoutTypeSelect />
                </Grid>
              </Popover>
            </Grid>
            <Grid item>
              <Button onClick={() => navigate('/browse-sessions')}>
                <MagnifyingGlass />
              </Button>
            </Grid>
          </Grid>
        </Grow>
      </Grid>
    </Box>
  )
}
