import * as React from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { Grid, Typography, Divider, Chip, IconButton } from '@mui/material'
import SessionCard from '../session_card/SessionCard'
import { ArrowBack, ArrowForward } from '@mui/icons-material'

export default function BrowseSessionsFooter() {
  return (
    <Grid container>
      <Grid container>
        <Grid item>
          <IconButton>
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography>Page 1 of 8</Typography>
        </Grid>
        <Grid item>
          <IconButton>
            <ArrowForward />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Typography>144 results</Typography>
      </Grid>
    </Grid>
  )
}
