import React, { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Typography, Grid } from '@mui/material'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import Average from '../../assets/HeroSlider/average.jpg'
import Alone from '../../assets/HeroSlider/alone.jpg'
import Best from '../../assets/HeroSlider/best.jpg'
import Adventure from '../../assets/HeroSlider/adventure.png'
import Show from '../../assets/HeroSlider/show.png'
import Younger from '../../assets/HeroSlider/younger.png'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthModalOpen } from '../../redux/slices/uiSlice'
import { useNavigate } from 'react-router-dom'

export default function ImageCarousel(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = React.useState(0)
  const memberSignedIn = useSelector((state) => state.auth.memberSignedIn)

  var items = [
    {
      name: "It's time to GET TRAIND ",
      description: 'Because working out alone sucks',
      imgPath: Alone,
    },
    {
      name: "It's time to GET TRAIND ",
      description: 'Make your workout the best part of your day ',
      imgPath: Best,
    },
    {
      top: true,
      name: "It's time to GET TRAIND ",
      description: "Life is an adventure, why isn't your workout?",
      imgPath: Adventure,
    },
    {
      top: true,
      name: "It's time to GET TRAIND ",
      description: "When average isn't cutting it",
      imgPath: Average,
    },
    {
      right: true,
      name: "It's time to GET TRAIND ",
      description: 'All you have to do is show up',
      imgPath: Show,
    },
    {
      top: true,
      dark: true,
      name: "It's time to GET TRAIND ",
      description: "Because you're not getting any younger",
      imgPath: Younger,
    },
  ]
  const renderIndicators = () => {}

  return (
    <Carousel
      indicators={false}
      navButtonsProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
      onChange={(index) => setActiveIndex(index)}
      navButtonsAlwaysVisible
      sx={{ width: '100%', alignItems: 'center' }}
      NextIcon={<ArrowForwardIosRoundedIcon sx={{ fontSize: 56 }} />}
      PrevIcon={<ArrowBackIosNewRoundedIcon sx={{ fontSize: 56 }} />}
    >
      {items.map((item, i) => (
        <Paper
          style={{
            height: '832px',
          }}
          sx={{
            color: 'white',
            width: '100%',
            backgroundImage: `url(${item.imgPath})`,
            backgroundSize: 'cover',
          }}
        >
          <Grid
            container
            style={{
              height: '100%',
              alignItems: item.top ? 'flex-start' : 'center',
              backgroundColor: 'rgba(0,0,0,.05)',
            }}
          >
            <Grid container sx={{ pt: item.top ? 10 : 0 }}>
              <Grid
                item
                sx={{ pl: item.right ? 0 : 15, pr: item.right ? 15 : 0 }}
                xs={12}
                textAlign={item.right ? 'right' : 'left'}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 46,
                    color: item.dark ? 'gunMetal.main' : 'white',
                  }}
                >
                  {item.name}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{ pl: item.right ? 0 : 15, pr: item.right ? 15 : 0 }}
                xs={12}
                textAlign={item.right ? 'right' : 'left'}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 26,
                    color: item.dark ? 'gunMetal.main' : 'white',
                  }}
                >
                  {item.description}
                </Typography>
              </Grid>
              <Grid
                container
                style={{ position: 'absolute', bottom: '150px' }}
                sx={{ pt: 15 }}
              >
                <Button
                  onClick={() => {
                    if (memberSignedIn) {
                      navigate('/browse-sessions')
                    } else {
                      dispatch(setAuthModalOpen(true))
                    }
                  }}
                  variant="contained"
                  sx={{ p: 1.5, pl: 3, pr: 3 }}
                >
                  Get Traind
                </Button>
              </Grid>
              <Grid container style={{ position: 'absolute', bottom: '120px' }}>
                {[...Array(items.length)].map((x, i) => (
                  <Paper
                    sx={{
                      margin: 0.5,
                      height: 10,
                      width: 10,
                      backgroundColor:
                        i === activeIndex ? 'primary.main' : 'white',
                      borderRadius: '50%',
                    }}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Carousel>
  )
}
