/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      pronouns
      profilePhotoUrl
      homeLocation
      about
      infoForTrainers
      fitnessGoals
      workoutPreferences
      trainingTypes
      trainingTimePreferences
      trainingDayPreferences
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          virtual
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
        }
        nextToken
      }
      medicalConditions {
        items {
          id
          title
          description
          createdAt
          updatedAt
          userMedicalConditionsId
        }
        nextToken
      }
      stats {
        id
        age
        height
        weight
        bmi
        fatPercentage
        stepsPerDay
        calsPerDay
        stairsPerDay
        milesPerWeekRunning
        milesPerWeekCycle
        goalStepsPerDay
        goalCalsPerDay
        goalStairsPerDay
        goalMilesPerWeekRunning
        goalMilesPerWeekCycle
        createdAt
        updatedAt
      }
      focusAreas {
        items {
          id
          injury
          locationX
          locationY
          createdAt
          updatedAt
          userFocusAreasId
        }
        nextToken
      }
      favoriteSessions {
        items {
          id
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          createdAt
          updatedAt
          userFavoriteSessionsId
          favoritedSessionSessionId
        }
        nextToken
      }
      favoriteTrainers {
        items {
          id
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userFavoriteTrainersId
          favoritedTrainerTrainerId
        }
        nextToken
      }
      createdAt
      updatedAt
      userStatsId
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      nextToken
    }
  }
`
export const getFocusArea = /* GraphQL */ `
  query GetFocusArea($id: ID!) {
    getFocusArea(id: $id) {
      id
      injury
      locationX
      locationY
      createdAt
      updatedAt
      userFocusAreasId
    }
  }
`
export const listFocusAreas = /* GraphQL */ `
  query ListFocusAreas(
    $filter: ModelFocusAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFocusAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        injury
        locationX
        locationY
        createdAt
        updatedAt
        userFocusAreasId
      }
      nextToken
    }
  }
`
export const getStat = /* GraphQL */ `
  query GetStat($id: ID!) {
    getStat(id: $id) {
      id
      age
      height
      weight
      bmi
      fatPercentage
      stepsPerDay
      calsPerDay
      stairsPerDay
      milesPerWeekRunning
      milesPerWeekCycle
      goalStepsPerDay
      goalCalsPerDay
      goalStairsPerDay
      goalMilesPerWeekRunning
      goalMilesPerWeekCycle
      createdAt
      updatedAt
    }
  }
`
export const listStats = /* GraphQL */ `
  query ListStats(
    $filter: ModelStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        age
        height
        weight
        bmi
        fatPercentage
        stepsPerDay
        calsPerDay
        stairsPerDay
        milesPerWeekRunning
        milesPerWeekCycle
        goalStepsPerDay
        goalCalsPerDay
        goalStairsPerDay
        goalMilesPerWeekRunning
        goalMilesPerWeekCycle
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getMedicalCondition = /* GraphQL */ `
  query GetMedicalCondition($id: ID!) {
    getMedicalCondition(id: $id) {
      id
      title
      description
      createdAt
      updatedAt
      userMedicalConditionsId
    }
  }
`
export const listMedicalConditions = /* GraphQL */ `
  query ListMedicalConditions(
    $filter: ModelMedicalConditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicalConditions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        createdAt
        updatedAt
        userMedicalConditionsId
      }
      nextToken
    }
  }
`
export const getTrainer = /* GraphQL */ `
  query GetTrainer($id: ID!) {
    getTrainer(id: $id) {
      id
      username
      displayName
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          virtual
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
        }
        nextToken
      }
      sessions {
        items {
          id
          title
          description
          inPerson
          virtual
          maxTimeAfterStart
          category
          city
          state
          defaultPrice
          indoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          outdoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          commuteToMember
          maxCommuteDistance
          commuteFlatFee
          equipment {
            nextToken
          }
          cheerleader
          drillSargeant
          professor
          workoutBuddy
          availability {
            id
            daysAvailable
            maxSessionsPerDay
            minTimeBetweenSessions
            minTimeBeforeBooking
            startDate
            endDate
            isRepeat
            createdAt
            updatedAt
          }
          thrityMinsPrice
          sixtyMinsPrice
          ninetyMinsPrice
          oneTwentyMinsPrice
          coverPhotoIndex
          sessionPhotos
          instantBookingEnabled
          minTimeBeforeInstantBooking
          paused
          searchable
          createdAt
          updatedAt
          trainerSessionsId
          sessionIndoorLocationId
          sessionOutdoorLocationId
          sessionAvailabilityId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listTrainers = /* GraphQL */ `
  query ListTrainers(
    $filter: ModelTrainerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        displayName
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment {
          items {
            id
            name
            optional
            createdAt
            updatedAt
            sessionEquipmentId
          }
          nextToken
        }
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        availability {
          id
          daysAvailable
          maxSessionsPerDay
          minTimeBetweenSessions
          minTimeBeforeBooking
          startDate
          endDate
          isRepeat
          createdAt
          updatedAt
        }
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        coverPhotoIndex
        sessionPhotos
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        sessionAvailabilityId
      }
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        displayName
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      price
      startTime
      endTime
      location {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      notes
      rating
      review
      sessionMaxHR
      sessionAvgHR
      sessionCalories
      trainerNotes
      trainerSuggestions
      selectedLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      virtual
      createdAt
      updatedAt
      userBookingsId
      trainerBookingsId
      bookingSessionId
      bookingSelectedLocationId
    }
  }
`
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        session {
          id
          title
          description
          inPerson
          virtual
          maxTimeAfterStart
          category
          city
          state
          defaultPrice
          indoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          outdoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          commuteToMember
          maxCommuteDistance
          commuteFlatFee
          equipment {
            nextToken
          }
          cheerleader
          drillSargeant
          professor
          workoutBuddy
          availability {
            id
            daysAvailable
            maxSessionsPerDay
            minTimeBetweenSessions
            minTimeBeforeBooking
            startDate
            endDate
            isRepeat
            createdAt
            updatedAt
          }
          thrityMinsPrice
          sixtyMinsPrice
          ninetyMinsPrice
          oneTwentyMinsPrice
          coverPhotoIndex
          sessionPhotos
          instantBookingEnabled
          minTimeBeforeInstantBooking
          paused
          searchable
          createdAt
          updatedAt
          trainerSessionsId
          sessionIndoorLocationId
          sessionOutdoorLocationId
          sessionAvailabilityId
        }
        member {
          id
          username
          pronouns
          profilePhotoUrl
          homeLocation
          about
          infoForTrainers
          fitnessGoals
          workoutPreferences
          trainingTypes
          trainingTimePreferences
          trainingDayPreferences
          bookings {
            nextToken
          }
          medicalConditions {
            nextToken
          }
          stats {
            id
            age
            height
            weight
            bmi
            fatPercentage
            stepsPerDay
            calsPerDay
            stairsPerDay
            milesPerWeekRunning
            milesPerWeekCycle
            goalStepsPerDay
            goalCalsPerDay
            goalStairsPerDay
            goalMilesPerWeekRunning
            goalMilesPerWeekCycle
            createdAt
            updatedAt
          }
          focusAreas {
            nextToken
          }
          favoriteSessions {
            nextToken
          }
          favoriteTrainers {
            nextToken
          }
          createdAt
          updatedAt
          userStatsId
        }
        trainer {
          id
          username
          displayName
          bookings {
            nextToken
          }
          sessions {
            nextToken
          }
          createdAt
          updatedAt
        }
        status
        price
        startTime
        endTime
        location {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        notes
        rating
        review
        sessionMaxHR
        sessionAvgHR
        sessionCalories
        trainerNotes
        trainerSuggestions
        selectedLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        virtual
        createdAt
        updatedAt
        userBookingsId
        trainerBookingsId
        bookingSessionId
        bookingSelectedLocationId
      }
      nextToken
    }
  }
`
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      title
      description
      inPerson
      virtual
      maxTimeAfterStart
      category
      city
      state
      defaultPrice
      indoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      outdoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      commuteToMember
      maxCommuteDistance
      commuteFlatFee
      equipment {
        items {
          id
          name
          optional
          createdAt
          updatedAt
          sessionEquipmentId
        }
        nextToken
      }
      cheerleader
      drillSargeant
      professor
      workoutBuddy
      availability {
        id
        daysAvailable
        maxSessionsPerDay
        minTimeBetweenSessions
        minTimeBeforeBooking
        startDate
        endDate
        isRepeat
        createdAt
        updatedAt
      }
      thrityMinsPrice
      sixtyMinsPrice
      ninetyMinsPrice
      oneTwentyMinsPrice
      coverPhotoIndex
      sessionPhotos
      instantBookingEnabled
      minTimeBeforeInstantBooking
      paused
      searchable
      createdAt
      updatedAt
      trainerSessionsId
      sessionIndoorLocationId
      sessionOutdoorLocationId
      sessionAvailabilityId
    }
  }
`
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment {
          items {
            id
            name
            optional
            createdAt
            updatedAt
            sessionEquipmentId
          }
          nextToken
        }
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        availability {
          id
          daysAvailable
          maxSessionsPerDay
          minTimeBetweenSessions
          minTimeBeforeBooking
          startDate
          endDate
          isRepeat
          createdAt
          updatedAt
        }
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        coverPhotoIndex
        sessionPhotos
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        sessionAvailabilityId
      }
      nextToken
    }
  }
`
export const getAvailability = /* GraphQL */ `
  query GetAvailability($id: ID!) {
    getAvailability(id: $id) {
      id
      daysAvailable
      maxSessionsPerDay
      minTimeBetweenSessions
      minTimeBeforeBooking
      startDate
      endDate
      isRepeat
      createdAt
      updatedAt
    }
  }
`
export const listAvailabilities = /* GraphQL */ `
  query ListAvailabilities(
    $filter: ModelAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailabilities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        daysAvailable
        maxSessionsPerDay
        minTimeBetweenSessions
        minTimeBeforeBooking
        startDate
        endDate
        isRepeat
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getEquipment = /* GraphQL */ `
  query GetEquipment($id: ID!) {
    getEquipment(id: $id) {
      id
      name
      optional
      createdAt
      updatedAt
      sessionEquipmentId
    }
  }
`
export const listEquipment = /* GraphQL */ `
  query ListEquipment(
    $filter: ModelEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEquipment(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        optional
        createdAt
        updatedAt
        sessionEquipmentId
      }
      nextToken
    }
  }
`
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      address
      directions
      lat
      lng
      isIndoor
      createdAt
      updatedAt
    }
  }
`
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getMessageRoom = /* GraphQL */ `
  query GetMessageRoom($id: ID!) {
    getMessageRoom(id: $id) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      messages {
        items {
          id
          content
          sender {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          read
          createdAt
          updatedAt
          messageRoomMessagesId
          messageSenderId
        }
        nextToken
      }
      createdAt
      updatedAt
      messageRoomMemberId
      messageRoomTrainerId
    }
  }
`
export const listMessageRooms = /* GraphQL */ `
  query ListMessageRooms(
    $filter: ModelMessageRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        member {
          id
          username
          pronouns
          profilePhotoUrl
          homeLocation
          about
          infoForTrainers
          fitnessGoals
          workoutPreferences
          trainingTypes
          trainingTimePreferences
          trainingDayPreferences
          bookings {
            nextToken
          }
          medicalConditions {
            nextToken
          }
          stats {
            id
            age
            height
            weight
            bmi
            fatPercentage
            stepsPerDay
            calsPerDay
            stairsPerDay
            milesPerWeekRunning
            milesPerWeekCycle
            goalStepsPerDay
            goalCalsPerDay
            goalStairsPerDay
            goalMilesPerWeekRunning
            goalMilesPerWeekCycle
            createdAt
            updatedAt
          }
          focusAreas {
            nextToken
          }
          favoriteSessions {
            nextToken
          }
          favoriteTrainers {
            nextToken
          }
          createdAt
          updatedAt
          userStatsId
        }
        trainer {
          id
          username
          pronouns
          profilePhotoUrl
          homeLocation
          about
          infoForTrainers
          fitnessGoals
          workoutPreferences
          trainingTypes
          trainingTimePreferences
          trainingDayPreferences
          bookings {
            nextToken
          }
          medicalConditions {
            nextToken
          }
          stats {
            id
            age
            height
            weight
            bmi
            fatPercentage
            stepsPerDay
            calsPerDay
            stairsPerDay
            milesPerWeekRunning
            milesPerWeekCycle
            goalStepsPerDay
            goalCalsPerDay
            goalStairsPerDay
            goalMilesPerWeekRunning
            goalMilesPerWeekCycle
            createdAt
            updatedAt
          }
          focusAreas {
            nextToken
          }
          favoriteSessions {
            nextToken
          }
          favoriteTrainers {
            nextToken
          }
          createdAt
          updatedAt
          userStatsId
        }
        messages {
          items {
            id
            content
            read
            createdAt
            updatedAt
            messageRoomMessagesId
            messageSenderId
          }
          nextToken
        }
        createdAt
        updatedAt
        messageRoomMemberId
        messageRoomTrainerId
      }
      nextToken
    }
  }
`
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      content
      sender {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      read
      createdAt
      updatedAt
      messageRoomMessagesId
      messageSenderId
    }
  }
`
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        sender {
          id
          username
          pronouns
          profilePhotoUrl
          homeLocation
          about
          infoForTrainers
          fitnessGoals
          workoutPreferences
          trainingTypes
          trainingTimePreferences
          trainingDayPreferences
          bookings {
            nextToken
          }
          medicalConditions {
            nextToken
          }
          stats {
            id
            age
            height
            weight
            bmi
            fatPercentage
            stepsPerDay
            calsPerDay
            stairsPerDay
            milesPerWeekRunning
            milesPerWeekCycle
            goalStepsPerDay
            goalCalsPerDay
            goalStairsPerDay
            goalMilesPerWeekRunning
            goalMilesPerWeekCycle
            createdAt
            updatedAt
          }
          focusAreas {
            nextToken
          }
          favoriteSessions {
            nextToken
          }
          favoriteTrainers {
            nextToken
          }
          createdAt
          updatedAt
          userStatsId
        }
        read
        createdAt
        updatedAt
        messageRoomMessagesId
        messageSenderId
      }
      nextToken
    }
  }
`
export const getFavoritedSession = /* GraphQL */ `
  query GetFavoritedSession($id: ID!) {
    getFavoritedSession(id: $id) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment {
          items {
            id
            name
            optional
            createdAt
            updatedAt
            sessionEquipmentId
          }
          nextToken
        }
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        availability {
          id
          daysAvailable
          maxSessionsPerDay
          minTimeBetweenSessions
          minTimeBeforeBooking
          startDate
          endDate
          isRepeat
          createdAt
          updatedAt
        }
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        coverPhotoIndex
        sessionPhotos
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        sessionAvailabilityId
      }
      createdAt
      updatedAt
      userFavoriteSessionsId
      favoritedSessionSessionId
    }
  }
`
export const listFavoritedSessions = /* GraphQL */ `
  query ListFavoritedSessions(
    $filter: ModelFavoritedSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoritedSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        member {
          id
          username
          pronouns
          profilePhotoUrl
          homeLocation
          about
          infoForTrainers
          fitnessGoals
          workoutPreferences
          trainingTypes
          trainingTimePreferences
          trainingDayPreferences
          bookings {
            nextToken
          }
          medicalConditions {
            nextToken
          }
          stats {
            id
            age
            height
            weight
            bmi
            fatPercentage
            stepsPerDay
            calsPerDay
            stairsPerDay
            milesPerWeekRunning
            milesPerWeekCycle
            goalStepsPerDay
            goalCalsPerDay
            goalStairsPerDay
            goalMilesPerWeekRunning
            goalMilesPerWeekCycle
            createdAt
            updatedAt
          }
          focusAreas {
            nextToken
          }
          favoriteSessions {
            nextToken
          }
          favoriteTrainers {
            nextToken
          }
          createdAt
          updatedAt
          userStatsId
        }
        session {
          id
          title
          description
          inPerson
          virtual
          maxTimeAfterStart
          category
          city
          state
          defaultPrice
          indoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          outdoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          commuteToMember
          maxCommuteDistance
          commuteFlatFee
          equipment {
            nextToken
          }
          cheerleader
          drillSargeant
          professor
          workoutBuddy
          availability {
            id
            daysAvailable
            maxSessionsPerDay
            minTimeBetweenSessions
            minTimeBeforeBooking
            startDate
            endDate
            isRepeat
            createdAt
            updatedAt
          }
          thrityMinsPrice
          sixtyMinsPrice
          ninetyMinsPrice
          oneTwentyMinsPrice
          coverPhotoIndex
          sessionPhotos
          instantBookingEnabled
          minTimeBeforeInstantBooking
          paused
          searchable
          createdAt
          updatedAt
          trainerSessionsId
          sessionIndoorLocationId
          sessionOutdoorLocationId
          sessionAvailabilityId
        }
        createdAt
        updatedAt
        userFavoriteSessionsId
        favoritedSessionSessionId
      }
      nextToken
    }
  }
`
export const getFavoritedTrainer = /* GraphQL */ `
  query GetFavoritedTrainer($id: ID!) {
    getFavoritedTrainer(id: $id) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        displayName
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userFavoriteTrainersId
      favoritedTrainerTrainerId
    }
  }
`
export const listFavoritedTrainers = /* GraphQL */ `
  query ListFavoritedTrainers(
    $filter: ModelFavoritedTrainerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoritedTrainers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        member {
          id
          username
          pronouns
          profilePhotoUrl
          homeLocation
          about
          infoForTrainers
          fitnessGoals
          workoutPreferences
          trainingTypes
          trainingTimePreferences
          trainingDayPreferences
          bookings {
            nextToken
          }
          medicalConditions {
            nextToken
          }
          stats {
            id
            age
            height
            weight
            bmi
            fatPercentage
            stepsPerDay
            calsPerDay
            stairsPerDay
            milesPerWeekRunning
            milesPerWeekCycle
            goalStepsPerDay
            goalCalsPerDay
            goalStairsPerDay
            goalMilesPerWeekRunning
            goalMilesPerWeekCycle
            createdAt
            updatedAt
          }
          focusAreas {
            nextToken
          }
          favoriteSessions {
            nextToken
          }
          favoriteTrainers {
            nextToken
          }
          createdAt
          updatedAt
          userStatsId
        }
        trainer {
          id
          username
          displayName
          bookings {
            nextToken
          }
          sessions {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        userFavoriteTrainersId
        favoritedTrainerTrainerId
      }
      nextToken
    }
  }
`

export const getTrainerForSessionDetailsById = /* GraphQL */ `
  query GetTrainer($id: ID!) {
    getTrainer(id: $id) {
      id
      username
      displayName
    }
  }
`
