import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../redux/slices/uiSlice'
import SubTitleA from '../text/SubTitleA'
import { ReactComponent as UploadProfileIcon } from '../../assets/icons/upload_profile.svg'
import {
  setUploadedImage,
  setUserProfilePicture,
} from '../../redux/slices/formsSlice'
import { useGetImageQuery } from '../../redux/api/api'
import S3Image from '../S3Image'

const ProfileImageComponent = (props) => {
  const dispatch = useDispatch()
  const cognitoUser = useSelector((state) => state.auth.cognitoUser.sub)
  const profileFormData = useSelector((state) => state.forms.userProfileModal)
  const { data, error, isLoading } = useGetImageQuery(`${cognitoUser}/Profile`)

  const profilePictureBlob = useSelector(
    (state) => state.forms.userProfileModal.profilePicture
  )

  const { editMode } = props
  const ProfilePicture = () => {
    return (
      <S3Image
        dimensions={{
          width: '200px',
          height: '200px',
          borderRadius: '50%',
        }}
        imagePath={`${cognitoUser}/Profile`}
      />
    )
  }
  const ProfilePhotoUpload = (props) => {
    const [hovered, setHovered] = useState(false)
    const openFile = () => {
      document.getElementById('profile-photo').click()
    }
    return (
      <Box
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        sx={{
          position: 'relative',
          width: '200px',
          height: '200px',
          borderRadius: '50%',
          backgroundSize: '200px 200px',
          backgroundImage: `url(${data})`,
        }}
      >
        <input
          id="profile-photo"
          type="file"
          style={{ display: 'none' }}
          accept=".gif,.jpg,.jpeg,.png"
          onChange={(e) => {
            console.log('setting Uploaded Image: ', e.target.files[0])
            dispatch(setUploadedImage(URL.createObjectURL(e.target.files[0])))
            dispatch(
              setUserProfilePicture(URL.createObjectURL(e.target.files[0]))
            )
            dispatch(setGeneralModalComponent('cropper'))
            dispatch(setGeneralModalOpen(true))
          }}
        />
        {hovered && (
          <>
            <Box
              onClick={openFile}
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '200px',
                height: '200px',
                borderRadius: '50%',
                backgroundColor: 'gunMetal.main',
                opacity: 0.5,
                zIndex: 50,
              }}
            >
              <UploadProfileIcon
                style={{
                  width: '200px',
                  height: '200px',
                  zIndex: 100,
                }}
              />
            </Box>
            <UploadProfileIcon
              style={{
                width: '200px',
                height: '200px',
              }}
            />
          </>
        )}
      </Box>
    )
  }

  return (
    <Grid item>
      <SubTitleA text="Profile photo" />
      {!editMode ? (
        <ProfilePicture src={data ? data : 'https://via.placeholder.com/150'} />
      ) : (
        <ProfilePhotoUpload
          backgroundSrc={data ? data : 'https://via.placeholder.com/150'}
        />
      )}
    </Grid>
  )
}

export default ProfileImageComponent
