import { Button, Grid, Input, Typography } from '@mui/material'
import InfoText from '../text/InfoText'
import SubTitleA from '../text/SubTitleA'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import TitleC from '../text/TitleC'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import React from 'react'
export default function EmergencyContacts(props) {
  const [emergencyContactTwo, setEmergencyContactTwo] = React.useState(false)
  const EmergencyContact = (props) => {
    const { second = false } = props
    return (
      <>
        <Grid
          container
          xs={12}
          style={{
            padding: '20px 0 20px 0',
            justifyContent: 'space-around',
            rowGap: '16px',
          }}
        >
          <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
            <SubTitleA
              text={`${second ? '2nd' : ''} Emergency contact first name`}
            />
            <Input fullWidth placeholder="first name"></Input>
          </Grid>
          <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
            <SubTitleA
              text={`${second ? '2nd' : ''} Emergency contact last name`}
            />
            <Input fullWidth placeholder="last name"></Input>
          </Grid>
          <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
            <SubTitleA text="Phone number" />
            <Input fullWidth placeholder="phone number"></Input>
          </Grid>
          <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
            <SubTitleA text="Relationship to you" />
            <Input fullWidth placeholder="relationship"></Input>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
    <Grid item xs={6}>
      <EmergencyContact />
      {emergencyContactTwo && <EmergencyContact second={true} />}
      <Grid container>
        {!emergencyContactTwo && (
          <Button
            variant="outlined"
            onClick={() => {
              setEmergencyContactTwo(true)
            }}
          >
            <PlusIcon style={{ padding: '5px' }} />
            2nd emergency contact
          </Button>
        )}
      </Grid>
      </Grid>
    </>
  )
}
