import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Badge,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import Header from '../header/Header'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'

import { ReactComponent as Pencil } from '../../assets/icons/pencil.svg'
import { ReactComponent as PencilLine } from '../../assets/icons/pencil_line.svg'
import { ReactComponent as Plus } from '../../assets/icons/plus.svg'
import { ReactComponent as Trash } from '../../assets/icons/trash.svg'
import { ReactComponent as FloppyDisk } from '../../assets/icons/floppy_disk.svg'
import { ReactComponent as FacilityIcon } from '../../assets/icons/facility.svg'
import CellphoneIcon from '../../assets/icons/cellphone.png'
import OutdoorIcon from '../../assets/icons/outdoor.png'
import { ReactComponent as CommuteIcon } from '../../assets/icons/commute.svg'

import Profile from '../../assets/profile_picture.png'
import body from '../../assets/body.svg'
import styled from '@emotion/styled'

import {
  Add,
  BoltOutlined,
  CalendarMonthOutlined,
  Cancel,
  CancelOutlined,
  CheckCircleOutline,
  Delete,
  Edit,
  InfoOutlined,
  LocationOnOutlined,
  PauseCircle,
  PauseCircleOutline,
  PhotoCamera,
  Remove,
  RemoveCircle,
  Search,
  Star,
  Upload,
  UploadFile,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import CheckCircle from '../../assets/icons/CheckCircle.svg'
import XCircle from '../../assets/icons/XCircle.svg'
import { SearchOutlined } from '@mui/icons-material'
import Masonry from '@mui/lab/Masonry'
import UpcomingSessionPreview from '../session_card/UpcomingSessionPreview'
import SessionCard from '../session_card/SessionCard'
import PopMap from '../location/PopMap'
import AvailabilityModal from './AvailabilityModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCreateSessionField,
  setSessionCommuteField,
  setSessionIndoorLocationField,
  setSessionOutdoorLocationField,
  setUploadedImage,
  setUserProfilePicture,
  setUserSessionPicture,
} from '../../redux/slices/formsSlice'
import { API, graphqlOperation } from 'aws-amplify'
import {
  createSession,
  createLocation,
  createEquipment,
} from '../../graphql/mutations'

import { getSession } from '../../graphql/queries'
import {
  useCreateSessionMutation,
  useGetSessionDetailsByIdQuery,
} from '../../redux/api/api'
import GeneralModal from '../modals/GeneralModal'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../redux/slices/uiSlice'
import SubTitleA from '../text/SubTitleA'
import { useNavigate, useParams } from 'react-router-dom'
import S3Image from '../S3Image'

export default function SessionDetailsComponent(props) {
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)

  const { sessionId } = useParams()
  const { data, error, isLoading, isSuccess } = useGetSessionDetailsByIdQuery(
    sessionId,
    {
      skip: sessionId === 0 || sessionId === '0',
    }
  )

  //const {sessionDetailsRequest} = useGetSessionDetailsByIdQuery(sessionId)
  const newSession = {
    id: 0,
    title: 'Session Title',
    description: 'Session Description',
    inPerson: false,
    virtual: false,
    maxTimeAfterStart: 0,
    category: 'Category',
    city: 'City',
    state: 'State',
    defaultPrice: 0,
    commuteToMember: false,
    maxCommuteDistance: 0,
    commuteFlatFee: 0,
    cheerleader: 0,
    drillSargeant: 0,
    professor: 0,
    workoutBuddy: 0,
    thrityMinsPrice: 0,
    sixtyMinsPrice: 0,
    ninetyMinsPrice: 0,
    oneTwentyMinsPrice: 0,
    coverPhotoIndex: 0,
    sessionPhotos: [],
    instantBookingEnabled: false,
    minTimeBeforeInstantBooking: 24,
    indoorLocation: {
      name: 'testname remove!',
      address: 'test',
      isIndoor: true,
      directions: 'test',
      lat: 0,
      lng: 0,
      // city: '',
      // state: '',
      // zip: '',
      // lat: 0,
      // lng: 0,
    },
    outdoorLocation: {
      name: 'Hollywood Hills',
      address: '12345 Hollywood Blvd',
      isIndoor: false,
      directions: 'Meet by the blue sign on the side of the road',
      lat: 0,
      lng: 0,
      // city: '',
      // state: '',
      // zip: '',
      // lat: 0,
      // lng: 0,
    },
    commute: {
      commuteEnabled: false,
      maxDistance: 0,
      flatFee: 0,
    },
    equipment: [],
    paused: false,
    searchable: false,
    availability: {
      //durationsEnabled: [],
      maxSessionsPerDay: 0,
      minTimeBetweenSessions: 0,
      minTimeBeforeBooking: 0,
      isRepeat: true,
      startDate: 'default start',
      endDate: 'default end',
      daysAvailable: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    },
  }
  //const { session } = props
  const session = data ? data : newSession
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const createSessionForm = useSelector(
    (state) => state.forms.createSessionForm
  )

  const [editMode, setEditMode] = React.useState(false)
  const [locationViewing, setLocationViewing] = React.useState('indoor')
  const [checkedCommute, setCheckedCommute] = React.useState(false)
  const [indoorChecked, setIndoorChecked] = React.useState(false)
  const [outdoorChecked, setOutdoorChecked] = React.useState(false)
  const [commuteMaxDistance, setCommuteMaxDistance] = React.useState(0)

  const [
    createSessionTrigger, // This is the mutation trigger
    { isLoading: isUpdating, data: createSessionData }, // This is the destructured mutation result
  ] = useCreateSessionMutation()

  const handleSetSessionFormField = (field, value) => {
    console.debug('set session form field: ', field, value)
    dispatch(setCreateSessionField({ field, value }))
  }

  React.useEffect(() => {
    if (session.id === 0) {
      console.log('new session')
      setEditMode(true)
      openSessionIntro()
      // should also set the pop up open
    }
  }, [])

  React.useEffect(() => {
    if (createSessionData) {
      console.log(
        'create session data: ',
        createSessionData.data.createSession.id
      )
      navigate(`../session-details/${createSessionData.data.createSession.id}`)
    }
  }, [createSessionData])

  const handleBookNow = () => {
    console.debug('book now')
  }

  const handleSaveSesssion = async () => {
    console.log('save session: ', createSessionForm)
    return createSessionTrigger({
      sessionData: createSessionForm,
      id: cognitoUser.sub,
    })
  }

  const openSessionIntro = () => {
    dispatch(setGeneralModalComponent('session_intro'))
    dispatch(setGeneralModalOpen(true))
  }

  const handleEditPricing = () => {
    dispatch(setGeneralModalComponent('session_pricing'))
    dispatch(setGeneralModalOpen(true))
  }

  const handleChangeIndoorField = (address) => {
    console.log('change indoor field: ', address)
    dispatch(
      setSessionIndoorLocationField({
        field: 'address',
        value: address.place_name,
      })
    )
    dispatch(
      setSessionIndoorLocationField({
        field: 'lat',
        value: address.center[1],
      })
    )
    dispatch(
      setSessionIndoorLocationField({
        field: 'lng',
        value: address.center[0],
      })
    )
  }

  const handleChangeIndoorDirections = (e) => {
    console.log('change indoor directions: ', e.target.value)
    dispatch(
      setSessionIndoorLocationField({
        field: 'directions',
        value: e.target.value,
      })
    )
  }

  const handleChangeOutdoorDirections = (e) => {
    console.log('change outdoor directions: ', e.target.value)
    dispatch(
      setSessionOutdoorLocationField({
        field: 'directions',
        value: e.target.value,
      })
    )
  }

  const handleChangeOutdoorField = (address) => {
    console.log('change outdoor field: ', address)
    dispatch(
      setSessionOutdoorLocationField({
        field: 'address',
        value: address.place_name,
      })
    )
    dispatch(
      setSessionOutdoorLocationField({
        field: 'lat',
        value: address.center[1],
      })
    )
    dispatch(
      setSessionOutdoorLocationField({
        field: 'lng',
        value: address.center[0],
      })
    )
  }

  const TitleRow = (props) => {
    return (
      <Grid container sx={{ p: 2, justifyContent: 'left' }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: '34px', lineHeight: '51px' }}
        >
          {createSessionForm?.title
            ? createSessionForm?.title
            : 'Session Title'}{' '}
          With{' '}
          {session?.trainer?.username
            ? session?.trainer?.username
            : cognitoUser?.given_name +
              ' ' +
              cognitoUser?.family_name.substring(0, 1) +
              '.'}
        </Typography>
        <Typography
          color="primary"
          sx={{
            fontWeight: 700,
            fontSize: '17px',
            lineHeight: '25.5px',
            pl: 2,
          }}
        >
          He/Him
        </Typography>
        {editMode && (
          <IconButton sx={{ ml: 2 }} onClick={openSessionIntro}>
            <Pencil />
          </IconButton>
        )}

        <Grid item style={{ marginLeft: 'auto' }}>
          <Grid container sx={{ justifyContent: 'flex-end' }}>
            <Typography
              display="inline"
              sx={{ color: editMode ? 'primary.main' : null }}
            >
              Edit Mode
            </Typography>
            <Switch
              checked={!editMode}
              onChange={() => {
                setEditMode(!editMode)
              }}
            />
            <Typography
              display="inline"
              sx={{ color: !editMode ? 'primary.main' : null }}
            >
              Public View
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const DetailHeaderCard = (props) => {
    return (
      <Grid
        container
        sx={{
          p: 2,
          bgcolor: 'white',
          borderRadius: '16px',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <Grid item style={{ width: '285px' }}>
          <Grid container>
            <S3Image
              dimensions={{
                height: '166px',
                width: '285px',
                borderRadius: '16px',
              }}
              imagePath={session.sessionPhotos[0]}
            />
          </Grid>
          <Grid container sx={{ justifyContent: 'space-between', pt: 1 }}>
            <Chip
              label={
                createSessionForm?.category
                  ? createSessionForm?.category
                  : 'Category empty'
              }
              sx={{ color: 'white', bgcolor: 'gunMetal.main' }}
              deleteIcon={editMode ? <Pencil /> : null}
              onDelete={editMode ? openSessionIntro : null}
            />
            <Grid item>
              <Grid container sx={{ justifyContent: 'flex-end' }}>
                <Star></Star> <Typography>4.8</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: editMode ? 'flex-start' : 'space-between',
              pt: 1,
              alignItems: 'center',
            }}
          >
            <Typography>
              {createSessionForm.city}, {' ' + createSessionForm.state}{' '}
            </Typography>{' '}
            {editMode && (
              <Grid item>
                <Grid container sx={{ justifyContent: 'flex-start' }}>
                  <Pencil onClick={openSessionIntro}></Pencil>
                </Grid>
              </Grid>
            )}
            <Typography style={{ marginLeft: 'auto' }}>$47/hour</Typography>
          </Grid>
        </Grid>

        <Grid item sx={{ p: 2 }} xs={12} sm={5}>
          <Grid container>
            <Grid item xs={editMode ? 10 : 12}>
              <Typography
                sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '39px' }}
              >
                {createSessionForm.title}
              </Typography>{' '}
            </Grid>
            {editMode && (
              <Grid item xs={2}>
                <Grid container sx={{ justifyContent: 'flex-end' }}>
                  <Pencil onClick={openSessionIntro}></Pencil>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            sx={{ textAlign: 'left', pt: 2, justifyContent: 'flex-start' }}
          >
            <Grid item xs={editMode ? 10 : 12}>
              <Typography
                sx={{ fontWeight: 400, fontSize: '15px', lineHeight: '22.5px' }}
              >
                {editMode ? createSessionForm.description : session.description}
              </Typography>{' '}
            </Grid>
            {editMode && (
              <Grid item xs={2}>
                <Grid container sx={{ justifyContent: 'flex-end' }}>
                  <Pencil onClick={openSessionIntro}></Pencil>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container sx={{ pt: 2 }}>
            <Button color="primary" variant="contained" onClick={handleBookNow}>
              Book Now
            </Button>
          </Grid>
        </Grid>

        <Grid item sx={{ height: '100%' }} xs={12} sm={3}>
          <Grid container justifyContent="start">
            {!editMode && (
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '21px',
                }}
              >
                Offers In Person
              </Typography>
            )}
            {editMode && (
              <Grid container alignItems="center" justifyContent="start">
                <Switch />
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  In-Person
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="start">
            {!editMode && (
              <>
                <img src={CellphoneIcon} />
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  Offers Virtual
                </Typography>{' '}
              </>
            )}
            {editMode && (
              <Grid container alignItems="center" justifyContent="start">
                <Switch />
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  Virtual
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="start">
            {!editMode && (
              <>
                <img src={CellphoneIcon} />
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  Offers Virtual
                </Typography>{' '}
              </>
            )}
            {editMode && (
              <Grid container alignItems="center" justifyContent="start">
                <Grid item xs={9}>
                  <Typography
                    sx={{
                      color: 'gunMetal.main',
                      fontWeight: 700,
                      fontSize: '15px',
                      lineHeight: '22.5px',
                    }}
                  >
                    Max time a client cant enter session after start time
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    onClick={() =>
                      handleSetSessionFormField(
                        'instantBookingMinimum',
                        'test2'
                      )
                    }
                  >
                    Test
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const LocationsCard = (props) => {
    return (
      <Grid
        container
        sx={{
          p: 2,
          bgcolor: 'white',
          borderRadius: '16px',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <Grid container justifyContent="left">
          <Grid item sx={{ pl: 2 }}>
            <Box
              sx={{
                bgcolor:
                  locationViewing === 'indoor'
                    ? 'primary.main'
                    : 'text.disabled',
                width: '100%',
                height: 2,
              }}
            ></Box>
            <Button onClick={() => setLocationViewing('indoor')}>
              <Typography
                sx={{
                  color:
                    locationViewing === 'indoor'
                      ? 'primary.main'
                      : 'text.disabled',
                  fontWeight: 700,
                  fontSize: '15px',
                  lineHeight: '22.5px',
                  letterSpacing: '1.5px',
                }}
              >
                INDOOR LOCATION
              </Typography>
            </Button>
          </Grid>
          <Grid item sx={{ pl: 2, pr: 2 }}>
            <Box
              sx={{
                bgcolor:
                  locationViewing === 'outdoor'
                    ? 'primary.main'
                    : 'text.disabled',
                width: '100%',
                height: 2,
              }}
            ></Box>
            <Button
              sx={{ width: '100%' }}
              onClick={() => setLocationViewing('outdoor')}
            >
              <Typography
                sx={{
                  color:
                    locationViewing === 'outdoor'
                      ? 'primary.main'
                      : 'text.disabled',
                  fontWeight: 700,
                  fontSize: '15px',
                  lineHeight: '22.5px',
                  letterSpacing: '1.5px',
                }}
              >
                OUTDOOR LOCATION
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item sx={{ p: 2 }} xs={12} sm={3}>
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '15px',
                lineHeight: '22.5px',
                color: 'gunMetal.main',
                pb: 1,
              }}
            >
              {locationViewing === 'indoor'
                ? createSessionForm?.indoorLocation?.address
                : createSessionForm?.outdoorLocation?.address}
            </Typography>{' '}
          </Grid>
          {/* <Grid container sx={{ justifyContent: 'space-between' }}>
            <Typography>
              {locationViewing === 'indoor'
                ? createSessionForm?.indoorLocation?.address
                : createSessionForm?.outdoorLocation?.address}
            </Typography>{' '}
          </Grid> */}
        </Grid>

        <Grid item sx={{ p: 2 }} xs={12} sm={6}>
          <Grid
            container
            sx={{ textAlign: 'left', justifyContent: 'flex-start' }}
            alignItems="start"
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '15px',
                lineHeight: '22.5px',
                color: 'primary.main',
              }}
            >
              Open Directions
            </Typography>{' '}
            <PopMap
              editMode={false}
              lat={
                locationViewing === 'indoor'
                  ? createSessionForm?.indoorLocation?.lat
                  : createSessionForm?.outdoorLocation?.lat
              }
              lng={
                locationViewing === 'indoor'
                  ? createSessionForm?.indoorLocation?.lng
                  : createSessionForm?.outdoorLocation?.lng
              }
            />
          </Grid>
        </Grid>

        <Grid item sx={{ height: '100%', p: 2 }} xs={12} sm={3}>
          <Grid container justifyContent="start">
            <FacilityIcon />
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '21px',
              }}
            >
              Teaches out of a facility
            </Typography>{' '}
          </Grid>
          <Grid container justifyContent="start">
            <img src={OutdoorIcon} />
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '21px',
              }}
            >
              Meets Outdoors
            </Typography>{' '}
          </Grid>
          <Grid container justifyContent="start">
            <CommuteIcon />
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '21px',
              }}
            >
              Commutes to you
            </Typography>{' '}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const handleChangeCommute = (e) => {
    console.log('handleChangeCommute', e.target.checked)
    setCheckedCommute(e.target.checked)
    dispatch(
      setSessionCommuteField({
        field: 'commuteToMember',
        value: e.target.checked,
      })
    )
  }
  const handleChangeMaxCommuteDistance = (e) => {
    dispatch(
      setSessionCommuteField({
        field: 'maxCommuteDistance',
        value: e.target.value,
      })
    )
    setCommuteMaxDistance(e.target.value)
  }

  const handleIndoorCheckClick = (e) => {
    console.log('handleIndoorCheckClick', e.target.checked)
    setIndoorChecked(e.target.checked)
    // dispatch(
    //   setSessionIndoorField({
    //     field: 'indoorLocation',
    //     value: e.target.checked,
    //   })
    // )
  }

  const handleOutdoorCheckClick = (e) => {
    console.log('handleOutdoorCheckClick', e.target.checked)
    setOutdoorChecked(e.target.checked)
    // dispatch(
    //   setSessionIndoorField({
    //     field: 'indoorLocation',
    //     value: e.target.checked,
    //   })
    // )
  }
  const LocationsCardEditMode = (props) => {
    return (
      <Grid
        container
        sx={{
          p: 2,
          bgcolor: 'white',
          borderRadius: '16px',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <Grid container justifyContent="left" sx={{ p: 2, pb: 0 }}>
          <Typography
            sx={{
              color: 'gunMetal.primary',
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
            }}
          >
            Indoor Location
          </Typography>
        </Grid>
        <Grid container justifyContent="left" sx={{ p: 2, pt: 0 }}>
          <Typography
            sx={{
              color: 'gunMetal.primary',
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22.5px',
            }}
          >
            They come to your basecamp, gym or studio:
          </Typography>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="start"
          sx={{ p: 2, pt: 0 }}
        >
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={indoorChecked}
                    onChange={handleIndoorCheckClick}
                  />
                }
                label="Gym or Indoor Facility Available"
              />
            </FormGroup>
          </Grid>

          {indoorChecked && (
            <>
              <Grid item>
                <SubTitleA
                  text={
                    'Address: ' + createSessionForm?.indoorLocation?.address
                  }
                />
              </Grid>
              <Grid item sx={{ width: '100%', height: 400 }}>
                <PopMap
                  editMode={true}
                  setLocationData={handleChangeIndoorField}
                  lat={createSessionForm?.indoorLocation?.lat}
                  lng={createSessionForm?.indoorLocation?.lng}
                />
              </Grid>
              <Grid container justifyContent="flex-start">
                <SubTitleA text="Additional Directions (Optional) " />
              </Grid>
              <Grid container justifyContent="flex-start">
                <TextField
                  multiline
                  sx={{
                    width: '100%',
                    pb: '32px',
                    '& fieldset': { borderRadius: '25px' },
                  }}
                  placeholder=""
                  onChange={handleChangeIndoorDirections}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container justifyContent="left" sx={{ p: 2, pb: 0 }}>
          <Typography
            sx={{
              color: 'gunMetal.primary',
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
            }}
          >
            Outdoor Location
          </Typography>
        </Grid>
        <Grid container justifyContent="left" sx={{ p: 2, pt: 0 }}>
          <Typography
            sx={{
              color: 'gunMetal.primary',
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22.5px',
            }}
          >
            Meet up at a local park or outdoor location:
          </Typography>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="start"
          sx={{ p: 2, pt: 0 }}
        >
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={outdoorChecked}
                    onChange={handleOutdoorCheckClick}
                  />
                }
                label="Outdoor Location Available"
              />
            </FormGroup>
          </Grid>
          {outdoorChecked && (
            <>
              <Grid item>
                <SubTitleA
                  text={
                    'Address: ' + createSessionForm?.outdoorLocation?.address
                  }
                />
              </Grid>

              <Grid item sx={{ width: '100%', height: 400 }}>
                <PopMap
                  editMode={true}
                  setLocationData={handleChangeOutdoorField}
                  lat={createSessionForm?.outdoorLocation?.lat}
                  lng={createSessionForm?.outdoorLocation?.lng}
                />
              </Grid>
              <Grid container justifyContent="flex-start">
                <SubTitleA text="Additional Directions (Optional) " />
              </Grid>
              <Grid container justifyContent="flex-start">
                <TextField
                  multiline
                  sx={{
                    width: '100%',
                    pb: '32px',
                    '& fieldset': { borderRadius: '25px' },
                  }}
                  placeholder=""
                  onChange={handleChangeOutdoorDirections}
                  value={createSessionForm?.outdoorLocation?.directions}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid container justifyContent="left" sx={{ p: 2 }}>
          <Typography
            sx={{
              color: 'gunMetal.primary',
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
            }}
          >
            Commute to Member
          </Typography>
          <Grid container justifyContent="left" sx={{ p: 0 }}>
            <Switch checked={checkedCommute} onChange={handleChangeCommute} />
            <Typography>
              Enable if you are willing to commute to your Member Bookings.
            </Typography>
          </Grid>
          {checkedCommute && (
            <>
              <Grid container justifyContent="left" sx={{ p: 0 }}>
                <Typography>
                  {' '}
                  What is the max distance you're willing to travel?
                </Typography>
                <FormControl sx={{ m: 1 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={commuteMaxDistance}
                    label="maxCommuteDistance"
                    onChange={handleChangeMaxCommuteDistance}
                  >
                    <MenuItem value={5}>5 MI</MenuItem>
                    <MenuItem value={10}>10 MI</MenuItem>
                    <MenuItem value={15}>15 MI</MenuItem>
                    <MenuItem value={20}>20 MI</MenuItem>
                    <MenuItem value={25}>25 MI</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container justifyContent="left" sx={{ p: 0 }}>
                <Typography>
                  Up to 10 miles free of commute to Member and after that is a
                  Flat Fee
                </Typography>
              </Grid>
              <Grid container justifyContent="left" sx={{ p: 0 }}>
                <Typography>Input dollar amount</Typography>
                <FormControl sx={{ m: 1 }}>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    //value={values.amount}
                    //onChange={handleChange('amount')}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    )
  }

  const EquipmentCard = (props) => {
    return (
      <Grid
        container
        sx={{
          p: 2,
          bgcolor: 'white',
          borderRadius: '16px',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <Grid container justifyContent="left" alignItems="start">
          {
            <EquipmentList
              listTitle="Equipment"
              listItems={createSessionForm?.equipment}
            />
          }
        </Grid>
      </Grid>
    )
  }

  const EquipmentList = (props) => {
    const { listTitle, listItems } = props
    const [adding, setAdding] = React.useState(false)
    const [newItem, setNewItem] = React.useState('')
    return (
      <Grid item sx={{ pl: 2, pr: 2 }} xs={12} sm={4}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '15px',
            lineHeight: '22.5px',
            letterSpacing: '1.5px',
          }}
        >
          {listTitle}
        </Typography>
        <Grid container justifyContent="left">
          <ul style={{ paddingLeft: 25 }}>
            {listItems.map((item) => (
              <li key={item}>
                <Typography>
                  {item}
                  {editMode && (
                    <IconButton sx={{ pl: 1 }}>
                      <Edit sx={{ color: 'gunMetal.primary' }} />
                    </IconButton>
                  )}
                </Typography>
              </li>
            ))}
          </ul>
        </Grid>
        {editMode && adding && (
          <Grid container justifyContent="left">
            <TextField
              sx={{
                width: '100%',
                pb: '32px',
                '& fieldset': { borderRadius: '25px' },
              }}
              placeholder="Add Item"
              onChange={(e) => setNewItem(e.target.value)}
            />
            <Button
              variant="outlined"
              sx={{
                borderRadius: '25px',
                height: '40px',
              }}
              onClick={() =>
                handleSetSessionFormField('equipment', [
                  ...createSessionForm.equipment,
                  newItem,
                ])
              }
            >
              <Grid container alignItems="center">
                <Typography
                  sx={{
                    color: 'gunMetal.primary',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '22.5px',
                    letterSpacing: '1.5px',
                  }}
                >
                  Save
                </Typography>
              </Grid>
            </Button>
          </Grid>
        )}
        {editMode && !adding && (
          <Grid container justifyContent="left">
            <Button
              variant="outlined"
              sx={{
                borderRadius: '25px',
                height: '40px',
              }}
              onClick={() => setAdding(true)}
            >
              <Grid container alignItems="center">
                <Add sx={{ mr: 1 }} />
                <Typography
                  sx={{
                    color: 'gunMetal.primary',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '22.5px',
                    letterSpacing: '1.5px',
                  }}
                >
                  Add Item
                </Typography>
              </Grid>
            </Button>
          </Grid>
        )}
      </Grid>
    )
  }

  const TrainingTypeRating = (props) => {
    const { trainingType, rating, trainingTypeField } = props
    return (
      <Grid container justifyContent="space-between" sx={{ p: 1 }}>
        <Grid item xs={6}>
          <Typography>{trainingType}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="space-between">
            {[1, 2, 3, 4, 5].map((item) => (
              <>
                <Box
                  onClick={() =>
                    dispatch(
                      setCreateSessionField({
                        field: trainingTypeField,
                        value: item,
                      })
                    )
                  }
                  sx={{
                    borderRadius: '50%',
                    width: 19,
                    height: 19,
                    bgcolor: item <= rating ? 'primary.main' : 'white',
                    borderColor:
                      item <= rating ? 'primary.main' : 'gunMetal.main',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    p: 0.15 + 0.2 * item,
                  }}
                >
                  <Grid container>
                    <Typography
                      sx={{
                        color: item <= rating ? 'white' : 'gunMetal.main',
                      }}
                    >
                      {item}
                    </Typography>
                  </Grid>
                </Box>
                {item < 5 && (
                  <Box
                    sx={{
                      width: 5,
                      height: 2,
                      bgcolor: 'gunMetal.main',
                    }}
                  ></Box>
                )}
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const TrainingStyleCard = (props) => {
    return (
      <Grid
        container
        sx={{
          p: 2,
          bgcolor: 'white',
          borderRadius: '16px',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <Grid container justifyContent="space-between" alignItems="start">
          <Grid item sx={{ pl: 2, pr: 2 }} xs={12} sm={12} md={6}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '15px',
                lineHeight: '22.5px',
                letterSpacing: '1.5px',
              }}
            >
              {cognitoUser?.given_name}'s Style of Training
            </Typography>
            {
              <TrainingTypeRating
                trainingType="Cheerleader"
                trainingTypeField="cheerleader"
                rating={createSessionForm.cheerleader}
              />
            }
            {
              <TrainingTypeRating
                trainingType="Drill Sargeant"
                trainingTypeField="drillSargeant"
                rating={createSessionForm.drillSargeant}
              />
            }
            {
              <TrainingTypeRating
                trainingType="Professor"
                trainingTypeField="professor"
                rating={createSessionForm.professor}
              />
            }
            {
              <TrainingTypeRating
                trainingType="Workout Buddy"
                trainingTypeField="workoutBuddy"
                rating={createSessionForm.workoutBuddy}
              />
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const CalendarCard = (props) => {
    return (
      <Grid
        container
        sx={{
          p: 2,
          pl: 4,
          bgcolor: 'white',
          borderRadius: '16px',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <Grid container justifyContent="start" alignItems="start">
          <Typography> You currently have no available Sessions</Typography>
        </Grid>
        <AvailabilityModal />
      </Grid>
    )
  }

  const PricingCard = (props) => {
    return (
      <Grid
        container
        sx={{
          p: 2,
          pl: 4,
          bgcolor: 'white',
          borderRadius: '16px',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
              color: 'gunMetal.main',
            }}
          >
            {' '}
            Default preview pricing
          </Typography>
          <IconButton sx={{ ml: 2 }} onClick={handleEditPricing}>
            <Pencil />
          </IconButton>

          <Grid justifyContent="start" alignItems="center" container>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'gunMetal.main',
                color: 'white',
                height: 45,
                mr: 2,
              }}
            >
              {' '}
              $50 / HR
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const SessionPreviewCard = (props) => {
    const [imageSelected, setImageSelected] = React.useState(1)
    const [imageHovered, setImageHovered] = React.useState(null)

    return (
      <Grid
        container
        sx={{
          p: 2,
          pl: 4,
          bgcolor: 'white',
          borderRadius: '16px',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <Grid item xs={7}>
          <Grid
            container
            justifyContent="start"
            alignItems="start"
            sx={{ p: 2 }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '26px',
                lineHeight: '39px',
                color: 'gunMetal.main',
              }}
            >
              {' '}
              Select Session Cover Photo
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="start"
            sx={{ p: 2, pl: 0, pb: 0 }}
          >
            {createSessionForm?.sessionPhotos.map((item, index) => (
              <Grid item sx={{ p: 2, pt: 0 }}>
                {imageSelected === index && (
                  <CheckCircleOutline
                    style={{
                      width: 50,
                      height: 50,
                      position: 'absolute',
                      paddingTop: 64 - 25,
                      paddingLeft: 64 - 25,
                    }}
                  />
                )}
                <Tooltip
                  title="Delete"
                  style={{
                    width: 50,
                    height: 50,
                    position: 'absolute',
                    marginTop: -25,
                    marginLeft: 100,
                  }}
                >
                  <IconButton>
                    <Cancel color="error" />
                  </IconButton>
                </Tooltip>

                <img
                  onMouseEnter={() => setImageHovered(index)}
                  onMouseLeave={() => setImageHovered(null)}
                  onClick={() => setImageSelected(index)}
                  src={item}
                  style={{
                    cursor: 'pointer',
                    width: 128,
                    height: 128,
                    borderRadius: '20px',
                    opacity:
                      imageSelected === index || imageHovered === index
                        ? 1
                        : 0.5,
                  }}
                />
                <Grid container>
                  <Tooltip title="Upload">
                    <IconButton
                      onClick={() =>
                        document.getElementById('session-photo').click()
                      }
                    >
                      <input
                        id="session-photo"
                        type="file"
                        style={{ display: 'none' }}
                        accept=".gif,.jpg,.jpeg,.png"
                        onChange={(e) => {
                          console.log(
                            'setting Uploaded Image: ',
                            e.target.files[0]
                          )
                          dispatch(
                            setUploadedImage({
                              data: URL.createObjectURL(e.target.files[0]),
                              session: true,
                            })
                          )
                          dispatch(
                            setUserSessionPicture(
                              URL.createObjectURL(e.target.files[0])
                            )
                          )
                          dispatch(setGeneralModalComponent('session_cropper'))
                          dispatch(setGeneralModalOpen(true))
                        }}
                      />
                      <PhotoCamera />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
            {createSessionForm?.sessionPhotos.length < 4 && (
              <Grid item sx={{ p: 2, pt: 0 }}>
                <Tooltip title="New Photo">
                  <IconButton
                    onClick={() =>
                      document.getElementById('session-photo').click()
                    }
                    sx={{
                      width: 128,
                      height: 128,
                      borderRadius: '20px',
                      border: '1px solid #E5E5E5',
                      backgroundColor: 'white',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <input
                      id="session-photo"
                      type="file"
                      style={{ display: 'none' }}
                      accept=".gif,.jpg,.jpeg,.png"
                      onChange={(e) => {
                        console.log(
                          'setting Uploaded Image: ',
                          e.target.files[0]
                        )
                        dispatch(
                          setUploadedImage({
                            data: URL.createObjectURL(e.target.files[0]),
                            session: true,
                          })
                        )
                        dispatch(
                          setUserSessionPicture(
                            URL.createObjectURL(e.target.files[0])
                          )
                        )
                        dispatch(setGeneralModalComponent('session_cropper'))
                        dispatch(setGeneralModalOpen(true))
                      }}
                    />
                    <PhotoCamera />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <SessionCard />
        </Grid>
      </Grid>
    )
  }

  const ManageIncomingBookingsCard = (props) => {
    const [imageSelected, setImageSelected] = React.useState(1)
    const [imageHovered, setImageHovered] = React.useState(null)

    return (
      <Grid
        container
        sx={{
          p: 2,
          pl: 4,
          bgcolor: 'white',
          borderRadius: '16px',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <Grid container justifyContent="start">
          <BoltOutlined sx={{ pr: 2, color: 'gunMetal.main' }} />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
              color: 'gunMetal.main',
            }}
          >
            {' '}
            Instant Booking
          </Typography>
          <InfoOutlined sx={{ pl: 2, opacity: 0.5 }} color="error" />
        </Grid>
        <Grid container justifyContent="start">
          <Typography sx={{ color: 'gunMetal.main' }}>
            Turn on instant booking?
          </Typography>
          <Switch sx={{ pl: 2 }} />
        </Grid>
        <Grid container justifyContent="start"></Grid>
        <Grid container justifyContent="start">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              <Typography sx={{ color: 'gunMetal.main' }}>
                What is the minimum amount of time before a member can request
                instant booking for your HIIT Training Session?
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="8 hours"
                control={<Radio />}
                label="8 hours"
              />
              <FormControlLabel
                value="24 hours"
                control={<Radio />}
                label="24 hours"
              />
              <FormControlLabel
                value="Manual"
                control={<Radio />}
                label="Manual"
              />
              <FormControlLabel
                value="disabled"
                disabled
                control={<Radio />}
                label="other"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid container justifyContent="start" sx={{ pt: 2 }}>
          <PauseCircleOutline sx={{ pr: 2, color: 'gunMetal.main' }} />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
              color: 'gunMetal.main',
            }}
          >
            {' '}
            Pause Session
          </Typography>
          <InfoOutlined sx={{ pl: 2, opacity: 0.5 }} color="error" />
        </Grid>
        <Grid container justifyContent="start" sx={{ pt: 2 }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22.5px',
              color: 'gunMetal.main',
            }}
          >
            {' '}
            Pause Session
          </Typography>
          <Switch />
        </Grid>
        <Grid container justifyContent="start" sx={{ pt: 2 }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22.5px',
              color: 'gunMetal.main',
            }}
          >
            {' '}
            Keep Session Searchable
          </Typography>
          <Switch />
        </Grid>
        <Grid container justifyContent="start" sx={{ pt: 2 }}>
          <Delete sx={{ pr: 2, color: 'gunMetal.main' }} />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
              color: 'gunMetal.main',
            }}
          >
            {' '}
            Delete Session
          </Typography>
          <InfoOutlined sx={{ pl: 2, opacity: 0.5 }} color="error" />
        </Grid>
        <Grid container justifyContent="start" sx={{ pt: 2 }}>
          <Button color="error">Delete</Button>
        </Grid>
      </Grid>
    )
  }

  const ImageMasonry = () => {
    const itemData = [
      {
        img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
        title: 'Fern',
      },
      {
        img: 'https://images.unsplash.com/photo-1627308595229-7830a5c91f9f',
        title: 'Snacks',
      },
      {
        img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        title: 'Mushrooms',
      },
      {
        img: 'https://images.unsplash.com/photo-1529655683826-aba9b3e77383',
        title: 'Tower',
      },
    ]
    return (
      <Box sx={{ width: '90%', minHeight: 700, p: 2 }}>
        <Masonry columns={3} spacing={2}>
          {itemData.map((item, index) => (
            <div key={index}>
              <img
                src={`${item.img}?w=162&auto=format`}
                srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
                style={{
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  display: 'block',
                  width: '100%',
                }}
              />
            </div>
          ))}
        </Masonry>
      </Box>
    )
  }

  return (
    <Grid container sx={{ p: 2, m: 4, mt: 0 }}>
      {<TitleRow />}
      {<DetailHeaderCard />}
      <Grid container sx={{ p: 2, justifyContent: 'left' }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '39px' }}
        >
          Locations
        </Typography>
      </Grid>
      {editMode ? <LocationsCardEditMode /> : <LocationsCard />}
      <Grid container sx={{ p: 2, justifyContent: 'left' }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '39px' }}
        >
          Equipment
        </Typography>
      </Grid>
      {<EquipmentCard />}
      <Grid container sx={{ p: 2, justifyContent: 'left' }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '39px' }}
        >
          Training Style
        </Typography>
      </Grid>
      {<TrainingStyleCard />}
      {!editMode && <ImageMasonry />}
      {editMode && (
        <Grid container sx={{ p: 2, justifyContent: 'left' }}>
          <Typography
            sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '39px' }}
          >
            Calendar
          </Typography>
        </Grid>
      )}
      {editMode && <CalendarCard />}
      {editMode && (
        <Grid container sx={{ p: 2, justifyContent: 'left' }}>
          <Typography
            sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '39px' }}
          >
            Pricing
          </Typography>
        </Grid>
      )}
      {editMode && <PricingCard />}
      {editMode && (
        <Grid container sx={{ p: 2, justifyContent: 'left' }}>
          <Typography
            sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '39px' }}
          >
            Session Card Preview
          </Typography>
        </Grid>
      )}
      {editMode && <SessionPreviewCard />}
      {editMode && (
        <Grid container sx={{ p: 2, justifyContent: 'left' }}>
          <Typography
            sx={{ fontWeight: 700, fontSize: '26px', lineHeight: '39px' }}
          >
            Manage Incoming Bookings
          </Typography>
        </Grid>
      )}
      {editMode && <ManageIncomingBookingsCard />}
      {!editMode && (
        <Grid container sx={{ p: 2 }}>
          <Button variant="contained" sx={{ bgcolor: 'primary.main' }}>
            Book Now
          </Button>
        </Grid>
      )}
      {editMode && (
        <Grid container sx={{ p: 2 }}>
          <Button
            variant="contained"
            sx={{ bgcolor: 'primary.main' }}
            onClick={handleSaveSesssion}
          >
            Save Session
          </Button>
        </Grid>
      )}
      <GeneralModal />
    </Grid>
  )
}
