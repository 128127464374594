import { Button, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import TitleC from '../text/TitleC'
import IntroduceMember from './IntroduceMember'
import { ReactComponent as LocationIcon } from '../../assets/icons/location_pin.svg'

import { ReactComponent as UploadProfileIcon } from '../../assets/icons/upload_profile.svg'
import { useEffect, useState } from 'react'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import FitnessGoals from './FitnessGoals'
import PreferredTrainingDaysTime from './PreferredTrainingDaysTime'
import ChooseLocation from './ChooseLocation'
import AboutMember from './AboutMember'
import ChooseWorkouts from './ChooseWorkouts'
import EmergencyContacts from './EmergencyContacts'
import BackgroundCheck from './BackgroundCheck'
import MemberInfoB from './MemberInfoB'
import ProfileImageComponent from '../profile/ProfileImageComponent'
import BorderSelectButton from '../buttons/BorderSelectButton'
import PhotoCropper from '../profile/PhotoCropper'
import ProfilePhotoUpload from '../profile/ProfileComponents/ProfilePhotoUpload'

export default function Pages() {
  const dispatch = useDispatch()
  const onboardOpen = useSelector((state) => state.ui.onboardOpen)
  const onboardStep = useSelector((state) => state.ui.onboardStep)
  const DarkSubtitle = (props) => {
    const { text, onClick } = props
    return (
      <>
        <Typography
          sx={{
            color: 'gunMetal.main',
            fontWeight: 700,
            fontSize: 17,
            lineHeight: '25.5px',
          }}
          onClick={onClick}
        >
          {text}
        </Typography>
      </>
    )
  }
  const LightSubtitle = (props) => {
    const { text, onClick } = props
    return (
      <>
        <Typography
          sx={{
            color: 'gunMetal.main',
            fontWeight: 400,
            fontSize: 15,
            lineHeight: '22.5px',
          }}
          onClick={onClick}
        >
          {' '}
          {text}
        </Typography>
      </>
    )
  }
  const RenderStepOne = () => {
    return (
      <>
        <Grid item xs={12}>
          <TitleC text="Introduce yourself!" />
        </Grid>
        <IntroduceMember onboarding />
      </>
    )
  }

  const RenderStepFive = () => {
    return (
      <>
        <Grid item xs={12}>
          <TitleC text="Where do you want to GET TRAIND regularly?" />
        </Grid>
        <Grid container>
          <Button
            variant="outlined"
            //onClick={handleClickSearch}
            sx={{
              width: 258,
              height: 64,
              color: 'gunMetal.main',
              borderColor: 'transparent',
              boxShadow: 1,
            }}
          >
            <Grid container sx={{ p: '20px' }} justifyContent={'left'}>
              <LocationIcon />
              <Grid item sx={{ ml: 1 }}>
                <Grid container sx={{ textAlign: 'left' }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textTransform: 'none',
                        fontWeight: 700,
                        fontSize: '13px',
                      }}
                    >
                      City, State
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 400, fontSize: '9px' }}>
                      Location
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid container sx={{ padding: ' 8px 0 32px 0' }}>
          <Typography
            sx={{ color: 'primary.main', fontWeight: 300, fontSize: 15 }}
          >
            Travel a little
          </Typography>
        </Grid>
        <Grid container>
          <Button
            variant="outlined"
            //onClick={handleClickSearch}
            sx={{
              width: 258,
              height: 64,
              color: 'gunMetal.main',
              borderColor: 'transparent',
              boxShadow: 1,
            }}
          >
            <Grid container sx={{ p: '20px' }} justifyContent={'left'}>
              <LocationIcon />
              <Grid item sx={{ ml: 1 }}>
                <Grid container sx={{ textAlign: 'left' }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textTransform: 'none',
                        fontWeight: 700,
                        fontSize: '13px',
                      }}
                    >
                      City, State
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 400, fontSize: '9px' }}>
                      Location
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid container sx={{ padding: ' 8px 0 32px 0' }}>
          <Typography
            sx={{ color: 'primary.main', fontWeight: 300, fontSize: 15 }}
          >
            Travel a lot
          </Typography>
        </Grid>
        <Grid container>
          <Button
            variant="outlined"
            //onClick={handleClickSearch}
            sx={{
              width: 258,
              height: 64,
              color: 'gunMetal.main',
              borderColor: 'transparent',
              boxShadow: 1,
            }}
          >
            <Grid container sx={{ p: '20px' }} justifyContent={'left'}>
              <LocationIcon />
              <Grid item sx={{ ml: 1 }}>
                <Grid container sx={{ textAlign: 'left' }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textTransform: 'none',
                        fontWeight: 700,
                        fontSize: '13px',
                      }}
                    >
                      City, State
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 400, fontSize: '9px' }}>
                      Location
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid container sx={{ padding: ' 8px 0 32px 0' }}>
          <Typography
            sx={{ color: 'primary.main', fontWeight: 300, fontSize: 15 }}
          >
            Travel a whole lot
          </Typography>
        </Grid>
      </>
    )
  }
  const RenderStepSix = (props) => {
    const { onboarding } = props
    // const { data, isLoading, isSuccess, isError, error } =
    //   useGetMemberTrainingPreferenceByIdQuery('1')
    const [localData, setLocalData] = useState()

    //TODO: WE SHOULD KEEP TRACK OF THIS ON THE UI PROFILE FORM!
    useEffect(() => {
      if (onboarding) {
        console.log('onboarding!!!!!!!')
        setLocalData({
          trainingPreference: {
            preferVirtual: false,
            preferInPerson: false,
            preferOneOnOne: false,
            preferGroup: false,
            trainerIsProfessor: false,
            trainerIsCheerleader: false,
            trainerIsDrillSargent: false,
            trainerIsWorkoutBuddy: false,
          },
        })
        return
      }
      //setLocalData(data)
    }, [])
    // if (isLoading) {
    //   return <></>
    // }
    const handleUpdateSessionType = (selected) => {
      console.log('handleUpdateSessionType: ', selected)
      dispatch(
        setUserProfileModalField({ value: selected, field: 'sessionType' })
      )
    }
    const handleUpdateTrainingStyle = (selected) => {
      console.log('handleUpdateTrainingStyle: ', selected)
      dispatch(
        setUserProfileModalField({ value: selected, field: 'trainingStyle' })
      )
    }

    if (localData) {
      return (
        <>
          <Grid item xs={12}>
            <TitleC text="How do you want to GET TRAIND?" />
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: '20px' }}>
            <DarkSubtitle text={'Session type'} />
            <Grid
              container
              style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
              spacing={'20px'}
            >
              <Grid item>
                <BorderSelectButton
                  text="virtual"
                  active={localData.preferVirtual}
                  onClick={handleUpdateSessionType}
                  // onClick={() => {
                  //   setLocalData({
                  //     ...localData,
                  //     preferVirtual: !localData.preferVirtual,
                  //   })
                  // }}
                />
              </Grid>
              <Grid item>
                <BorderSelectButton
                  text="In person"
                  active={localData.preferInPerson}
                  // onClick={() => {
                  //   setLocalData({
                  //     ...localData,
                  //     preferInPerson: !localData.preferInPerson,
                  //   })
                  // }}
                />
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: '20px' }}>
            <DarkSubtitle text={'Training Style'} />
            <LightSubtitle text="Choose up to three styles of motivation" />
            <Grid
              container
              style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
              spacing={'20px'}
            >
              <Grid item xs={6}>
                <BorderSelectButton
                  text="Professor"
                  active={localData.trainerIsProfessor}
                  onClick={() => {
                    setLocalData({
                      ...localData,
                      trainerIsProfessor: !localData.trainerIsProfessor,
                    })
                  }}
                />
                <LightSubtitle text="The technical educator" />
              </Grid>
              <Grid item xs={6}>
                <BorderSelectButton
                  text="Workout Buddy"
                  active={localData.trainerIsWorkoutBuddy}
                  onClick={() => {
                    setLocalData({
                      ...localData,
                      trainerIsWorkoutBuddy: !localData.trainerIsWorkoutBuddy,
                    })
                  }}
                />
                <LightSubtitle text="The partner" />
              </Grid>
              <Grid item xs={6}>
                <BorderSelectButton
                  text="Cheerleader"
                  active={localData.trainerIsCheerleader}
                  onClick={() => {
                    setLocalData({
                      ...localData,
                      trainerIsCheerleader: !localData.trainerIsCheerleader,
                    })
                  }}
                />
                <LightSubtitle text="The motivator" />
              </Grid>
              <Grid item xs={6}>
                <BorderSelectButton
                  text="Drill Sargeant"
                  active={localData.trainerIsDrillSargent}
                  onClick={() => {
                    setLocalData({
                      ...localData,
                      trainerIsDrillSargent: !localData.trainerIsDrillSargent,
                    })
                  }}
                />
                <LightSubtitle text="The pusher" />
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </>
      )
    }
  }

  const RenderStepNine = () => {
    const openFile = () => {
      document.getElementById('profile-photo').click()
    }
    return (
      <>
        <Grid item xs={12}>
          <TitleC text="Profile Photo" />
        </Grid>
        <Grid container>
          <LightSubtitle text="Upload profile photo" />
        </Grid>
        <Grid container>
          <ProfilePhotoUpload />
          {/* <input id="profile-photo" type="file" style={{ display: 'none' }} />
          <UploadProfileIcon
            style={{ padding: '32px', cursor: 'pointer' }}
            onClick={openFile}
          /> */}
        </Grid>
        <Grid container>
          <PhotoCropper />
        </Grid>
      </>
    )
  }
  const RenderStepTen = () => {
    return (
      <>
        <Grid item xs={12}>
          <TitleC text="Personal Information" />
          <TitleC text="1/2" />
        </Grid>
        <MemberInfoB />
        <Grid container></Grid>
      </>
    )
  }
  const RenderStepEleven = () => {
    const openFile = () => {
      document.getElementById('profile-photo').click()
    }
    return (
      <>
        <Grid item xs={12}>
          <TitleC text="Personal Information" />
          <TitleC text="2/2" />
        </Grid>
        <MemberInfoB />
        <Grid container></Grid>
      </>
    )
  }
  const RenderStepTwelve = () => {
    return (
      <>
        <Grid item xs={12}>
          <TitleC text="ID and Background Check" />
        </Grid>
        <BackgroundCheck />

        <Grid container sx={{ justifyContent: 'space-around' }}>
          <Grid item></Grid>
        </Grid>
      </>
    )
  }
  const RenderStepThirteen = () => {
    return (
      <>
        <Grid item xs={12}>
          <TitleC text="Emergency Contact" />
        </Grid>
        <EmergencyContacts />
      </>
    )
  }

  return (
    <>
      {onboardStep == 1 && <RenderStepOne />}
      {onboardStep == 2 && <FitnessGoals onboarding />}
      {onboardStep == 3 && <PreferredTrainingDaysTime onboarding />}
      {onboardStep == 4 && <ChooseLocation />}
      {onboardStep == 5 && <RenderStepFive />}
      {onboardStep == 6 && <RenderStepSix onboarding />}
      {onboardStep == 7 && <AboutMember />}
      {onboardStep == 8 && <ChooseWorkouts onboarding />}
      {onboardStep == 9 && <RenderStepNine />}
      {onboardStep == 10 && <PhotoCropper />}
      {onboardStep == 11 && <RenderStepTen />}
      {onboardStep == 12 && <RenderStepEleven />}
      {onboardStep == 13 && <RenderStepTwelve />}
      {onboardStep == 14 && <RenderStepThirteen />}
    </>
  )
}
