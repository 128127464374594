import { API, Auth, graphqlOperation } from 'aws-amplify'
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAuthModalOpen,
  setBecomeTrainerModalOpen,
} from '../../redux/slices/uiSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import TitleC from '../text/TitleC'
import TitleB from '../text/TitleB'
import SubTitleA from '../text/SubTitleA'
import SubTitleB from '../text/SubTitleB'
import {
  Cancel,
  CheckCircle,
  Refresh,
  Upload,
  UploadFile,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import SolidColorButton from '../buttons/SolidColorButton'
import GreyTextButton from '../buttons/GreyTextButton'
import { Link, useNavigate } from 'react-router-dom'
import BorderColorButton from '../buttons/BorderColorButton'
import { setIsTrainer } from '../../redux/slices/authSlice'
import { createTrainer } from '../../graphql/mutations'
import ProfileImageComponent from '../profile/ProfileImageComponent'

/*
<Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
            <SubTitleB text={'Email'} />
            <TextField
              value={textFields.username}
              onChange={handleChangeText('username')}
              variant="standard"
              fullWidth
              placeholder="example@email.com"
            />
          </Grid>
*/
export default function BecomeTrainerModal() {
  const navigate = useNavigate()
  const [textFields, setTextFields] = useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    confirmationPassword: '',
    verificationCode: '',
  })
  const [page, setPage] = useState(1)
  const open = useSelector((state) => state.ui.becomeTrainerModalOpen)

  const dispatch = useDispatch()

  const handleCloseBecomeTrainerModal = () => {
    dispatch(setBecomeTrainerModalOpen(false))
  }

  const handleChangeText = (prop) => (event) => {
    setTextFields({ ...textFields, [prop]: event.target.value })
  }

  const handleSaveProfile = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      console.log('user: ', user)
      const trainerFormData = {
        id: user.username,
        username: user.username,
        displayName:
          user?.attributes?.given_name +
          ' ' +
          user?.attributes?.family_name.substring(0, 1),
      }
      const userData = await API.graphql(
        graphqlOperation(createTrainer, {
          input: trainerFormData,
        })
      )
      const testData = userData.data.createTrainer
      console.log('testData: ', testData)
      await Auth.updateUserAttributes(user, {
        'custom:user_type': 'trainer',
      })
      dispatch(setIsTrainer(true))
      navigate('/dashboard')
      dispatch(setBecomeTrainerModalOpen(false))
    } catch (e) {
      console.warn('error saving user trainer data: ', e)
    }
  }
  const RenderPageOne = () => {
    return (
      <Grid container>
        <Grid container sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '26px',
              color: 'primary.main',
              lineHeight: '39px',
            }}
          >
            {' '}
            Trainer Profile Photo
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2, pb: 4 }}>
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: 400,
              fontSize: '17px',
              lineHeight: '25.5px',
            }}
          >
            Please upload a high quality head shot with a plain white background
            or an unobstructed blue sky view.
          </Typography>
        </Grid>
        <Grid container spacing={8} justifyContent="center" sx={{ pb: 2 }}>
          <Grid item>
            <Grid container>
              <Typography
                sx={{ fontWeight: 700, fontSize: '17px', lineHeight: '25.5px' }}
              >
                Acceptable
              </Typography>
            </Grid>
            <Grid container sx={{ pt: 2, bgcolor: 'white' }}>
              <Badge
                badgeContent={
                  <CheckCircle
                    color="primary"
                    sx={{ mr: 1, mt: 1, bgcolor: 'white', borderRadius: '50%' }}
                  />
                }
              >
                <img
                  src="https://via.placeholder.com/150"
                  style={{
                    width: '128px',
                    height: '128px',
                    borderRadius: '20px',
                  }}
                />
              </Badge>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Typography
                sx={{ fontWeight: 700, fontSize: '17px', lineHeight: '25.5px' }}
              >
                Unacceptable
              </Typography>
            </Grid>
            <Grid container sx={{ pt: 2 }}>
              <Badge
                badgeContent={
                  <Cancel
                    color="error"
                    sx={{ mr: 1, mt: 1, bgcolor: 'white', borderRadius: '50%' }}
                  />
                }
              >
                <img
                  src="https://via.placeholder.com/150"
                  style={{
                    width: '128px',
                    height: '128px',
                    borderRadius: '20px',
                  }}
                />
              </Badge>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <ProfileImageComponent />
        </Grid>
        <Grid container sx={{ pt: 2 }}>
          <SolidColorButton text="next" onClick={() => setPage(2)} />
        </Grid>
        <Grid container sx={{ pt: 2 }}>
          <GreyTextButton text="skip for now" onClick={() => setPage(2)} />
        </Grid>
      </Grid>
    )
  }
  const RenderPageTwo = () => {
    return (
      <Grid container>
        <Grid container sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '26px',
              color: 'primary.main',
              lineHeight: '39px',
            }}
          >
            {' '}
            About Me 1/2
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2 }} justifyContent="flex-start" spacing={3}>
          <Grid item>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '17px',
                color: 'gunMetal.main',
                lineHeight: '25.5px',
              }}
            >
              {' '}
              What year did you start training?
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              value={textFields.startYear}
              onChange={handleChangeText('startYear')}
              fullWidth
              placeholder="2022"
            />
          </Grid>
        </Grid>
        <Grid container sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '17px',
              color: 'gunMetal.main',
              lineHeight: '25.5px',
            }}
          >
            What is your favorite aspect of training/teaching/ coaching?{' '}
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2, pb: 1 }}>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            value={textFields.startYear}
            onChange={handleChangeText('startYear')}
            fullWidth
            placeholder="I really enjoy..."
          />
        </Grid>
        <Grid sx={{ width: '95%' }}>
          <Typography
            sx={{
              textAlign: 'right',
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: '13px',
            }}
          >
            0/150
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '17px',
              color: 'gunMetal.main',
              lineHeight: '25.5px',
            }}
          >
            Besides the awesome workout, what is the most important take away
            you want your clients to get from their time with you?
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2, pb: 1 }}>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            value={textFields.startYear}
            onChange={handleChangeText('startYear')}
            fullWidth
            placeholder="The most important takeaway that I wany my clients to 
take is..."
          />
        </Grid>
        <Grid sx={{ width: '95%' }}>
          <Typography
            sx={{
              textAlign: 'right',
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: '13px',
            }}
          >
            0/150
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2 }}>
          <SolidColorButton text="next" onClick={() => setPage(3)} />
        </Grid>
        <Grid container sx={{ pt: 0 }}>
          <GreyTextButton text="skip for now" onClick={() => setPage(3)} />
        </Grid>
      </Grid>
    )
  }
  const RenderPageThree = () => {
    return (
      <Grid container>
        <Grid container sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '26px',
              color: 'primary.main',
              lineHeight: '39px',
            }}
          >
            {' '}
            About Me 2/2
          </Typography>
        </Grid>

        <Grid container sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '17px',
              color: 'gunMetal.main',
              lineHeight: '25.5px',
            }}
          >
            What do you like to call your Trainees or Tribe? (Ex. Renegade
            Brigade){' '}
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2, pb: 1 }}>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            value={textFields.startYear}
            onChange={handleChangeText('startYear')}
            fullWidth
            placeholder="I call my tribe or trainees..."
          />
        </Grid>
        <Grid sx={{ width: '95%' }}>
          <Typography
            sx={{
              textAlign: 'right',
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: '13px',
            }}
          >
            0/150
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '17px',
              color: 'gunMetal.main',
              lineHeight: '25.5px',
            }}
          >
            What are your certifications? And Is there anything else you are
            trained in that you like to teach?{' '}
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2, pb: 1 }}>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            value={textFields.startYear}
            onChange={handleChangeText('startYear')}
            fullWidth
            placeholder="My certifications include: ..."
          />
        </Grid>
        <Grid sx={{ width: '95%' }}>
          <Typography
            sx={{
              textAlign: 'right',
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: '13px',
            }}
          >
            0/150
          </Typography>
        </Grid>
        <Grid container sx={{ p: 2 }} spacing={3}>
          <Grid item>
            <BorderColorButton text="cancel" />
          </Grid>
          <Grid item>
            <SolidColorButton onClick={handleSaveProfile} text="Save" />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const RenderPage = () => {
    switch (page) {
      case 1:
        return <RenderPageOne />
      case 2:
        return <RenderPageTwo />
      case 3:
        return <RenderPageThree />
      default:
        break
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      PaperProps={{
        sx: {
          maxWidth: '596px',
          overflow: 'hidden',
          //width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '95%' }}>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            {page > 1 && (
              <IconButton
                onClick={() => setPage(page - 1)}
                style={{ width: '20px', height: '20px', marginLeft: '20px' }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
          </Grid>

          <Grid item>
            <IconButton
              onClick={handleCloseBecomeTrainerModal}
              style={{ width: '20px', height: '20px', marginRight: '20px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container>
            {page < 4 &&
              [...Array(3)].map((x, i) => (
                <Paper
                  key={i}
                  onClick={() => {
                    setPage(i + 1)
                  }}
                  sx={{
                    cursor: 'pointer',
                    margin: 0.5,
                    height: 15,
                    width: 15,
                    backgroundColor:
                      i + 1 === page ? 'primary.main' : 'gunMetal.light',
                    borderRadius: '50%',
                  }}
                />
              ))}
          </Grid>
          <Grid container>{<RenderPage />}</Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
