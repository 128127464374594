import { Button, Grid, Input, Typography } from '@mui/material'
import InfoText from '../text/InfoText'
import SubTitleA from '../text/SubTitleA'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import BorderSelectButton from '../buttons/BorderSelectButton'
import ContainedSelectButton from '../buttons/ContainedSelectButton'
export default function GenderPronouns(props) {
  return (
    <>
      {' '}
      <Grid container sx={{justifyContent:'flex-start'}}>
        <Grid container sx={{justifyContent:'flex-start'}}>
          <Grid item>
            <SubTitleA text="Gender"></SubTitleA>
          </Grid>
        </Grid>
        
        <Grid item xs={12}><ContainedSelectButton text="FEMALE" /></Grid>
      </Grid>
      <Grid container sx={{justifyContent:'flex-start'}}>
        <SubTitleA text="Preferred pronouns"></SubTitleA>
        <Grid item xs={12}><ContainedSelectButton text="She/her" /></Grid>
      </Grid>
      <Grid container sx={{justifyContent:'flex-start'}}>
        <SubTitleA text="Birth year"></SubTitleA>
        <Grid item xs={12}><ContainedSelectButton text="1995" /></Grid>
      </Grid>
    </>
  )
}
