import * as React from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { Grid, Typography, Divider, Chip, LinearProgress } from '@mui/material'
import SessionCard from '../session_card/SessionCard'
import TrainerAvailabilityModal from './TrainerAvailabilityModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  setTrainerAvailabilityModalVisible,
  setViewTrainerId,
} from '../../redux/slices/uiSlice'

import { useGetSessionsQuery, useGetTrainingQuery } from '../../redux/api/api'
import { setIsTrainer } from '../../redux/slices/authSlice'
import { useEffect } from 'react'
import { listSessions } from '../../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import { setCreateBookingField } from '../../redux/slices/formsSlice'

export default function BrowseSessions(props) {
  const { size } = props
  const userId = useSelector((state) => state.auth.cognitoUser?.sub)
  const dispatch = useDispatch()
  const { data, isLoading, isSuccess } = useGetSessionsQuery({})

  const handleOpenTrainerAvailability = (session) => {
    dispatch(setTrainerAvailabilityModalVisible(true))
    dispatch(setViewTrainerId(session?.trainerSessionsId))
    dispatch(
      setCreateBookingField({
        field: 'trainerBookingsId',
        value: session?.trainerSessionsId,
      })
    )
    dispatch(setCreateBookingField({ field: 'userBookingsId', value: userId }))
    dispatch(
      setCreateBookingField({
        field: 'bookingSessionId',
        value: session?.id,
      })
    )
    console.log('handleOpenTrainerAvailability: Session: ', session)
  }
  return (
    <Grid container sx={{ justifyContent: 'flex-start' }}>
      {isSuccess &&
        data &&
        data.map((session, index) => (
          <Grid key={session.id} item xs={size ? size : 3} sx={{ p: 1 }}>
            <SessionCard
              session={session}
              onClick={() =>
                //handleOpenTrainerAvailability(session?.trainer?.user?.userId)
                //console.log('clicked', session)
                handleOpenTrainerAvailability(session)
              }
            />
          </Grid>
        ))}
      {isLoading && <LinearProgress />}

      <TrainerAvailabilityModal />
    </Grid>
  )
}
