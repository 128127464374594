import { Grid, Paper, Switch, useTheme } from '@mui/material'
import { API, graphqlOperation } from 'aws-amplify'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../../../graphql/mutations'
import ProfileImageComponent from '../ProfileImageComponent'
import { useParams } from 'react-router-dom'
import SubTitleA from '../../text/SubTitleA'
import EditIconButtonBorder from '../../buttons/EditIconButtonBorder'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../../redux/slices/uiSlice'
import InfoText from '../../text/InfoText'
import AddInfoIconButton from './AddInfoIconButton'
import TitleB from '../../text/TitleB'
import FitnessGoal from './FitnessGoal'
import Workout from './Workout'
import SubTitleB from '../../text/SubTitleB'
import TimeDayPreference from './TimeDayPreference'

const MemberGeneralInfo = (props) => {
  const { drawer } = props
  const editMode = useSelector((state) => state.ui.editMode)
  const isTrainer = useSelector((state) => state.auth.isTrainer)
  const trainerId = useSelector((state) => state.auth.trainerId)
  const { viewTrainerId, viewMemberId } = useParams()
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const profileFormData = useSelector((state) => state.forms.userProfileModal)

  const dispatch = useDispatch()
  const theme = useTheme()
  const [inPersonChecked, setInPersonChecked] = useState(
    profileFormData?.trainingTypes.includes('in person')
  )
  const [virtualChecked, setVirtualChecked] = useState(
    profileFormData?.trainingTypes.includes('virtual')
  )

  const handleUpdateTrainingTypes = async (type) => {
    console.log('handleUpdateTrainingTypes', type)
    try {
      // dispatch user update and save profile
      var newTrainingTypes = []
      if (virtualChecked && type !== 'virtual') {
        console.log('virtualChecked', virtualChecked)
        newTrainingTypes.push('virtual')
      }

      if (inPersonChecked && type !== 'in person') {
        console.log('inPersonChecked', inPersonChecked)
        newTrainingTypes.push('in person')
      }

      var input = {
        id: cognitoUser?.sub,

        trainingTypes: newTrainingTypes,
      }
      console.log('input: ', input)
      if (input.trainingTypes.includes('in person')) {
        setInPersonChecked(true)
      } else {
        setInPersonChecked(false)
      }
      if (input.trainingTypes.includes('virtual')) {
        setVirtualChecked(true)
      } else {
        setVirtualChecked(false)
      }
      const userUpdate = await API.graphql(
        graphqlOperation(updateUser, {
          input: input,
        })
      )
      const response = userUpdate.data.updateUser
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Grid container style={{ width: '100%', justifyContent: 'space-between' }}>
      <Paper
        style={{
          minHeight: 620,
          width: '100%',
          borderRadius: 16,
          marginTop: '16px',
        }}
      >
        <Grid
          container
          style={{ padding: '32px 64px', alignItems: 'flex-start' }}
        >
          <Grid item xs={6}>
            <ProfileImageComponent editMode={editMode} />
            <Grid item>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <SubTitleA text="Location" />
                </Grid>
                <Grid item>
                  <EditIconButtonBorder
                    onClick={() => {
                      dispatch(setGeneralModalComponent('choose_location'))
                      dispatch(setGeneralModalOpen(true))
                    }}
                  />
                </Grid>
              </Grid>
              {true ? (
                <InfoText
                  text={
                    profileFormData?.homeLocation
                      ? profileFormData?.homeLocation
                      : 'City, State'
                  }
                />
              ) : (
                <AddInfoIconButton text="City, state" onClick={() => {}} />
              )}
            </Grid>
            <Grid item>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <SubTitleA text="About me" />
                </Grid>
                <Grid item>
                  <EditIconButtonBorder
                    onClick={() => {
                      dispatch(setGeneralModalComponent('about_member'))
                      dispatch(setGeneralModalOpen(true))
                    }}
                  />
                </Grid>
              </Grid>

              {true ? (
                <InfoText text={profileFormData?.about} />
              ) : (
                <AddInfoIconButton text="About me" onClick={() => {}} />
              )}
            </Grid>
            <Grid item>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <SubTitleA text="Info for the trainer" />
                </Grid>
                <Grid item>
                  <EditIconButtonBorder
                    onClick={() => {
                      dispatch(setGeneralModalComponent('about_member'))
                      dispatch(setGeneralModalOpen(true))
                    }}
                  />
                </Grid>
              </Grid>
              {true ? (
                <InfoText text={profileFormData?.infoForTrainers} />
              ) : (
                <AddInfoIconButton text="More info" onClick={() => {}} />
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item>
              <Grid
                container
                style={{ justifyContent: 'flex-start', alignItems: 'center' }}
              >
                <Grid item>
                  <TitleB text="Fitness goals" />
                </Grid>
                <Grid item>
                  <EditIconButtonBorder
                    onClick={() => {
                      dispatch(setGeneralModalComponent('fitness_goals'))
                      dispatch(setGeneralModalOpen(true))
                    }}
                  />
                </Grid>
              </Grid>
              {profileFormData?.fitnessGoals ? (
                profileFormData?.fitnessGoals.map((fitnessGoal, index) => {
                  return <FitnessGoal key={index} text={fitnessGoal} />
                })
              ) : (
                <AddInfoIconButton text="fitness goals " onClick={() => {}} />
              )}
            </Grid>
            <Grid item>
              <TitleB text="Training preferences" />
            </Grid>
            <Grid item>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  {editMode ? (
                    <SubTitleA text="1. Choose your Workouts" />
                  ) : (
                    <SubTitleA text="1. Workouts" />
                  )}
                </Grid>
                <Grid item>
                  <EditIconButtonBorder
                    onClick={() => {
                      dispatch(setGeneralModalComponent('choose_workouts'))
                      dispatch(setGeneralModalOpen(true))
                    }}
                  />
                </Grid>
              </Grid>
              {profileFormData?.workoutPreferences ? (
                profileFormData?.workoutPreferences.map((workout, index) => {
                  return <Workout key={index} text={workout} />
                })
              ) : (
                <AddInfoIconButton text="Session type" onClick={() => {}} />
              )}
            </Grid>
            <Grid item>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  {editMode ? (
                    <SubTitleA text="2. How do you want your Training?" />
                  ) : (
                    <SubTitleA text="2. Training type" />
                  )}
                </Grid>
                <Grid item>
                  <EditIconButtonBorder onClick={() => {}} />
                </Grid>
              </Grid>

              {editMode ? (
                <>
                  <Grid container style={{ justifyContent: 'flex-start' }}>
                    <Grid item>
                      <Switch
                        checked={inPersonChecked}
                        onChange={() => handleUpdateTrainingTypes('in person')}
                      />
                      <SubTitleB text="In person - one on one" />
                    </Grid>
                  </Grid>
                  <Grid container style={{ justifyContent: 'flex-start' }}>
                    <Grid item>
                      <Switch
                        checked={virtualChecked}
                        onChange={() => handleUpdateTrainingTypes('virtual')}
                      />
                      <SubTitleB text="Virtual - one on one" />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <ul style={{ margin: 0 }}>
                  {profileFormData?.trainingTypes?.includes('virtual') && (
                    <li>
                      <SubTitleB text="VIRTUAL" />
                    </li>
                  )}
                  {profileFormData?.trainingTypes?.includes('in person') && (
                    <li>
                      <SubTitleB text="IN-PERSON" />
                    </li>
                  )}
                </ul>
              )}
            </Grid>
            <TimeDayPreference />
          </Grid>
          <Grid container style={{ justifyContent: 'flex-end' }}>
            <Grid item style={{ padding: '0 16px 0 0' }}>
              <SubTitleB text="Total Sessions: 2" />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default MemberGeneralInfo
