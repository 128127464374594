import { useState } from 'react'
import { useGetTrainerByIdQuery } from '../../../redux/api/api'
import {
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import TitleB from '../../text/TitleB'
import ProfileImageComponent from '../ProfileImageComponent'
import { useDispatch, useSelector } from 'react-redux'
import SubTitleA from '../../text/SubTitleA'
import RoundTextField from './RoundTextField'
import InfoText from '../../text/InfoText'
import { ReactComponent as DownArrow } from '../../../assets/icons/down_arrow.svg'
import UploadIconButtonBorder from './UploadIconButtonBorder'
import SubTitleB from '../../text/SubTitleB'
import { ReactComponent as File } from '../../../assets/icons/file.svg'
import SolidColorButton from '../../buttons/SolidColorButton'
import { TabContext, TabPanel } from '@mui/lab'
import { ReactComponent as Heart } from '../../../assets/icons/likedtrainers.svg'
import EditIconButtonBorder from '../../buttons/EditIconButtonBorder'
import {
  setEditMode,
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../../redux/slices/uiSlice'
import TrainerGeneralInfo from './TrainerGeneralInfo'
import TrainerOtherInfo from './TrainerOtherInfo'
import { useParams } from 'react-router-dom'

const TrainerProfile = (props) => {
  const dispatch = useDispatch()
  const editMode = useSelector((state) => state.ui.editMode)
  const isTrainer = useSelector((state) => state.auth.isTrainer)
  const trainerId = useSelector((state) => state.auth.cognitoUser.sub)
  const firstName = useSelector((state) => state.auth.cognitoUser.given_name)
  const lastName = useSelector((state) => state.auth.cognitoUser.family_name)

  const { viewTrainerId, viewMemberId } = useParams()
  const id = isTrainer ? trainerId : viewTrainerId
  const { data, isLoading, isSuccess, isError, error } =
    useGetTrainerByIdQuery(id)
  const [tabIndex, setTabIndex] = useState(0)

  const TrainerEditMode = (props) => {
    const TrainerEditTabs = (props) => {
      return (
        <>
          {' '}
          <Tabs
            value={tabIndex}
            sx={{
              width: '100%',
              margin: '10px',
              borderTop: '1px solid black',
              borderColor: 'divider',
            }}
            TabIndicatorProps={{
              sx: {
                top: 0,
              },
            }}
          >
            <Tab
              label="MAIN PROFILE"
              index={0}
              onClick={() => {
                setTabIndex(0)
              }}
            />
            <Tab
              label="Trainer Verification"
              index={1}
              onClick={() => {
                setTabIndex(1)
              }}
            />
          </Tabs>
        </>
      )
    }
    const TrainerMainProfileEdit = (props) => {
      return (
        <>
          <TitleB text="Profile Gallery" />
          <Paper
            style={{
              //minHeight: 620,
              width: '100%',
              borderRadius: 16,
              marginTop: '16px',
            }}
          >
            <Grid container style={{ justifyContent: 'flex-start' }}>
              <Grid item style={{ padding: '32px' }}>
                <ProfileImageComponent editMode={editMode} />
              </Grid>
            </Grid>
          </Paper>
          <TitleB text="About Me" />
          <Paper
            style={{
              //minHeight: 620,
              width: '100%',
              borderRadius: 16,
              marginTop: '16px',
            }}
          >
            <Grid
              container
              sx={{ justifyContent: 'flex-start', p: '32px' }}
              spacing={2}
            >
              <Grid item xs={12}>
                <Grid container style={{ justifyContent: 'flex-start' }}>
                  <Grid xs={3}>
                    <SubTitleA inline text="Years as a trainer:"></SubTitleA>{' '}
                  </Grid>
                  <Grid>
                    <RoundTextField width="500px" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container style={{ justifyContent: 'flex-start' }}>
                  <Grid xs={3}>
                    <SubTitleA
                      inline
                      text="My favorite aspect of training is: "
                    ></SubTitleA>
                  </Grid>
                  <RoundTextField width="500px" />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container style={{ justifyContent: 'flex-start' }}>
                  <Grid xs={3}>
                    <SubTitleA
                      inline
                      text="Key takeaway for my clients:"
                    ></SubTitleA>
                  </Grid>
                  <RoundTextField width="500px" />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container style={{ justifyContent: 'flex-start' }}>
                  <Grid xs={3}>
                    <SubTitleA
                      inline
                      text="I call my tribe or trainees: "
                    ></SubTitleA>
                  </Grid>
                  <RoundTextField width="500px" />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Grid container style={{ justifyContent: 'flex-start' }}>
                    <Grid xs={3}>
                      <SubTitleA
                        inline
                        text="My certifications include: "
                      ></SubTitleA>
                    </Grid>
                    <RoundTextField width="500px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <TitleB text="About Me... Some More" />
          <Paper
            style={{
              //minHeight: 620,
              width: '100%',
              borderRadius: 16,
              marginTop: '16px',
            }}
          >
            <Grid
              container
              style={{ justifyContent: 'flex-start', padding: '32px' }}
            >
              <SubTitleA text="What music are you listening to?" />
              <Grid container sx={{ justifyContent: 'flex-start', pb: '16px' }}>
                {' '}
                <InfoText text="Song/album/artist name" />
              </Grid>
              <RoundTextField
                width={'700px'}
                placeholder="I am listening to..."
              />
            </Grid>
          </Paper>
        </>
      )
    }
    const TrainerVerificationEdit = (props) => {
      const [certType, setCertType] = useState(null)
      const [trainerCert, setTrainerCert] = useState(null)
      const [otherCert, setOtherCert] = useState(null)
      const [cprCert, setCprCert] = useState(null)
      const [insurance, setInsurance] = useState(null)
      return (
        <>
          <input
            id="certificate-A"
            type="file"
            style={{ display: 'none' }}
            accept=".gif,.jpg,.jpeg,.png,.pdf"
            onChange={(e) => {
              setTrainerCert(URL.createObjectURL(e.target.files[0]))
            }}
          />
          <input
            id="certificate-Other"
            type="file"
            style={{ display: 'none' }}
            accept=".gif,.jpg,.jpeg,.png,.pdf"
            onChange={(e) => {
              setOtherCert(URL.createObjectURL(e.target.files[0]))
            }}
          />
          <input
            id="certificate-CPR"
            type="file"
            style={{ display: 'none' }}
            accept=".gif,.jpg,.jpeg,.png,.pdf"
            onChange={(e) => {
              setCprCert(URL.createObjectURL(e.target.files[0]))
            }}
          />
          <input
            id="insurance"
            type="file"
            style={{ display: 'none' }}
            accept=".gif,.jpg,.jpeg,.png,.pdf"
            onChange={(e) => {
              setInsurance(URL.createObjectURL(e.target.files[0]))
            }}
          />
          <TitleB text="Certifications and Specialties" />
          <Paper
            style={{
              //minHeight: 620,
              width: '100%',
              borderRadius: 16,
              marginTop: '16px',
            }}
          >
            <Grid
              container
              style={{ justifyContent: 'flex-start', padding: '32px' }}
            >
              <InfoText
                width="800px"
                text="Find your certification, then upload your credentials, and fill in the expiration date. If you do not see your association or website, fill in the “Other” field and upload your documentation. Be sure to fill in the expiration date with 00/0000 if you do not have one."
              />

              <Grid
                container
                style={{ justifyContent: 'flex-start' }}
                spacing={3}
              >
                <Grid item xs={12}>
                  <SubTitleA text="Trainer Certification" />
                  <Select
                    onChange={(e) => {
                      setCertType(e.target.value)
                    }}
                    style={{
                      backgroundColor: 'gunMetal.main',
                      color: 'white',
                      width: '300px',
                      borderRadius: '40px',
                      fontWeight: 700,
                    }}
                    IconComponent={() => {
                      return <DownArrow style={{ paddingRight: '20px' }} />
                    }}
                    defaultValue="none"
                  >
                    <MenuItem value="none" disabled>
                      Workout type
                    </MenuItem>
                    <MenuItem value={'running'}>Running</MenuItem>
                    <MenuItem value={'walking'}>Walking</MenuItem>
                    <MenuItem value={'hiking'}>Hiking</MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <UploadIconButtonBorder
                    disabled={!certType}
                    text="trainer CERTIFICATION"
                    onClick={() => {
                      document.getElementById('certificate-A').click()
                    }}
                  />
                </Grid>
                <Grid item>
                  <SubTitleB text="Expiration Date" />
                </Grid>
                <Grid item>
                  <TextField variant="standard" placeholder="MM/YYYY" />
                </Grid>
              </Grid>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                {trainerCert && (
                  <Grid item>
                    <img src={trainerCert} style={{ width: '60px' }} />
                  </Grid>
                )}
              </Grid>
              <SubTitleA text="Other Documentation or Association" />
              <Grid
                container
                style={{ justifyContent: 'flex-start' }}
                spacing={3}
              >
                <Grid item xs={12}>
                  <InfoText
                    width="700px"
                    text="(If no known association, add additional document describing your experience and expertise. Sign and date your documentation.)"
                  />
                </Grid>
                <Grid item>
                  <UploadIconButtonBorder
                    text="Other CERTIFICATION"
                    onClick={() => {
                      document.getElementById('certificate-Other').click()
                    }}
                  />
                </Grid>
                <Grid item>
                  <SubTitleB text="Expiration Date" />
                </Grid>
                <Grid item>
                  <TextField variant="standard" placeholder="MM/YYYY" />
                </Grid>
              </Grid>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                {otherCert && (
                  <Grid item>
                    <img src={otherCert} style={{ width: '60px' }} />
                  </Grid>
                )}
              </Grid>
              <SubTitleA text="CPR Certification" />
              <Grid
                container
                style={{ justifyContent: 'flex-start' }}
                spacing={3}
              >
                <Grid item xs={12}>
                  <Select
                    onChange={() => {}}
                    style={{
                      backgroundColor: 'gunMetal.main',
                      color: 'white',
                      width: '300px',
                      borderRadius: '40px',
                      fontWeight: 700,
                    }}
                    IconComponent={() => {
                      return <DownArrow style={{ paddingRight: '20px' }} />
                    }}
                    defaultValue="none"
                  >
                    <MenuItem value="none" disabled>
                      CPR Certification
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <UploadIconButtonBorder
                    text="CPR Certification"
                    onClick={() => {
                      document.getElementById('certificate-CPR').click()
                    }}
                  />
                </Grid>
                <Grid item>
                  <SubTitleB text="Expiration Date" />
                </Grid>
                <Grid item>
                  <TextField variant="standard" placeholder="MM/YYYY" />
                </Grid>
              </Grid>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                {cprCert && (
                  <Grid item>
                    <img src={cprCert} style={{ width: '60px' }} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
          <TitleB text="Insurance" />
          <Paper
            style={{
              //minHeight: 620,
              width: '100%',
              borderRadius: 16,
              marginTop: '16px',
            }}
          >
            <Grid
              container
              style={{ justifyContent: 'flex-start', padding: '32px' }}
            >
              <SubTitleA text="Liablity Insurance" />
              <Grid
                container
                style={{ justifyContent: 'flex-start' }}
                spacing={3}
              >
                <Grid item>
                  <UploadIconButtonBorder
                    text="Liablity Insurance"
                    onClick={() => {
                      document.getElementById('insurance').click()
                    }}
                  />
                </Grid>
                <Grid item>
                  <SubTitleB text="Expiration Date" />
                </Grid>
                <Grid item>
                  <TextField variant="standard" placeholder="MM/YYYY" />
                </Grid>
              </Grid>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                {insurance && (
                  <Grid item>
                    <img src={insurance} style={{ width: '60px' }} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
          <TitleB text="Rules and Ethics Agreements" />
          <Paper
            style={{
              //minHeight: 620,
              width: '100%',
              borderRadius: 16,
              marginTop: '16px',
            }}
          >
            <Grid
              container
              style={{ justifyContent: 'flex-start', padding: '32px' }}
            >
              <Grid item xs={12}>
                <SubTitleA text="Rules" />
              </Grid>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <Checkbox />
                </Grid>
                <Grid item>
                  <InfoText
                    width="900px"
                    text="You agree to respect our Members’ privacy and treat them with the utmost respect and care. We expect you to act courteously and professionally with all Members (clients and trainers) of the GET TRAIND family."
                  />
                </Grid>
              </Grid>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <Checkbox />
                </Grid>
                <Grid item>
                  <InfoText text="You must never book or charge a GET TRAIND Member outside the GET TRAIND website or app." />
                </Grid>
              </Grid>{' '}
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <Checkbox />
                </Grid>
                <Grid item>
                  <InfoText text="You agree to familiarize yourself with all Member needs and preferences before each session." />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <SubTitleA text="Equipment" />
              </Grid>{' '}
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <Checkbox />
                </Grid>
                <Grid item>
                  <InfoText text="You agree to bring equipment that is required for your session." />
                </Grid>
              </Grid>{' '}
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <Checkbox />
                </Grid>
                <Grid item>
                  <InfoText text="You agree to bring a bluetooth speaker when meeting in person." />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <SubTitleA text="Documents to Sign" />
              </Grid>{' '}
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <Checkbox />
                </Grid>
                <Grid item>
                  <IconButton>
                    <File onClick={() => {}} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <InfoText text="Non-compete agreement" />
                </Grid>
              </Grid>{' '}
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <Checkbox />
                </Grid>
                <Grid item>
                  <IconButton>
                    <File onClick={() => {}} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <InfoText text="Acceptance of rules" />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid container sx={{ padding: '64px' }}>
            <InfoText
              width="600px"
              text="Once you Go Live, your profile needs to be approved by a GET TRAIND Admin after your identity and background search have been verified."
            />
          </Grid>
          <Grid container>
            <SolidColorButton text="GO LIVE!" />
          </Grid>
        </>
      )
    }
    return (
      <>
        <Grid container style={{ width: '100%', justifyContent: 'flex-start' }}>
          <Grid item xs={12}>
            <TrainerEditTabs />
          </Grid>
          <TabContext value={tabIndex}>
            <TabPanel value={0} index={0} sx={{ width: '100%', p: 0, m: 0 }}>
              <TrainerMainProfileEdit />
            </TabPanel>
            <TabPanel value={1} index={1} sx={{ width: '100%', p: 0, m: 0 }}>
              <TrainerVerificationEdit />
            </TabPanel>
          </TabContext>
        </Grid>
      </>
    )
  }
  if (false) {
    return <LinearProgress />
  }
  if (isSuccess) {
    // const trainerName = `${data?.user.firstName} ${data?.user.lastName}`
    // const trainerPronouns = data?.user.pronouns
    const trainerName = `${firstName} ${lastName}`
    const trainerPronouns = data?.pronouns
    return (
      <Grid container sx={{ p: '32px', width: '100%' }}>
        <Grid
          container
          style={{ width: '100%', justifyContent: 'space-between' }}
        >
          {!isTrainer && (
            <Grid container style={{ justifyContent: 'flex-end' }}>
              <SubTitleA
                inline
                text={`Add ${trainerName} to trainer favourites? `}
              ></SubTitleA>
              <IconButton>
                <Heart width="16px" />
              </IconButton>
            </Grid>
          )}
          {tabIndex == 0 ? (
            <Grid item>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <Typography
                    display="inline"
                    sx={{
                      fontWeight: 700,
                      fontSize: '34px',
                      lineHeight: '25.5px',
                    }}
                  >
                    {trainerName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    display="inline"
                    sx={{
                      color: 'primary.main',
                      fontWeight: 700,
                      fontSize: '17px',
                      lineHeight: '25.5px',
                      pl: '16px',
                    }}
                  >
                    {data?.pronouns}
                  </Typography>
                </Grid>
                <Grid item>
                  <EditIconButtonBorder
                    onClick={() => {
                      dispatch(setGeneralModalComponent('introduce_member'))
                      dispatch(setGeneralModalOpen(true))
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Grid item>
                  <Typography
                    display="inline"
                    sx={{
                      fontWeight: 700,
                      fontSize: '34px',
                      lineHeight: '25.5px',
                    }}
                  >
                    Trainer Verification
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isTrainer && (
            <Grid item>
              <Typography display="inline" sx={{}}>
                Edit Mode
              </Typography>
              <Switch
                checked={!editMode}
                onChange={() => {
                  dispatch(setEditMode())
                }}
              />
              <Typography display="inline" sx={{ color: 'primary.main' }}>
                Public View
              </Typography>
            </Grid>
          )}
        </Grid>
        {editMode ? (
          <>
            <TrainerEditMode />
          </>
        ) : (
          <>
            <TrainerGeneralInfo data={data} />
            <TrainerOtherInfo data={data} />
          </>
        )}
      </Grid>
    )
  }
}

export default TrainerProfile
