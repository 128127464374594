import * as React from 'react'
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import { MonthPicker } from '@mui/x-date-pickers/MonthPicker'
import { YearPicker } from '@mui/x-date-pickers/YearPicker'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { Box, Button, IconButton, Popover, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  Circle,
  CircleOutlined,
  Edit,
  ExpandMore,
  FiberManualRecord,
  HideSource,
} from '@mui/icons-material'
import PreSessionBookingModal from './PreSessionBookingModal'
import PostSessionReviewModal from './PostSessionReviewModal'
import { useSelector } from 'react-redux'
import EditIconButtonBorder from '../buttons/EditIconButtonBorder'
import { useRef } from 'react'
import { useGetBookingsQuery } from '../../redux/api/api'
import { PickersDay } from '@mui/x-date-pickers'

const minDate = dayjs(new Date())
const maxDate = dayjs('2034-01-01T00:00:00.000')

export default function CalendarFull() {
  const isTrainer = useSelector((state) => state.auth?.isTrainer)
  const userId = useSelector((state) => state.auth?.cognitoUser?.sub)
  const bookingsData = useGetBookingsQuery(userId, { skip: !userId })
  const pendingWorkouts =
    bookingsData?.data?.filter((booking) => {
      return booking.status === 'pending'
    }) || []
  const pendingWorkoutDates = pendingWorkouts.map((workout) => {
    return dayjs(workout.startTime).format('YYYY-MM-DD')
  })
  const confirmedWorkouts =
    bookingsData?.data?.filter((booking) => {
      return booking.status === 'confirmed'
    }) || []
  const confirmedWorkoutDates = confirmedWorkouts.map((workout) => {
    return dayjs(workout.startTime).format('YYYY-MM-DD')
  })
  const rescheduledWorkouts =
    bookingsData?.data?.filter((booking) => {
      return booking.status === 'reschedule'
    }) || []
  const rescheduledWorkoutDates = rescheduledWorkouts.map((workout) => {
    return dayjs(workout.startTime).format('YYYY-MM-DD')
  })
  const StyledDatePicker = styled(StaticDatePicker)({
    '& .css-epd502': {
      width: '100%',
    },
    '& .MuiDayPicker-header': {
      justifyContent: 'space-between',
    },
    '& .MuiCalendarOrClockPicker-root': {
      width: '100%',
    },
    '& .MuiCalendarPicker-root ': {
      width: '500px',
    },
    '& .MuiPickersCalendarHeader-root ': {
      display: 'none',
    },
    '& .MuiDayPicker-weekContainer': {
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    '& .MuiPickersDay-root ': {
      fontWeight: 'bold',
    },
    '& .MuiPickersDay-today ': {
      textEmphasisStyle: 'circle',
      textEmphasisPosition: 'under',
      border: 'none',
      fontWeight: 'bold',
    },

    '& .MuiDayPicker-weekDayLabel ': {
      fontWeight: 'bold',
    },
  })

  const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
      prop !== 'today' &&
      prop !== 'reschedule' &&
      prop !== 'confirmed' &&
      prop !== 'unavailable' &&
      prop !== 'pending',
  })(({ theme, today, reschedule, confirmed, unavailable, pending }) => ({
    ...(today && {
      textEmphasisStyle: 'circle',
      textEmphasisPosition: 'under',
      border: 'none',
      fontWeight: 'bold',
    }),
    ...(reschedule && {
      borderRadius: '50%',
      backgroundColor: '#FA7E00',
    }),
    ...(confirmed && {
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
    }),
    ...(unavailable && {
      textDecoration: 'line-through',
      fontWeight: 'bold',
    }),
    ...(pending && {
      borderRadius: '50%',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
      fontWeight: 'bold',
    }),
  }))

  const [date, setDate] = React.useState(dayjs())
  const [monthOpen, setMonthOpen] = React.useState(false)
  const [yearOpen, setYearOpen] = React.useState(false)
  const [preSessionOpen, setPreSessionOpen] = React.useState(false)
  const [postSessionOpen, setPostSessionOpen] = React.useState(false)
  const [amPm, setAmPm] = React.useState('am')
  const clickMonth = (e) => {
    setMonthOpen(true)
    setMonthEl(e.currentTarget)
    console.log(date.month())
  }
  const clickYear = (e) => {
    setYearOpen(true)
    setYearEl(e.currentTarget)
    console.log(date.year())
  }
  const handleChangeMonth = (newDate) => {
    setDate(newDate)
    setMonthOpen(false)
  }
  const handleChangeYear = (newDate) => {
    setDate(newDate)
    setYearOpen(false)
  }
  const clickTimeZone = () => {}
  const handleAmClick = () => {
    setAmPm('am')
  }
  const handlePmClick = () => {
    setAmPm('pm')
  }
  const times = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
  const timeSlots = times.map((slot) => (
    <Grid item xs={3} sx={{ p: 1, m: 1 }}>
      <Button variant="contained">
        {slot}:00 {amPm}
      </Button>
    </Grid>
  ))

  const isDisabled = (date) => {
    const day = date.day()

    return day === 0 || day === 6
  }

  const handleDaySelect = (newDay) => {
    console.log('day selected: ', newDay.get('date'))
    if (newDay.get('date') === 24) {
      setPreSessionOpen(true)
    } else if (newDay.get('date') === 17) {
      setPostSessionOpen(true)
    }
    setDate(newDay)
  }

  const handleClosePreSessionBookingModal = () => {
    setPreSessionOpen(false)
  }

  const handleClosePostSessionReviewModal = () => {
    setPostSessionOpen(false)
  }
  const [monthEl, setMonthEl] = React.useState(null)
  const [yearEl, setYearEl] = React.useState(null)

  const renderBookingsDay = (date, selectedDates, pickersDayProps) => {
    if (!date) {
      return <PickersDay {...pickersDayProps} />
    }
    //dayjs().isSame('2011-01-01', 'year')
    const today = dayjs().isSame(date, 'day')
    const confirmed = confirmedWorkoutDates.includes(date.format('YYYY-MM-DD'))
    const reschedule = rescheduledWorkoutDates.includes(
      date.format('YYYY-MM-DD')
    )
    //const pending = date.isSame('2023-02-28', 'day')
    const pending = pendingWorkoutDates.includes(date.format('YYYY-MM-DD'))

    const unavailable = date.isSame('2023-02-25', 'day')
    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        today={today}
        reschedule={reschedule}
        confirmed={confirmed}
        pending={pending}
        unavailable={unavailable}
      />
    )
  }

  const calendarView = (
    <>
      <Grid container sx={{ justifyContent: 'flex-start', ml: 5 }}>
        <Grid item sx={{ mr: 3 }}>
          <Button
            variant="contained"
            sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
            onClick={(e) => {
              clickMonth(e)
            }}
          >
            {date.format('MMMM')} <ExpandMore sx={{ pl: 1 }} />
          </Button>
          <Popover
            PaperProps={{ sx: { borderRadius: '30px' } }}
            open={monthOpen}
            anchorEl={monthEl}
            sx={{ width: '250px', top: '40px' }}
          >
            <MonthPicker
              sx={{ width: '150px', height: '300px' }}
              disablePast
              date={date}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleChangeMonth}
            />
          </Popover>
        </Grid>
        <Grid item sx={{ mr: 3 }}>
          <Button
            variant="contained"
            sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
            onClick={(e) => {
              clickYear(e)
            }}
          >
            {date.format('YYYY')} <ExpandMore sx={{ pl: 1 }} />
          </Button>
          <Popover
            PaperProps={{ sx: { borderRadius: '30px' } }}
            open={yearOpen}
            anchorEl={yearEl}
            sx={{ width: '250px', top: '40px' }}
          >
            <YearPicker
              sx={{ width: '150px', height: '300px' }}
              disablePast
              date={date}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleChangeYear}
            />
          </Popover>
        </Grid>
        {isTrainer && (
          <Grid item>
            <Button variant="outlined">
              <Edit sx={{ pr: 2 }} />
              Edit Days Off
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ justifyContent: 'flex-start', m: 3, p: 3 }}>
        <StyledDatePicker
          fullWidth
          today
          shouldDisableDate={isDisabled}
          disablePast
          displayStaticWrapperAs="desktop"
          value={date}
          views={['day']}
          onChange={handleDaySelect}
          componentProps={{
            switchViewButton: { sx: { display: 'none' } },
          }}
          renderDay={renderBookingsDay}
        />
      </Grid>
    </>
  )
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        justifyContent="start"
        sx={{ bgcolor: 'white', p: 2, pl: 0, ml: 0 }}
      >
        {
          <PreSessionBookingModal
            onClose={handleClosePreSessionBookingModal}
            open={preSessionOpen}
          />
        }
        {
          <PostSessionReviewModal
            onClose={handleClosePostSessionReviewModal}
            open={postSessionOpen}
          />
        }
        <Grid item xs={12} sm={12} md={12} lg={8}>
          {calendarView}
        </Grid>
        <Grid item>
          <Grid container justifyContent={'start'} sx={{ p: 1 }}>
            <FiberManualRecord
              color="null"
              sx={{ p: 1, fontSize: '12px', pl: 2 }}
            />
            <Typography sx={{ p: 1 }}>Today</Typography>
          </Grid>
          <Grid container justifyContent={'start'} sx={{ p: 1 }}>
            <Circle color="primary" sx={{ p: 1 }} />
            <Typography> Confirmed Workout</Typography>
          </Grid>
          <Grid container justifyContent={'start'} sx={{ p: 1 }}>
            <CircleOutlined color="primary" sx={{ p: 1 }} />
            <Typography> Pending Session</Typography>
          </Grid>
          <Grid container justifyContent={'start'} sx={{ p: 1 }}>
            <Circle sx={{ color: '#FA7E00', p: 1 }} />
            <Typography> Reschedule Request</Typography>
          </Grid>
          <Grid container justifyContent={'start'} sx={{ p: 1 }}>
            <HideSource sx={{ p: 1, color: 'text.secondary' }} />
            <Typography>Unavailable</Typography>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}
