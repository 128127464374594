import * as React from 'react'
import { Box, Button, Grid, Typography, Container } from '@mui/material'
import BottomBanner from '../../assets/HeroSlider/BottomBanner.jpg'

export default function LandingFooterTrainer() {
  return (
    <Box>
      <Box
        style={{
          backgroundImage: `url(${BottomBanner})`,
          position: 'relative',
          objectFit: 'cover',
          backgroundSize: 'cover',
          width: '100%',
          minHeight: 600,
          paddingTop: 70,
          margin: 0,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            margin: 0,
            backgroundColor: 'rgba(0,0,0,.4)',
          }}
        >
          <Grid
            container
            sx={{
              textAlign: 'center',
              alignItems: 'space-between',
              height: '100%',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: 'white',
                m: 2,
                p: 4,
              }}
            >
              We know that a physically active lifestyle is the secret to
              health, happiness, success, and longevity, but finding the time
              and motivation to do it on your own is hard. <br />
              <br />
              We also know that if you had a great trainer or coach to guide
              you, you would reach and even exceed your goals.
              <br />
              <br /> Our mission is to provide a team of experts around the
              globe who can be there, live in person or live virtually, to
              inspire and motivate you into acheiving the most fulfilling and
              vibrant life imaginable.
              <br /> <br />
              You don’t have to go it alone.
            </Typography>
            <Button
              color="primary"
              variant="contained"
              sx={{
                color: 'white',
                p: 2,
                pl: 6,
                pr: 6,
                fontWeight: 'bold',
              }}
            >
              Let's do this
            </Button>
          </Grid>
        </div>
      </Box>
    </Box>
  )
}
