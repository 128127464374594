import { API, Auth, graphqlOperation } from 'aws-amplify'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthModalOpen } from '../../redux/slices/uiSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import TitleC from '../text/TitleC'
import TitleB from '../text/TitleB'
import SubTitleA from '../text/SubTitleA'
import SubTitleB from '../text/SubTitleB'
import { Refresh, Visibility, VisibilityOff } from '@mui/icons-material'
import SolidColorButton from '../buttons/SolidColorButton'
import GreyTextButton from '../buttons/GreyTextButton'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { createUser } from '../../graphql/mutations'

export default function AuthModal() {
  const [textFields, setTextFields] = useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    confirmationPassword: '',
    verificationCode: '',
  })
  const [agreed, setAgreed] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isSignup, setIsSignup] = useState(false)
  const userCognitoID = useSelector((state) => state.auth.cognitoUser?.sub)
  async function signUp() {
    const { username, password, firstName, lastName, confirmationPassword } =
      textFields
    console.debug(username, password, firstName, lastName, confirmationPassword)
    try {
      const stripeAcct = await axios.post(
        'https://fbfnzssjr6bnkmknrdcx743ls40xeqdr.lambda-url.us-east-2.on.aws/',
        {
          name: firstName + ' ' + lastName,
          email: username,
        }
      )
      const data = stripeAcct.data
      const stripe_id = data.id
      console.log('stripe_id: ', stripe_id)
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          'custom:user_type': 'member',
          'custom:stripe_customer_id': stripe_id,
          given_name: firstName,
          family_name: lastName,
          email: username, // optional
          //phone_number, // optional - E.164 number convention
          // other custom attributes
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      })
      console.log(user)
      setConfirming(true)
    } catch (error) {
      console.log('error signing up:', error)
    }
  }

  async function signIn() {
    const { username, password } = textFields
    console.debug(username, password)
    try {
      const { user } = await Auth.signIn({
        username,
        password,
      })
      console.log(user)
      handleCloseAuthModal()
    } catch (error) {
      console.log('error signing up:', error)
    }
  }

  const open = useSelector((state) => state.ui.authModalOpen)

  const dispatch = useDispatch()

  const handleCloseAuthModal = () => {
    dispatch(setAuthModalOpen(false))
  }

  const handleChangeText = (prop) => (event) => {
    setTextFields({ ...textFields, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleAuthButtonClick = async () => {
    if (isSignup) {
      console.debug('Sign up here', textFields.username)
      await signUp()
    } else {
      console.debug('Sign in here', textFields.username)
      await signIn()
    }
  }

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(textFields.username)
      console.log('code resent successfully')
    } catch (err) {
      console.log('error resending code: ', err)
    }
  }

  async function handleConfirmAccount() {
    try {
      await Auth.confirmSignUp(textFields.username, textFields.verificationCode)
      setConfirming(false)
      handleCloseAuthModal()
    } catch (error) {
      console.log('error confirming sign up', error)
    }
  }

  const signupDisabled =
    textFields.username === '' ||
    textFields.password === '' ||
    textFields.firstName === '' ||
    textFields.lastName === '' ||
    textFields.confirmationPassword === '' ||
    !agreed

  const signinDisabled =
    textFields.username === '' || textFields.password === ''

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      PaperProps={{
        sx: {
          minWidth: '50%',
          overflow: 'hidden',
          //width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '95%' }}>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            {/*  {' '}
            <IconButton
              onClick={() => {
                //lastPage()
              }}
              style={{ width: '20px', height: '20px', marginLeft: '20px' }}
            >
              <ArrowBackIcon />
            </IconButton> */}
          </Grid>

          <Grid item>
            <IconButton
              onClick={handleCloseAuthModal}
              style={{ width: '20px', height: '20px', marginRight: '20px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {confirming ? (
        <DialogContent style={{}}>
          <Grid container sx={{ p: 2 }}>
            <SubTitleA text="Please Check Your Email To Verify Your Account" />
          </Grid>
          <Grid container>
            <SubTitleB text={'Verification Code'} />
          </Grid>
          <Grid container>
            <TextField
              sx={{ textAlign: 'center' }}
              value={textFields.verificationCode}
              onChange={handleChangeText('verificationCode')}
              variant="standard"
              placeholder="######"
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
            />
          </Grid>
          <Grid container sx={{ p: 2 }}>
            <Button
              onClick={resendConfirmationCode}
              variant="text"
              color="primary"
              sx={{ textTransform: 'none' }}
            >
              Resend Confirmation
              <Refresh />
            </Button>
          </Grid>
          <Grid container sx={{ p: 2 }}>
            <SolidColorButton onClick={handleConfirmAccount} text="Submit" />
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent style={{}}>
          <Grid container sx={{ p: 2 }}>
            <TitleB
              text={isSignup ? "We're excited to have you!" : 'Welcome Back!'}
            />
          </Grid>
          <Grid container sx={{ p: 2 }}>
            <TitleB text={isSignup ? 'Sign Up' : 'Login'} />
          </Grid>
          {isSignup && (
            <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
              <SubTitleB text={'First Name'} />
              <TextField
                value={textFields.firstName}
                onChange={handleChangeText('firstName')}
                variant="standard"
                fullWidth
                placeholder="First Name"
              />
            </Grid>
          )}
          {isSignup && (
            <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
              <SubTitleB text={'Last Name'} />
              <TextField
                value={textFields.lastName}
                onChange={handleChangeText('lastName')}
                variant="standard"
                fullWidth
                placeholder="Last Name"
              />
            </Grid>
          )}
          <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
            <SubTitleB text={'Email'} />
            <TextField
              value={textFields.username}
              onChange={handleChangeText('username')}
              variant="standard"
              fullWidth
              placeholder="example@email.com"
            />
          </Grid>
          <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
            <SubTitleB text={'Password'} />
            <TextField
              type={showPassword ? 'text' : 'password'}
              value={textFields.password}
              onChange={handleChangeText('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="standard"
              fullWidth
              placeholder="Password"
            />

            {!isSignup && (
              <Typography
                sx={{ fontSize: '14px', color: 'primary.main', pt: 1 }}
              >
                Forgot Password?
              </Typography>
            )}
          </Grid>
          {isSignup && (
            <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
              <SubTitleB text={'Confirm Password'} />
              <TextField
                type={showPassword ? 'text' : 'password'}
                value={textFields.confirmationPassword}
                onChange={handleChangeText('confirmationPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                fullWidth
                placeholder="Confirm Password"
              />
            </Grid>
          )}
          {!isSignup && (
            <Grid container>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked={false} />}
                  label="Remember Me"
                />
              </FormGroup>
            </Grid>
          )}
          <Grid container sx={{ p: 1 }}>
            <SolidColorButton
              disabled={isSignup ? signupDisabled : signinDisabled}
              onClick={handleAuthButtonClick}
              text={isSignup ? 'Sign Up' : 'Login'}
            />
          </Grid>

          <Grid container sx={{ p: 1 }}>
            {isSignup && (
              <Grid container>
                <Checkbox
                  checked={agreed}
                  onChange={() => setAgreed(!agreed)}
                />
                <Typography sx={{ color: 'gunMetal.main', ml: 1 }}>
                  I agree to the following{' '}
                  <Link
                    target="_blank"
                    href="/privacy"
                    underline="none"
                    sx={{ color: 'primary.main' }}
                  >
                    Privacy Policy
                  </Link>{' '}
                  and <Link underline="none">Terms and Conditions</Link>
                </Typography>
              </Grid>
            )}
            {!isSignup && (
              <Grid container>
                <Grid item sx={{ pr: 2 }}>
                  {' '}
                  <Link
                    target="_blank"
                    href="/privacy"
                    underline="none"
                    sx={{ color: 'primary.main' }}
                  >
                    Privacy Policy
                  </Link>
                </Grid>
                <Grid item>
                  {' '}
                  <Link
                    target="_blank"
                    href="/terms"
                    underline="none"
                    sx={{ color: 'primary.main' }}
                  >
                    Terms and Conditions
                  </Link>
                </Grid>
              </Grid>
            )}
            <Typography>
              {isSignup ? 'Already have an account?' : 'No Account?'}
            </Typography>
            <Button
              onClick={() => setIsSignup(!isSignup)}
              variant="text"
              color="primary"
              sx={{ textTransform: 'none' }}
            >
              {isSignup ? 'Login' : 'Sign up'}
            </Button>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  )
}
