import { Box, Grid, Typography } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import DashboardMessagesPage from '../components/member-dashboard.js/DashboardMessagesPage'
import TrainerSessionCardsPage from '../components/member-dashboard.js/TrainerSessionCardsPage'
import { useParams } from 'react-router-dom'
import { useGetSessionDetailsByIdQuery } from '../redux/api/api'
import SessionDetailsComponent from '../components/session_details/SessionDetailsComponent'
import { useSelector } from 'react-redux'

function SessionDetailsPage() {
  return (
    <Grid container sx={{ bgcolor: '#F6F7F8' }}>
      <Header />
      <SessionDetailsComponent />
      <Footer />
    </Grid>
  )
}

export default SessionDetailsPage
