import * as React from 'react'
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  Avatar,
  Chip,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export default function ReviewCard() {
  return (
    <Grid container sx={{ p: 2 }}>
      <Box
        sx={{
          width: 500,
          bgcolor: 'primary.light',
          borderRadius: '20px',
          height: 150,
        }}
      >
        <Grid container justifyContent="space-between" sx={{ p: 2 }}>
          <Grid item>
            <Grid container>
              <Grid item>
                <Avatar sx={{ width: 60, height: 60 }} />
              </Grid>
              <Grid item sx={{ p: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '22.5px',
                    color: 'gunMetal.main',
                  }}
                >
                  Tim O
                </Typography>

                <Typography
                  sx={{
                    fontWeight: 300,
                    fontSize: '15px',
                    lineHeight: '22.5px',
                    color: 'gunMetal.main',
                  }}
                >
                  Los Angeles, CA
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Chip
                sx={{ bgcolor: 'gunMetal.main', color: 'white' }}
                label="SPORTS PERFORMANCE TRAINING"
              />
            </Grid>
            <Grid container justifyContent="flex-end">
              <Typography>$47</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '26px',
              lineHeight: '39px',
              color: 'gunMetal.main',
            }}
          >
            Feb 12th 2023, 11:00 AM PDT
          </Typography>
        </Grid>
      </Box>
    </Grid>
  )
}
