import * as React from 'react'
import Box from '@mui/material/Box'
import { Button, Switch } from '@mui/material'
import Header from '../header/Header'
import 'cropperjs/dist/cropper.css'
import { useDispatch, useSelector } from 'react-redux'
import { setEditMode } from '../../redux/slices/uiSlice'
import GeneralModal from '../modals/GeneralModal'

import Footer from '../footer/Footer'
import { useEffect } from 'react'
import { setIsTrainer } from '../../redux/slices/authSlice'
import { useParams } from 'react-router-dom'
import TrainerProfile from './ProfileComponents/TrainerProfile'
import MemberProfile from './ProfileComponents/MemberProfile'

export default function Profile(props) {
  const { drawer } = props
  const editMode = useSelector((state) => state.ui.editMode)
  const isTrainer = useSelector((state) => state.auth.isTrainer)
  const { viewTrainerId, viewMemberId } = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    if (editMode) {
      dispatch(setEditMode())
    }
  }, [isTrainer])

  return (
    <>
      {!drawer && <Header />}
      <GeneralModal />

      <Box
        style={{
          backgroundColor: 'rgba(246, 247, 248, 1)',
          minHeight: '1000px',
          width: '100%',
        }}
      >
        <GeneralModal />
        {false && (
          <>
            Member
            <Switch
              checked={isTrainer}
              onChange={() => {
                dispatch(setIsTrainer(!isTrainer))
              }}
            />
            Trainer
            <Button>Testing Here</Button>
          </>
        )}
        {(isTrainer && !viewMemberId) || viewTrainerId ? (
          <TrainerProfile />
        ) : !isTrainer || viewMemberId ? (
          <MemberProfile />
        ) : (
          <>Error</>
        )}
      </Box>
      {!drawer && <Footer />}
    </>
  )
}
