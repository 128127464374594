import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import Header from '../header/Header'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'

import 'cropperjs/dist/cropper.css'

import { ReactComponent as File } from '../../assets/icons/file.svg'
import { ReactComponent as Pencil } from '../../assets/icons/pencil.svg'
import { ReactComponent as PencilLine } from '../../assets/icons/pencil_line.svg'
import { ReactComponent as Plus } from '../../assets/icons/plus.svg'
import { ReactComponent as Trash } from '../../assets/icons/trash.svg'
import { ReactComponent as FloppyDisk } from '../../assets/icons/floppy_disk.svg'
//import { ReactComponent as Star } from '../../assets/icons/star.svg'
import { ReactComponent as Heart } from '../../assets/icons/likedtrainers.svg'
import { ReactComponent as Checkmark } from '../../assets/icons/checkmark.svg'
import { ReactComponent as UploadProfileIcon } from '../../assets/icons/upload_profile.svg'
import { ReactComponent as UploadUpIcon } from '../../assets/icons/upload_up.svg'
import { ReactComponent as DownArrow } from '../../assets/icons/down_arrow.svg'

import ProfilePic from '../../assets/profile_picture.png'
import AlbumCover from '../../assets/album_cover.svg'
import body from '../../assets/body.svg'
import styled from '@emotion/styled'

import {
  CalendarMonthOutlined,
  Info,
  LocationOnOutlined,
  Search,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import CheckCircle from '../../assets/icons/CheckCircle.svg'
import XCircle from '../../assets/icons/XCircle.svg'
import { SearchOutlined } from '@mui/icons-material'
import SessionCard from '../session_card/SessionCard'
import SolidColorButton from '../buttons/SolidColorButton'
import { Cropper } from 'react-cropper'
import { useRef } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../redux/slices/uiSlice'
import GeneralModal from '../modals/GeneralModal'
import TitleB from '../text/TitleB'
import SubTitleA from '../text/SubTitleA'
import SubTitleB from '../text/SubTitleB'
import InfoText from '../text/InfoText'
import MemberInfoA from '../onboarding_modals/MemberInfoA'
import MemberInfoB from '../onboarding_modals/MemberInfoB'
import BackgroundCheck from '../onboarding_modals/BackgroundCheck'
import EmergencyContacts from '../onboarding_modals/EmergencyContacts'
import GenderPronouns from '../onboarding_modals/GenderPronouns'
export default function MemberSettings(props) {
  const { drawer } = props
  const [favorited, setFavorited] = React.useState(false)
  const [editMode, setEditMode] = React.useState(false)
  const [tabIndex, setTabIndex] = React.useState(0)
  const [profilePicture, setProfilePicture] = React.useState(null)

  const [isTrainer, setIsTrainer] = useState(false)
  const dispatch = useDispatch()
  const theme = useTheme()
  return (
    <>
      {!drawer && <Header />}
      <Box
        style={{
          backgroundColor: 'rgba(246, 247, 248, 1)',
          padding: '32px',
          minHeight: '1000px',
        }}
      >
        <Grid container>
          <Grid
            container
            style={{ width: '100%', justifyContent: 'space-between' }}
          >
            <TitleB text="Personal Information" />
            <Paper
              style={{
                //minHeight: 620,
                width: '100%',
                borderRadius: 16,
                marginTop: '16px',
              }}
            >
              <Grid
                container
                style={{ padding: '32px 64px', alignItems: 'flex-start' }}
              >
                <Grid item xs={6}>
                  <MemberInfoA />
                </Grid>
                <Grid item xs={6}>
                  <MemberInfoB />
                </Grid>
              </Grid>
            </Paper>
            <TitleB text="Backgroud Check" />
            <Paper
              style={{
                //minHeight: 620,
                width: '100%',
                borderRadius: 16,
                marginTop: '16px',
              }}
            >
              <Grid
                container
                style={{ padding: '32px 64px', alignItems: 'flex-start' }}
              >
                <BackgroundCheck />
              </Grid>
            </Paper>
            <TitleB text="Emergency Contacts" />
            <Paper
              style={{
                //minHeight: 620,
                width: '100%',
                borderRadius: 16,
                marginTop: '16px',
              }}
            >
              <Grid
                container
                style={{
                  padding: '32px 64px',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <EmergencyContacts />
              </Grid>
            </Paper>
            <TitleB text="About Me" />
            <Paper
              style={{
                //minHeight: 620,
                width: '100%',
                borderRadius: 16,
                marginTop: '16px',
              }}
            >
              <Grid
                container
                style={{
                  padding: '32px 64px',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <GenderPronouns />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
