import { Box, Grid, Paper } from '@mui/material'
import TitleB from '../../text/TitleB'
import SubTitleA from '../../text/SubTitleA'
import AlbumCover from '../../../assets/album_cover.svg'
import TrainerSessions from './TrainerSessions'
import TrainerReviews from './TrainerReviews'

const TrainerOtherInfo = (props) => {
  const { data } = props
  return (
    <>
      <Grid
        container
        style={{ width: '100%', justifyContent: 'space-between' }}
      >
        <Grid container sx={{ p: 2 }} justifyContent="flex-start">
          <TitleB text={`More about ${data?.displayName}`} />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              //height: 180,
              width: '100%',
              borderRadius: '16px',
              p: 2,
              //padding: '32px',
            }}
          >
            <Box sx={{ p: 2, pt: 0 }}>
              <SubTitleA text="What’s on my playlist" />
              <img src={AlbumCover} />
            </Box>
          </Paper>
        </Grid>
        <Grid
          container
          sx={{ p: 2 }}
          justifyContent="flex-start"
          onClick={() => console.log('data: ', data)}
        >
          <TitleB text={`${data?.displayName}'s Training Sessions`} />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              //height: 180,
              width: '100%',
              borderRadius: '16px',
              p: 2,
              //padding: '32px',
            }}
          >
            <Box sx={{ p: 2, pt: 0, width: '100%' }}>
              <SubTitleA text={`Explore ${data?.displayName}’s Sessions`} />
              <TrainerSessions />
            </Box>
          </Paper>
        </Grid>
        <Grid container sx={{ p: 2 }} justifyContent="flex-start">
          <TitleB text={`${data?.displayName}'s Reviews`} />
        </Grid>
        <Grid item xs={12}>
          <TrainerReviews reviews={data.reviews} />
        </Grid>
      </Grid>
    </>
  )
}

export default TrainerOtherInfo
