import { Grid } from '@mui/material'

const Notes = () => {
  return (
    <Grid
      style={{
        border: '1px solid black',
        minHeight: 84,
        borderRadius: '20px',
      }}
    ></Grid>
  )
}
export default Notes
