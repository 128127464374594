import { Box, Grid } from '@mui/material'
import SubTitleA from '../../text/SubTitleA'
import EditIconButtonBorder from '../../buttons/EditIconButtonBorder'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../../redux/slices/uiSlice'
import { useDispatch, useSelector } from 'react-redux'
import SubTitleB from '../../text/SubTitleB'
import Day from './Day'
import AddInfoIconButton from './AddInfoIconButton'

const TimeDayPreference = () => {
  const dispatch = useDispatch()

  const profileFormData = useSelector((state) => state.forms.userProfileModal)

  return (
    <Grid item>
      <Grid container style={{ justifyContent: 'flex-start' }}>
        <Grid item>
          <SubTitleA text="3. Preferred days to GET TRAIND " />
        </Grid>
        <Grid item>
          <EditIconButtonBorder
            onClick={() => {
              dispatch(setGeneralModalComponent('preferred_date_time'))
              dispatch(setGeneralModalOpen(true))
            }}
          />
        </Grid>
      </Grid>
      {true ? (
        <>
          <ul style={{ margin: 0 }}>
            {profileFormData?.trainingTimePreferences.includes('mornings') && (
              <li>
                <SubTitleB text="MORNINGS" />
              </li>
            )}
            {profileFormData?.trainingTimePreferences.includes(
              'afternoons'
            ) && (
              <li>
                <SubTitleB text="AFTERNOONS" />
              </li>
            )}
            {profileFormData?.trainingTimePreferences.includes('evenings') && (
              <li>
                <SubTitleB text="EVENINGS" />
              </li>
            )}
          </ul>
          <Box style={{ paddingTop: '16px' }}>
            {profileFormData?.trainingDayPreferences.includes('mon') && (
              <Day text="Mon" />
            )}
            {profileFormData?.trainingDayPreferences.includes('tue') && (
              <Day text="Tue" />
            )}
            {profileFormData?.trainingDayPreferences.includes('wed') && (
              <Day text="Wed" />
            )}
            {profileFormData?.trainingDayPreferences.includes('thu') && (
              <Day text="Thu" />
            )}
            {profileFormData?.trainingDayPreferences.includes('fri') && (
              <Day text="Fri" />
            )}
            {profileFormData?.trainingDayPreferences.includes('sat') && (
              <Day text="Sat" />
            )}
            {profileFormData?.trainingDayPreferences.includes('sun') && (
              <Day text="Sun" />
            )}
          </Box>
        </>
      ) : (
        <AddInfoIconButton text="Days and Times" onClick={() => {}} />
      )}
    </Grid>
  )
}

export default TimeDayPreference
