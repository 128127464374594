import { Grid } from '@mui/material'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProfilePicture } from '../../redux/slices/formsSlice'
import { setGeneralModalOpen } from '../../redux/slices/uiSlice'
import TitleB from '../text/TitleB'
import { Cropper } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import SolidColorButton from '../buttons/SolidColorButton'
import { Storage } from 'aws-amplify'
import { useGetImageQuery } from '../../redux/api/api'

const PhotoCropper = (props) => {
  const { sessionMode } = props
  const dispatch = useDispatch()
  const cognitoUser = useSelector((state) => state.auth.cognitoUser.sub)
  const { refetch } = useGetImageQuery(`${cognitoUser}/Profile`)
  const profilePicture = useSelector(
    (state) => state.forms.userProfileModal.uploadedImage
  )
  const sessionPhotos = useSelector(
    (state) => state.forms.createSessionForm.sessionPhotos
  )
  const cropperRef = useRef(null)
  const [croppedBlob, setCroppedBlob] = useState(null)
  const onCrop = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    const canvas = cropper.getCroppedCanvas()
    canvas.toBlob((blob) => {
      if (blob === null) {
        console.log('Blob is null')
        return
      } else {
        //console.log('HERE DA BLOB: ', blob)
        setCroppedBlob(blob)
      }
    }, 'image/jpeg')
  }

  const handleUploadProfileImage = async () => {
    try {
      await Storage.put(`${cognitoUser}/Profile`, croppedBlob, {
        contentType: 'image/png', // contentType is optional
      })
      console.log('Successfully uploaded')
      refetch()
      console.log('REFETCHED')
    } catch (error) {
      console.log('Error uploading file: ', error)
    }

    //dispatch(setUserProfilePicture(image))
    //dispatch(setGeneralModalOpen(false))
  }
  //TODO REAL SESSION ID< TRY TO CREATE SESSION INSTEAD OF STARTING WITH 0
  const sessionId = '34g3ver4'
  const handleUploadSessionImage = async () => {
    try {
      await Storage.put(
        `${cognitoUser}/sessions/${sessionId}/${sessionPhotos.length}`,
        croppedBlob,
        {
          contentType: 'image/png', // contentType is optional
        }
      )
      console.log('Successfully uploaded')
      refetch()
      console.log('REFETCHED')
    } catch (error) {
      console.log('Error uploading file: ', error)
    }

    //dispatch(setUserProfilePicture(image))
    //dispatch(setGeneralModalOpen(false))
  }
  return (
    <>
      {' '}
      <Grid container>
        <TitleB text="Crop and Size" />
      </Grid>
      <Grid sx={{ padding: '32px' }}>
        <Cropper
          src={
            sessionMode
              ? sessionPhotos[sessionPhotos.length - 1]
              : profilePicture
          }
          crop={onCrop}
          ref={cropperRef}
          aspectRatio={1}
          style={{
            height: 300,
            width: 300,
            backgroundColor: 'white',
          }}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <SolidColorButton
            text="Cancel"
            onClick={() => {
              dispatch(setGeneralModalOpen(false))
            }}
          />
        </Grid>
        <Grid item>
          <SolidColorButton
            text="Upload"
            onClick={
              sessionMode ? handleUploadSessionImage : handleUploadProfileImage
            }
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PhotoCropper
