import { Grid, Paper } from '@mui/material'
import TitleB from '../../text/TitleB'
import SearchBar from './SearchBar'
import EntryCard from './EntryCard'
import SubTitleB from '../../text/SubTitleB'
import Stat from './Stat'
import Notes from './Notes'

const WorkoutHistory = (props) => {
  const { memberName } = props
  return (
    <Paper
      style={{
        minheight: 620,
        borderRadius: 16,
      }}
    >
      <Grid
        container
        style={{
          padding: '32px 64px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Grid item>
          <TitleB text={`${memberName}'s Workout History`} />
          <SearchBar />
          <EntryCard active />
          <EntryCard />
          <EntryCard />
          <EntryCard />
        </Grid>
        <Grid item>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item>
              <TitleB text="Session Stats" />
            </Grid>
            <Grid item>
              <SubTitleB text=" Jun 12th 2021, 11:00 AM PDT" />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item>
              <Stat title="Max heart rate"></Stat>
            </Grid>
            <Grid item>
              <Stat title="Avg heart rate"></Stat>
            </Grid>
            <Grid item>
              <Stat title="Calories burned"></Stat>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <TitleB text="Trainer’s Notes" />
            </Grid>
            <Grid item xs={12}>
              <Notes />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <TitleB text="Trainer’s Suggestions" />
            </Grid>
            <Grid item xs={12}>
              <Notes />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default WorkoutHistory
