import { Box, Grid, LinearProgress } from '@mui/material'
import { useGetMemberStatsByIdQuery } from '../../redux/api/api'
import Stat from '../buttons/Stat'
import SubTitleA from '../text/SubTitleA'

export default function FitnessStats(props) {
  const { unit = 'us', editing = false } = props
  const units = {
    metric: { height: 'cm', weight: 'kg', distance: 'Km' },
    us: { height: '', weight: 'lb', distance: 'Miles' },
  }
  // const { data, isLoading, isSuccess, isError, error } =
  //   useGetMemberStatsByIdQuery('1')
  const data = {}
  function toUsHeight(n) {
    var realFeet = (n * 0.3937) / 12
    var feet = Math.floor(realFeet)
    var inches = Math.round((realFeet - feet) * 12)
    return feet + `'` + inches
  }
  function toUsWeight(n) {
    return Math.round(n * 2.2046)
  }
  function getBmi(weight, height) {
    return (weight / Math.pow(height / 100, 2)).toFixed(1)
  }
  if (true) {
    return <LinearProgress />
  }
  return (
    <Box>
      <SubTitleA text="Personal Stats" />
      <Grid container style={{ justifyContent: 'flex-start' }}>
        <Stat title="Age" value="22" />
        <Stat
          title="Height"
          value={unit == 'us' ? toUsHeight(data?.height) : data?.height}
          unit={units[unit].height}
        />
        <Stat
          title="Weight"
          value={unit == 'us' ? toUsWeight(data?.weight) : data?.weight}
          unit={units[unit].weight}
        />
        <Stat title="BMI" value={getBmi(data?.weight, data?.height)} />
        <Stat title="Fat percentage" value={data?.fatPercentage} unit="%" />
      </Grid>
      <SubTitleA text="Fitness Stats" />
      <Grid container style={{ justifyContent: 'flex-start' }}>
        <Stat title="Steps/day" value={data?.stepsPerDay} />
        <Stat title="Cals burned/day" value={data?.calPerDay} />
        <Stat title="Stairs/day" value={data?.stairsPerDay} />
        <Stat
          title={`${units[unit].distance}/week (run)`}
          value={data?.runningDistancePerWeek}
        />
        <Stat
          title={`${units[unit].distance}/week (cycle)`}
          value={data?.bikingDistancePerWeek}
        />
      </Grid>
      <SubTitleA text="Fitness Goals" />
      <Grid container style={{ justifyContent: 'flex-start' }}>
        <Stat title="Steps/day" value={data?.stepsPerDayGoal} />
        <Stat title="Cals burned/day" value={data?.calPerDayGoal} />
        <Stat title="Stairs/day" value={data?.stairsPerDayGoal} />
        <Stat
          title={`${units[unit].distance}/week (run)`}
          value={data?.runningDistancePerWeekGoal}
        />
        <Stat
          title={`${units[unit].distance}/week (cycle)`}
          value={data?.bikingDistancePerWeekGoal}
        />
      </Grid>
    </Box>
  )
}
