import * as React from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { Grid, Typography, Divider, Chip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import FilterDrawer from './FilterDrawerButton/FilterDrawer'
import BorderSelectButton from '../buttons/BorderSelectButton'
export default function FilterBar() {
  return (
    <Grid container>
      <Grid item sx={{ flexGrow: 1 }}>
        <FilterDrawer />
      </Grid>
      <Grid item sx={{ pr: 2 }}>
        <BorderSelectButton text="Sort By" />
      </Grid>

      <Grid item>
        <BorderSelectButton text="More to Explore" />
      </Grid>
    </Grid>
  )
}
