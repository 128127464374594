import * as React from 'react'
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import ReviewCard from './ReviewCard'
import PaymentMethodCard from '../payments/PaymentMethodCard'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useSelector } from 'react-redux'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_test_51MEHOJAsCikE6AYhDJypiY8Yg4fiBIUnTuQjShVM3jZqPLXCaJcBOUrV22Gb4T9LSOkWFZhOZus1ZVRtpQ4eieGo00RAkLfJUJ'
)

export default function ReviewStepper({ handleGoToPayment }) {
  const [addingPayment, setAddingPayment] = React.useState(false)
  const clientSecret = useSelector((state) => state.payments.stripeClientSecret)
  const handlePaymentClick = async () => {
    console.log('Payment Clicked')
    setAddingPayment(true)
  }

  const handleCloseModal = () => {
    setAddingPayment(false)
  }

  const handleSubmitPaymentMethod = () => {
    console.log('Submit Method')
  }
  const options = {
    // passing the client secret obtained from the server
    clientSecret,
  }

  const StyledStepper = styled(Stepper)({
    '& .MuiPickersCalendarHeader-root ': {
      display: 'none',
    },
    '& .MuiPickersDay-root ': {
      fontWeight: 'bold',
    },
    '& .MuiPickersDay-today ': {
      textEmphasisStyle: 'circle',
      textEmphasisPosition: 'under',
      border: 'none',
      fontWeight: 'bold',
    },
    '& .Mui-disabled ': {
      textDecoration: 'line-through',
      fontWeight: 'bold',
    },
    '& .MuiDayPicker-weekDayLabel ': {
      fontWeight: 'bold',
    },
  })
  // const [val, setVal] = React.useState(null)
  const [activeStep, setActiveStep] = React.useState(0)

  // const handleChangeVal = (newVal) => {
  //   setVal(newVal)
  // }
  const confirmationActionRow = (
    <Grid container sx={{ p: 2 }}>
      <Grid item sx={{ p: 2 }}>
        <Button
          onClick={() => console.log('go to calendar page here')}
          variant="outlined"
          sx={{
            color: 'gunMetal.main',
            fontWeight: 700,
            p: 2,
            pl: 3,
            pr: 3,
            fontSize: '15px',
          }}
        >
          {' '}
          Go To Calendar
        </Button>
      </Grid>
      <Grid item sx={{ p: 2 }}>
        <Button
          variant="contained"
          sx={{
            color: 'white',
            fontWeight: 700,
            p: 2,
            pl: 3,
            pr: 3,
            fontSize: '15px',
          }}
          onClick={() => console.log('go to message page here')}
        >
          {' '}
          Back to Browsing
        </Button>
      </Grid>
    </Grid>
  )

  const handleCompleteStepOne = async () => {
    try {
      await handleGoToPayment()
      setActiveStep(1)
    } catch (error) {
      console.log('error creating booking', error)
    }
  }

  const goToPaymentActionRow = (
    <Grid container sx={{ p: 2, mt: 4 }}>
      <Grid container sx={{ p: 2 }}>
        <Grid item sx={{ p: 2 }}>
          <Button
            onClick={handleCloseModal}
            variant="outlined"
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              p: 2,
              pl: 3,
              pr: 3,
              fontSize: '15px',
            }}
          >
            {' '}
            Close
          </Button>
        </Grid>
        <Grid item sx={{ p: 2 }}>
          <Button
            variant="contained"
            sx={{
              color: 'white',
              fontWeight: 700,
              p: 2,
              pl: 3,
              pr: 3,
              fontSize: '15px',
            }}
            onClick={handleCompleteStepOne}
          >
            {' '}
            Go To Payment
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
  const reviewPage = (
    <>
      <Grid container sx={{ pt: 2 }}>
        <Typography sx={{ color: 'gunMetal.main', fontSize: '15px' }}>
          You will be charged 24 hours before the session date and time. You
          have until then to modify your request
        </Typography>
      </Grid>
      <ReviewCard />
      {goToPaymentActionRow}
    </>
  )

  const confirmPage = (
    <>
      <Grid container sx={{ pt: 2 }}>
        <Typography
          sx={{ color: 'gunMetal.main', fontSize: '26px', fontWeight: 700 }}
        >
          Your workout request is complete!
        </Typography>
      </Grid>
      <Grid container sx={{ pt: 2 }}>
        <Typography
          sx={{ color: 'gunMetal.main', fontSize: '26px', fontWeight: 700 }}
        >
          GET READY TO GET TRAIND!
        </Typography>
      </Grid>
      <ReviewCard />
      {confirmationActionRow}
    </>
  )
  return (
    <>
      <Grid container>
        <Grid container sx={{ pb: 2 }}>
          <Grid item>
            <Typography
              sx={{ fontWeight: 700, fontSize: '34px', lineHeight: '51px' }}
            >
              {activeStep === 0 && 'Review'}
              {activeStep === 1 && 'Payment'}
              {activeStep === 2 && 'Confirmation'}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step key={0}>
              <StepLabel>Review</StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel>Payment</StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>Confirmation</StepLabel>
            </Step>
          </Stepper>
        </Box>
      </Grid>
      {activeStep === 0 && reviewPage}
      {activeStep === 1 && (
        <Elements stripe={stripePromise} options={options}>
          <PaymentMethodCard onSubmit={() => setActiveStep(2)} />
        </Elements>
      )}
      {activeStep === 2 && confirmPage}
    </>
  )
}
