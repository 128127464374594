import { Box, Button, Grid, SvgIcon, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setSessionRequestOpen } from '../../redux/slices/uiSlice'
import InfoText from '../text/InfoText'
import SubTitleA from '../text/SubTitleA'
import TitleC from '../text/TitleC'

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { ReactComponent as WorkoutIcon } from '../../assets/icons/workout.svg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SessionRequest from '../session_request/SessionRequest'
import theme from '../../styles/theme'
import { useGetMemberByIdQuery } from '../../redux/api/api'

const HeaderWorkoutTypeSelect = () => {
  const [typesOpen, setTypesOpen] = React.useState(false)
  const [typesSelected, setTypesSelected] = React.useState([])
  const [test, setTest] = React.useState([])
  const [formattedTrainingType, setFormattedTrainingType] = React.useState([])
  const dispatch = useDispatch()
  const [workoutTypes, setWorkoutTypes] = useState([
    { id: 0, parentTrainingId: null, trainingType: 'Adventure Trainings' },
    { id: 1, parentTrainingId: 0, trainingType: 'Hiking' },
    { id: 2, parentTrainingId: 0, trainingType: 'Rock Climbing' },
    { id: 3, parentTrainingId: 0, trainingType: 'Mountain Biking' },
    { id: 4, parentTrainingId: null, trainingType: 'Yoga and Pilates' },
    { id: 5, parentTrainingId: 4, trainingType: 'Vinyasa Yoga' },
    { id: 6, parentTrainingId: 4, trainingType: 'Power Yoga' },
    { id: 7, parentTrainingId: 4, trainingType: 'Iyengar Yoga' },
    { id: 8, parentTrainingId: 4, trainingType: 'Pilates Mat' },
    { id: 9, parentTrainingId: 4, trainingType: 'Athletic Stretching' },
    { id: 10, parentTrainingId: null, trainingType: 'Sports Coaching' },
    { id: 11, parentTrainingId: 10, trainingType: 'Running' },
    { id: 12, parentTrainingId: 10, trainingType: 'Road Cycling' },
    { id: 13, parentTrainingId: 10, trainingType: 'Marathon/Triathalon' },
    { id: 14, parentTrainingId: 10, trainingType: 'Tennis' },
    { id: 15, parentTrainingId: 10, trainingType: 'Golf' },
    { id: 16, parentTrainingId: 10, trainingType: 'Swimming' },
    { id: 17, parentTrainingId: null, trainingType: 'Strength Training' },
    { id: 18, parentTrainingId: 17, trainingType: 'Resistance Training' },
    { id: 19, parentTrainingId: 17, trainingType: 'Weight Training' },
    { id: 20, parentTrainingId: 17, trainingType: 'CrossFit' },
    { id: 21, parentTrainingId: 17, trainingType: 'Sports Performance' },
    { id: 22, parentTrainingId: 17, trainingType: 'Core Strengthening' },
    {
      id: 23,
      parentTrainingId: 17,
      trainingType: 'Calisthenics/Body Weight Training',
    },
  ])
  const [parents, setParents] = React.useState(
    workoutTypes?.filter((workoutType) => workoutType.parentTrainingId == null)
  )
  // const { data, isLoading, isSuccess, isError, error } =
  //   useGetMemberByIdQuery('1')

  // useEffect(() => {
  //   if (isSuccess) {
  //     setTypesSelected(JSON.parse(data.workoutTypes))
  //   }
  //   //
  // }, [data])
  useEffect(() => {
    let tempWorkoutTypes = workoutTypes
    typesSelected.map((type) => {
      let temp = tempWorkoutTypes.map((workout) => {
        if (type == workout.trainingType) {
          console.log(type, workout)
          return { ...workout, selected: true }
        }
        return { ...workout }
      })
      tempWorkoutTypes = temp
      setWorkoutTypes(tempWorkoutTypes)
      setTest(!test)
    })
  }, [typesSelected])
  return (
    <>
      <Grid container sx={{ justifyContent: 'flex-start' }}>
        <Grid item xs={8}>
          <Box
            fullWidth
            sx={{
              height: 314,
              border: 1,
              borderColor: 'gunMetal.main',
              borderRadius: '30px',
              padding: '10px',
            }}
          >
            <Box
              sx={{
                height: 314,
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  marginRight: '10px',
                  maxWidth: '10px',
                  maxHeight: '10px',
                },
                '&::-webkit-scrollbar-track': {
                  marginRight: '10px',
                  maxWidth: '1px',
                  hmaxHeight: '10px',
                  //'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '::-webkit-scrollbar-track-piece': {
                  maxHeight: '10px',
                  right: '30px',
                },
                '&::-webkit-scrollbar-thumb': {
                  maxHeight: '10px',
                  maxWidth: '1px',
                  borderRadius: '30px',
                  backgroundColor: 'rgba(0,0,0,.1)',
                },
              }}
            >
              {parents.map((parent) => {
                return (
                  <Box
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={() => {
                      const result = parents.map((type) => {
                        if (type.id == parent.id) {
                          return { ...type, active: true }
                        }
                        return { ...type, active: false }
                      })
                      setParents(result)
                    }}
                  >
                    <Grid
                      container
                      style={{
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item>
                        <SubTitleA
                          text={parent.trainingType}
                          onClick={() => {
                            console.log(parent.id)
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <ChevronRightIcon />
                      </Grid>
                    </Grid>

                    {parent.active &&
                      workoutTypes.map((child) => {
                        if (child.parentTrainingId == parent.id) {
                          return (
                            <Grid
                              onClick={() => {
                                let tempWorkoutType = workoutTypes.map(
                                  (type) => {
                                    if (type.id == child.id) {
                                      //if (child.selected) {
                                      if (
                                        typesSelected.includes(
                                          child.trainingType
                                        )
                                      ) {
                                        return { ...type, selected: false }
                                      } else {
                                        return { ...type, selected: true }
                                      }
                                    } else {
                                      return { ...type }
                                    }
                                  }
                                )
                                setWorkoutTypes(tempWorkoutType)
                                let temp = typesSelected
                                //if (temp[child.id]) {
                                //delete temp[child.id]
                                if (temp.includes(child.trainingType)) {
                                  temp = temp.filter(
                                    (a) => a != child.trainingType
                                  )
                                } else {
                                  temp.push(child.trainingType)
                                  //temp[child.id] = child
                                }
                                setTypesSelected(temp.filter((a) => a))
                              }}
                            >
                              <InfoText
                                color={
                                  child.selected
                                    ? theme.palette.primary.dark
                                    : 'gunMetal.main'
                                }
                                text={child.trainingType}
                              />
                            </Grid>
                          )
                        }
                      })}
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Grid>

        {typesSelected.length > 0 && (
          <Grid item xs={4}>
            {typesSelected.map((typeSelected) => {
              return (
                <Button
                  variant="contained"
                  sx={{ color: 'white', fontWeight: 500, ml: 1, mb: 1 }}
                >
                  {
                    //typeSelected.trainingType
                    typeSelected
                  }
                </Button>
              )
            })}
          </Grid>
        )}
        {typesSelected.length === 0 && (
          <Grid item xs={4} sx={{ p: 1 }}>
            <Typography> Pick some training options to search for </Typography>
          </Grid>
        )}
      </Grid>
      <SessionRequest />
      <Grid container xs={12} style={{ padding: '20px 0 20px 0' }}>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(setSessionRequestOpen())
          }}
        >
          <PlusIcon style={{ padding: '5px' }} />
          Can't find session?
        </Button>
      </Grid>
    </>
  )
}

export default HeaderWorkoutTypeSelect
