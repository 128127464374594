import * as React from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { Grid, Typography, Divider, Chip } from '@mui/material'
//import SessionCard from '../session_card/SessionCard'
//import TrainerAvailabilityModal from './TrainerAvailabilityModal'
import { useDispatch } from 'react-redux'
import { setTrainerAvailabilityModalVisible } from '../../redux/slices/uiSlice'
import TrainerCard from './TrainerCards'

export default function FavoriteTrainers(props) {
  const { size } = props
  const dispatch = useDispatch()
  const sessions = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4,
  ]
  const handleOpenTrainerAvailability = () => {
    dispatch(setTrainerAvailabilityModalVisible(true))
  }
  const trainerCards = sessions.map((number) => (
    <Grid item xs={size ? 3 : 4} sx={{ p: 1 }}>
      <TrainerCard onClick={handleOpenTrainerAvailability} />
    </Grid>
  ))
  return (
    <Grid container>
      {trainerCards}
      {/* <TrainerAvailabilityModal /> */}
    </Grid>
  )
}
