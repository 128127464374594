import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'
import { logDOM } from '@testing-library/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetMemberByIdQuery } from '../../redux/api/api'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import BorderSelectButton from '../buttons/BorderSelectButton'

export default function IntroduceMember(props) {
  const dispatch = useDispatch()
  const { onboarding } = props
  const [year, setYear] = React.useState('')

  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const formPronouns = useSelector(
    (state) => state.forms.userProfileModal.pronouns
  )
  const formGender = useSelector((state) => state.forms.userProfileModal.gender)
  const formBirthYear = useSelector(
    (state) => state.forms.userProfileModal.birthyear
  )

  const { data, isLoading, isSuccess, isError, error } = useGetMemberByIdQuery(
    cognitoUser?.sub,
    { skip: !cognitoUser?.sub }
  )
  const [localData, setLocalData] = useState()
  const handleClickPronoun = (newPronouns) => {
    dispatch(
      setUserProfileModalField({ field: 'pronouns', value: newPronouns })
    )
  }
  const handleClickGender = (newGender) => {
    dispatch(setUserProfileModalField({ field: 'gender', value: newGender }))
  }
  const handleYearChange = (event) => {
    const year = event.target.value
    setYear(year)
    dispatch(setUserProfileModalField({ field: 'birthyear', value: year }))
  }

  const yearsObj = () => {
    const d = new Date()
    let dummy = []
    for (let i = d.getFullYear() - 100; i <= d.getFullYear(); i++) {
      dummy.unshift(i)
    }
    return dummy
  }
  if (isLoading) {
    return <></>
  }
  if (data) {
    return (
      <>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
            }}
          >
            {' '}
            Identify As
          </Typography>
          <Grid
            container
            style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
            spacing={'20px'}
          >
            {['female', 'male', 'non-binary', 'other'].map((e, i) => (
              <Grid item>
                <BorderSelectButton
                  text={e}
                  active={formGender === e}
                  onClick={() => handleClickGender(e)}
                />
              </Grid>
            ))}

            <Grid item></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
            }}
          >
            Preferred pronouns
          </Typography>
          <Grid
            container
            style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
            spacing={'20px'}
          >
            {['he/him', 'she/her', 'they/them', 'other'].map((e, i) => (
              <Grid item>
                <BorderSelectButton
                  text={e}
                  active={formPronouns === e}
                  onClick={() => handleClickPronoun(e)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
            }}
          >
            Age
          </Typography>
          <Grid
            container
            style={{ paddingTop: '10px', justifyContent: 'flex-start' }}
            spacing={'20px'}
          >
            <Grid item>
              <Typography
                sx={{
                  color: 'gunMetal.main',
                  fontWeight: 400,
                  fontSize: 15,
                  lineHeight: '25.5px',
                }}
              >
                What year were your born?
              </Typography>
            </Grid>
            <Grid item>
              <FormControl>
                <Select
                  inputProps={{ 'aria-label': 'Without label' }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                  displayEmpty
                  value={formBirthYear ? formBirthYear : year}
                  style={{ width: '152px', borderRadius: '40px' }}
                  onChange={handleYearChange}
                >
                  <MenuItem value={''}>Select</MenuItem>
                  {yearsObj().map((year) => {
                    return <MenuItem value={year}>{year}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}
