import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import AdbIcon from '@mui/icons-material/Adb'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as BookingIcon } from '../../assets/icons/booking.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg'
import { ReactComponent as JoinIcon } from '../../assets/icons/join.svg'
import { ReactComponent as LikedTrainersIcon } from '../../assets/icons/likedtrainers.svg'
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg'
import { ReactComponent as MessagesIcon } from '../../assets/icons/messages.svg'
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg'
import { ReactComponent as ReviewsIcon } from '../../assets/icons/reviews.svg'
import { ReactComponent as TravelIcon } from '../../assets/icons/travel.svg'
import { ReactComponent as VirtualIcon } from '../../assets/icons/virtual.svg'
import { ReactComponent as WorkoutIcon } from '../../assets/icons/workout.svg'

import { ReactComponent as GTLogoFull } from '../../assets/GTLogoFull.svg'
import theme from '../../styles/theme'
import HeaderSearch from './HeaderSearch'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AccountCircleOutlined } from '@mui/icons-material'
import {
  setAuthModalOpen,
  setBecomeTrainerModalOpen,
} from '../../redux/slices/uiSlice'
import AuthModal from '../modals/AuthModal'
import { Auth } from 'aws-amplify'
import ErrorModal from '../modals/ErrorModal'
import BecomeTrainerModal from '../modals/BecomeTrainerModal'
import { setIsTrainer } from '../../redux/slices/authSlice'
import S3Image from '../S3Image'
import LiveModal from '../modals/LiveModal'

const pages = ['Products', 'Pricing', 'Blog']

const gunMetal = '#2B303E'

const trainerNav = [
  {
    name: 'Dashboard',
    link: '/dashboard',
  },
  {
    name: 'Bookings',
    link: '/bookings',
  },
  {
    name: 'Session Cards',

    link: '/session-cards',
  },
  {
    name: 'Messages',

    link: '/messages',
  },
  {
    name: 'Profile',
    link: '/profile',
  },
  {
    name: 'Calendar',
    link: '/calendar',
  },
  {
    name: 'Performance',
    link: '/performance',
  },
]

function Header() {
  const navigate = useNavigate()
  const memberSignedIn = useSelector((state) => state.auth.memberSignedIn)
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const isTrainer = useSelector((state) => state.auth.isTrainer)
  const hasTrainerProfile = useSelector((state) => state.auth.hasTrainerProfile)

  const dispatch = useDispatch()
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenAuthModal = () => {
    dispatch(setAuthModalOpen(true))
  }

  const handleSignOut = async () => {
    console.debug('trying to sign out')
    try {
      await Auth.signOut()
      console.debug('success sign out')
      navigate('/')
      handleCloseUserMenu()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  const settings = [
    { name: 'Profile', onClick: () => navigate('/profile') },
    { name: 'Account', onClick: () => console.debug('/account') },
    { name: 'Dashboard', onClick: () => console.debug('/dashboard') },
    { name: 'Logout', onClick: () => handleSignOut() },
  ]
  const currPage = useLocation().pathname
  const nav = [
    {
      name: 'Dashboard',
      icon: () => {
        return (
          <DashboardIcon
            stroke={currPage === '/Dashboard' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Dashboard',
    },
    {
      name: 'Workouts',
      icon: () => {
        return (
          <WorkoutIcon
            stroke={currPage === '/Workouts' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Workouts',
    },
    {
      name: 'Messages',
      icon: () => {
        return (
          <MessagesIcon
            stroke={currPage === '/Messages' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Messages',
    },
    {
      name: 'Calendar',
      icon: () => {
        return (
          <CalendarIcon
            stroke={currPage === '/Calendar' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Calendar',
    },
    {
      name: 'Profile',
      icon: () => {
        return (
          <ProfileIcon
            stroke={currPage === '/Profile' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Profile',
    },
    {
      name: 'Liked',
      icon: () => {
        return (
          <LikedTrainersIcon
            stroke={currPage === '/Liked' ? '#00C7C7' : gunMetal}
            style={{ width: '22px' }}
          />
        )
      },
      link: '/Liked',
    },
  ]
  const menuItem = (page) => {
    return (
      <MenuItem key={page.name} onClick={() => navigate(page.link)}>
        <SvgIcon
          inheritViewBox
          component={page.icon}
          sx={{ mr: 1, fontSize: 24, stroke: 'white' }}
        />
        <Typography textAlign="center">{page.name}</Typography>
      </MenuItem>
    )
  }

  return (
    <AppBar
      position="sticky"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <ErrorModal />
      <AuthModal />
      <BecomeTrainerModal />
      <LiveModal />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <SvgIcon
            onClick={() => navigate('/')}
            inheritViewBox
            component={GTLogoFull}
            sx={{ mr: 1, height: 75, fontSize: 136, cursor: 'pointer' }}
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {!isTrainer &&
                nav.map((page) => <MenuItem key={page.name} page={page} />)}
              {isTrainer &&
                trainerNav.map((page) => (
                  <MenuItem key={page.name} onClick={() => navigate(page.link)}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {!isTrainer &&
              nav.map((page) => (
                <Tooltip key={page.name} title={page.name}>
                  <IconButton
                    key={page.name}
                    onClick={() => navigate(page.link)}
                    sx={{ color: 'white', display: 'flex' }}
                  >
                    <SvgIcon
                      inheritViewBox
                      component={page.icon}
                      sx={{ fontSize: 18 }}
                    />
                  </IconButton>
                </Tooltip>
              ))}
            {isTrainer &&
              trainerNav.map((page) => (
                <Tooltip key={page.name} title={page.name}>
                  <Button
                    key={page.name}
                    onClick={() => navigate(page.link)}
                    sx={{
                      color:
                        currPage.toLowerCase() == page.link.toLowerCase()
                          ? 'primary.main'
                          : 'gunMetal.main',
                      display: 'flex',
                      textTransform: 'none',
                    }}
                  >
                    <Typography>{page.name}</Typography>
                  </Button>
                </Tooltip>
              ))}
            {!isTrainer && <HeaderSearch />}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {memberSignedIn && (
              <>
                {!isTrainer && !hasTrainerProfile && (
                  <Tooltip title="Become a Trainer">
                    <Button
                      onClick={() => dispatch(setBecomeTrainerModalOpen(true))}
                      sx={{
                        p: 0,
                        mr: 2,
                        textTransform: 'none',
                        color: 'gunMetal.main',
                      }}
                    >
                      <Typography> Become A Trainer </Typography>
                    </Button>
                  </Tooltip>
                )}
                {!isTrainer && hasTrainerProfile && (
                  <Tooltip title="Switch to Trainer">
                    <Button
                      onClick={() => dispatch(setIsTrainer(true))}
                      sx={{
                        p: 0,
                        mr: 2,
                        textTransform: 'none',
                        color: 'gunMetal.main',
                      }}
                    >
                      <Typography> Switch to Trainer </Typography>
                    </Button>
                  </Tooltip>
                )}
                {isTrainer && (
                  <Tooltip title="Switch to member">
                    <Button
                      onClick={() => dispatch(setIsTrainer(false))}
                      sx={{
                        p: 0,
                        mr: 2,
                        textTransform: 'none',
                        color: 'gunMetal.main',
                      }}
                    >
                      <Typography> Switch to member </Typography>
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Box
                      sx={{
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%',
                        border: 4,
                        borderColor: isTrainer
                          ? 'gunMetal.main'
                          : 'primary.main',
                      }}
                    >
                      <S3Image
                        dimensions={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                        }}
                        imagePath={`${cognitoUser.sub}/Profile`}
                      />
                    </Box>

                    {/* <Typography>{cognitoUser.sub}</Typography> */}
                    {/* <Avatar
                      alt={
                        cognitoUser.given_name + ' ' + cognitoUser.family_name
                      }
                      src="/static/images/avatar/2.jpg"
                      sx={{
                        border: 4,
                        borderColor: isTrainer
                          ? 'gunMetal.main'
                          : 'primary.main',
                      }}
                    /> */}
                  </IconButton>
                </Tooltip>
              </>
            )}
            {!memberSignedIn && (
              <Tooltip title="Sign In">
                <IconButton onClick={handleOpenAuthModal}>
                  <AccountCircleOutlined />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.name}
                  onClick={
                    setting.onClick ? setting.onClick : handleCloseUserMenu
                  }
                >
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
