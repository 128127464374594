import { TextField } from "@mui/material"

export default function FilterTextField(props) {
  const { placeholder,fullWidth, onChange } = props
  return (
    <TextField
      onChange={onChange}
      fullWidth={fullWidth}
      placeholder={placeholder}
      size="small"
      inputProps={{
        style: {
          textAlign: 'center',
          fontWeight: 500,
          fontSize: 13,
          color: 'gunMetal.main',
        },
      }}
      sx={{
        input: {
          '&::placeholder': {
            opacity: 1,
            color: 'gunMetal.main',
          },
        },
        '& placeholder': {
          color: 'gunMetal.main',
        },
        '& fieldset': {
          borderRadius: '30px',
          borderColor: 'gunMetal.main',
        },
      }}
    />
  )
}
