import { Button, Grid, Input, Typography } from '@mui/material'
import InfoText from '../text/InfoText'
import SubTitleA from '../text/SubTitleA'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import TitleC from '../text/TitleC'
import { useDispatch } from 'react-redux'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
export default function MemberInfoB(props) {
  const dispatch = useDispatch()
  const handleChangeField = (value, field) => {
    dispatch(setUserProfileModalField({ value, field }))
  }
  return (
    <>
      {' '}
      <Grid
        container
        style={{
          width: '50%',
          justifyContent: 'space-around',
          rowGap: '16px',
        }}
      >
        <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="Street address" />
          <Input
            fullWidth
            placeholder="street address"
            onChange={(e) => handleChangeField(e.target.value, 'streetAddress')}
          ></Input>
        </Grid>
        <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="Unit number" />
          <Input
            fullWidth
            placeholder="unit number"
            onChange={(e) => handleChangeField(e.target.value, 'unitNumber')}
          ></Input>
        </Grid>
        <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="City" />
          <Input
            fullWidth
            placeholder="city"
            onChange={(e) => handleChangeField(e.target.value, 'city')}
          ></Input>
        </Grid>
        <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="State" />
          <Input
            fullWidth
            placeholder="state"
            onChange={(e) => handleChangeField(e.target.value, 'state')}
          ></Input>
        </Grid>
        <Grid item xs={12} style={{ justifyContent: 'flex-start' }}>
          <SubTitleA text="Zip code" />
          <Input
            fullWidth
            placeholder="zip code"
            onChange={(e) => handleChangeField(e.target.value, 'zipCode')}
          ></Input>
        </Grid>
      </Grid>
    </>
  )
}
