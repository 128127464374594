import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Adventure from '../../assets/Landing/AdventureTraining.png'
import Sport from '../../assets/Landing/SportCoaching.png'
import Strength from '../../assets/Landing/StrengthTraining.png'
import Yoga from '../../assets/Landing/Yoga.png'
import { CardActionArea, Chip, Grid, IconButton } from '@mui/material'
import {
  DriveEta,
  Favorite,
  HeartBrokenTwoTone,
  LocationOn,
  LocationOnOutlined,
  Phone,
  PhoneAndroidOutlined,
  RocketLaunchOutlined,
  Sports,
  Star,
} from '@mui/icons-material'

const workouts = [
  {
    imgPath: Strength,
    title: 'Strength Training',
  },
  {
    imgPath: Yoga,
    title: 'Yoga and Pilates',
  },
  {
    imgPath: Sport,
    title: 'Sports Coaching',
  },
  {
    imgPath: Adventure,
    title: 'Adventure Training',
  },
]

export default function LandingWorkouts() {
  return (
    <Grid container style={{ justifyContent: 'flex-start' }} sx={{ pb: 3 }}>
      <Typography sx={{ ml: 5, fontWeight: 700, p: 2 }}>
        GET TRAIND Workouts
      </Typography>
      <Grid container style={{ justifyContent: 'space-around' }}>
        {workouts.map((type) => (
          <Grid
            container
            style={{
              height: '310px',
              width: '288px',

              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'flex-end',
              backgroundImage: `url(${type.imgPath})`,
            }}
          >
            <Chip
              label={type.title}
              sx={{ mb: 2 }}
              style={{
                backgroundColor: 'rgba(0,0,0,.5)',
                color: 'white',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
