import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom'
import '../index.css'
import logo from '../logo.svg'
import '../App.css'
import Components from './Components'
import ErrorPage from './Error'
import Landing from './Landing'
import SessionSearch from './SessionSearch'
import MemberProfile from '../components/member_profile/MemberProfile'
import Profile from '../components/profile/Profile'
import MemberSettings from '../components/member_settings/MemberSettings'
import MemberPage from './MemberPage'
import DashboardFavoritesPage from '../components/member-dashboard.js/DashboardFavoritesPage'
import DashboardMessagesPage from '../components/member-dashboard.js/DashboardMessagesPage'
import Messages from './Messages'
import { Favorite } from '@mui/icons-material'
import Favorites from './Favorites'
import CalendarPage from './CalendarPage'
import WorkoutsPage from './WorkoutsPage'
import SessionCardsPage from './SessionCardsPage'
import SessionDetailsPage from './SessionDetailsPage'
import DashboardTrainerBookings from '../components/member-dashboard.js/DashboardTrainerBookings'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/components',
    element: <Components />,
    errorElement: <ErrorPage />,
  },
  /* {
    path: '/landing',
    element: <Landing />,
    errorElement: <ErrorPage />,
  }, */
  {
    path: '/browse-sessions',
    element: <SessionSearch />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/profile',
    element: <Profile />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/settings',
    element: <MemberSettings />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/dashboard',
    element: <MemberPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/liked',
    element: <Favorites />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/messages',
    element: <Messages />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/calendar',
    element: <CalendarPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/workouts',
    element: <WorkoutsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/trainer/:viewTrainerId',
    element: <Profile />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/member/:viewMemberId',
    element: <Profile />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/session-cards',
    element: <SessionCardsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/session-details/:sessionId',
    element: <SessionDetailsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/bookings',
    element: (
      <>
        <Header />
        <DashboardTrainerBookings />
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
])

export default router
