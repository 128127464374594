/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      pronouns
      profilePhotoUrl
      homeLocation
      about
      infoForTrainers
      fitnessGoals
      workoutPreferences
      trainingTypes
      trainingTimePreferences
      trainingDayPreferences
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          virtual
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
        }
        nextToken
      }
      medicalConditions {
        items {
          id
          title
          description
          createdAt
          updatedAt
          userMedicalConditionsId
        }
        nextToken
      }
      stats {
        id
        age
        height
        weight
        bmi
        fatPercentage
        stepsPerDay
        calsPerDay
        stairsPerDay
        milesPerWeekRunning
        milesPerWeekCycle
        goalStepsPerDay
        goalCalsPerDay
        goalStairsPerDay
        goalMilesPerWeekRunning
        goalMilesPerWeekCycle
        createdAt
        updatedAt
      }
      focusAreas {
        items {
          id
          injury
          locationX
          locationY
          createdAt
          updatedAt
          userFocusAreasId
        }
        nextToken
      }
      favoriteSessions {
        items {
          id
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          createdAt
          updatedAt
          userFavoriteSessionsId
          favoritedSessionSessionId
        }
        nextToken
      }
      favoriteTrainers {
        items {
          id
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userFavoriteTrainersId
          favoritedTrainerTrainerId
        }
        nextToken
      }
      createdAt
      updatedAt
      userStatsId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      pronouns
      profilePhotoUrl
      homeLocation
      about
      infoForTrainers
      fitnessGoals
      workoutPreferences
      trainingTypes
      trainingTimePreferences
      trainingDayPreferences
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          virtual
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
        }
        nextToken
      }
      medicalConditions {
        items {
          id
          title
          description
          createdAt
          updatedAt
          userMedicalConditionsId
        }
        nextToken
      }
      stats {
        id
        age
        height
        weight
        bmi
        fatPercentage
        stepsPerDay
        calsPerDay
        stairsPerDay
        milesPerWeekRunning
        milesPerWeekCycle
        goalStepsPerDay
        goalCalsPerDay
        goalStairsPerDay
        goalMilesPerWeekRunning
        goalMilesPerWeekCycle
        createdAt
        updatedAt
      }
      focusAreas {
        items {
          id
          injury
          locationX
          locationY
          createdAt
          updatedAt
          userFocusAreasId
        }
        nextToken
      }
      favoriteSessions {
        items {
          id
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          createdAt
          updatedAt
          userFavoriteSessionsId
          favoritedSessionSessionId
        }
        nextToken
      }
      favoriteTrainers {
        items {
          id
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userFavoriteTrainersId
          favoritedTrainerTrainerId
        }
        nextToken
      }
      createdAt
      updatedAt
      userStatsId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      pronouns
      profilePhotoUrl
      homeLocation
      about
      infoForTrainers
      fitnessGoals
      workoutPreferences
      trainingTypes
      trainingTimePreferences
      trainingDayPreferences
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          virtual
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
        }
        nextToken
      }
      medicalConditions {
        items {
          id
          title
          description
          createdAt
          updatedAt
          userMedicalConditionsId
        }
        nextToken
      }
      stats {
        id
        age
        height
        weight
        bmi
        fatPercentage
        stepsPerDay
        calsPerDay
        stairsPerDay
        milesPerWeekRunning
        milesPerWeekCycle
        goalStepsPerDay
        goalCalsPerDay
        goalStairsPerDay
        goalMilesPerWeekRunning
        goalMilesPerWeekCycle
        createdAt
        updatedAt
      }
      focusAreas {
        items {
          id
          injury
          locationX
          locationY
          createdAt
          updatedAt
          userFocusAreasId
        }
        nextToken
      }
      favoriteSessions {
        items {
          id
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          createdAt
          updatedAt
          userFavoriteSessionsId
          favoritedSessionSessionId
        }
        nextToken
      }
      favoriteTrainers {
        items {
          id
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userFavoriteTrainersId
          favoritedTrainerTrainerId
        }
        nextToken
      }
      createdAt
      updatedAt
      userStatsId
    }
  }
`;
export const createFocusArea = /* GraphQL */ `
  mutation CreateFocusArea(
    $input: CreateFocusAreaInput!
    $condition: ModelFocusAreaConditionInput
  ) {
    createFocusArea(input: $input, condition: $condition) {
      id
      injury
      locationX
      locationY
      createdAt
      updatedAt
      userFocusAreasId
    }
  }
`;
export const updateFocusArea = /* GraphQL */ `
  mutation UpdateFocusArea(
    $input: UpdateFocusAreaInput!
    $condition: ModelFocusAreaConditionInput
  ) {
    updateFocusArea(input: $input, condition: $condition) {
      id
      injury
      locationX
      locationY
      createdAt
      updatedAt
      userFocusAreasId
    }
  }
`;
export const deleteFocusArea = /* GraphQL */ `
  mutation DeleteFocusArea(
    $input: DeleteFocusAreaInput!
    $condition: ModelFocusAreaConditionInput
  ) {
    deleteFocusArea(input: $input, condition: $condition) {
      id
      injury
      locationX
      locationY
      createdAt
      updatedAt
      userFocusAreasId
    }
  }
`;
export const createStat = /* GraphQL */ `
  mutation CreateStat(
    $input: CreateStatInput!
    $condition: ModelStatConditionInput
  ) {
    createStat(input: $input, condition: $condition) {
      id
      age
      height
      weight
      bmi
      fatPercentage
      stepsPerDay
      calsPerDay
      stairsPerDay
      milesPerWeekRunning
      milesPerWeekCycle
      goalStepsPerDay
      goalCalsPerDay
      goalStairsPerDay
      goalMilesPerWeekRunning
      goalMilesPerWeekCycle
      createdAt
      updatedAt
    }
  }
`;
export const updateStat = /* GraphQL */ `
  mutation UpdateStat(
    $input: UpdateStatInput!
    $condition: ModelStatConditionInput
  ) {
    updateStat(input: $input, condition: $condition) {
      id
      age
      height
      weight
      bmi
      fatPercentage
      stepsPerDay
      calsPerDay
      stairsPerDay
      milesPerWeekRunning
      milesPerWeekCycle
      goalStepsPerDay
      goalCalsPerDay
      goalStairsPerDay
      goalMilesPerWeekRunning
      goalMilesPerWeekCycle
      createdAt
      updatedAt
    }
  }
`;
export const deleteStat = /* GraphQL */ `
  mutation DeleteStat(
    $input: DeleteStatInput!
    $condition: ModelStatConditionInput
  ) {
    deleteStat(input: $input, condition: $condition) {
      id
      age
      height
      weight
      bmi
      fatPercentage
      stepsPerDay
      calsPerDay
      stairsPerDay
      milesPerWeekRunning
      milesPerWeekCycle
      goalStepsPerDay
      goalCalsPerDay
      goalStairsPerDay
      goalMilesPerWeekRunning
      goalMilesPerWeekCycle
      createdAt
      updatedAt
    }
  }
`;
export const createMedicalCondition = /* GraphQL */ `
  mutation CreateMedicalCondition(
    $input: CreateMedicalConditionInput!
    $condition: ModelMedicalConditionConditionInput
  ) {
    createMedicalCondition(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      userMedicalConditionsId
    }
  }
`;
export const updateMedicalCondition = /* GraphQL */ `
  mutation UpdateMedicalCondition(
    $input: UpdateMedicalConditionInput!
    $condition: ModelMedicalConditionConditionInput
  ) {
    updateMedicalCondition(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      userMedicalConditionsId
    }
  }
`;
export const deleteMedicalCondition = /* GraphQL */ `
  mutation DeleteMedicalCondition(
    $input: DeleteMedicalConditionInput!
    $condition: ModelMedicalConditionConditionInput
  ) {
    deleteMedicalCondition(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      userMedicalConditionsId
    }
  }
`;
export const createTrainer = /* GraphQL */ `
  mutation CreateTrainer(
    $input: CreateTrainerInput!
    $condition: ModelTrainerConditionInput
  ) {
    createTrainer(input: $input, condition: $condition) {
      id
      username
      displayName
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          virtual
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
        }
        nextToken
      }
      sessions {
        items {
          id
          title
          description
          inPerson
          virtual
          maxTimeAfterStart
          category
          city
          state
          defaultPrice
          indoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          outdoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          commuteToMember
          maxCommuteDistance
          commuteFlatFee
          equipment {
            nextToken
          }
          cheerleader
          drillSargeant
          professor
          workoutBuddy
          availability {
            id
            daysAvailable
            maxSessionsPerDay
            minTimeBetweenSessions
            minTimeBeforeBooking
            startDate
            endDate
            isRepeat
            createdAt
            updatedAt
          }
          thrityMinsPrice
          sixtyMinsPrice
          ninetyMinsPrice
          oneTwentyMinsPrice
          coverPhotoIndex
          sessionPhotos
          instantBookingEnabled
          minTimeBeforeInstantBooking
          paused
          searchable
          createdAt
          updatedAt
          trainerSessionsId
          sessionIndoorLocationId
          sessionOutdoorLocationId
          sessionAvailabilityId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTrainer = /* GraphQL */ `
  mutation UpdateTrainer(
    $input: UpdateTrainerInput!
    $condition: ModelTrainerConditionInput
  ) {
    updateTrainer(input: $input, condition: $condition) {
      id
      username
      displayName
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          virtual
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
        }
        nextToken
      }
      sessions {
        items {
          id
          title
          description
          inPerson
          virtual
          maxTimeAfterStart
          category
          city
          state
          defaultPrice
          indoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          outdoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          commuteToMember
          maxCommuteDistance
          commuteFlatFee
          equipment {
            nextToken
          }
          cheerleader
          drillSargeant
          professor
          workoutBuddy
          availability {
            id
            daysAvailable
            maxSessionsPerDay
            minTimeBetweenSessions
            minTimeBeforeBooking
            startDate
            endDate
            isRepeat
            createdAt
            updatedAt
          }
          thrityMinsPrice
          sixtyMinsPrice
          ninetyMinsPrice
          oneTwentyMinsPrice
          coverPhotoIndex
          sessionPhotos
          instantBookingEnabled
          minTimeBeforeInstantBooking
          paused
          searchable
          createdAt
          updatedAt
          trainerSessionsId
          sessionIndoorLocationId
          sessionOutdoorLocationId
          sessionAvailabilityId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTrainer = /* GraphQL */ `
  mutation DeleteTrainer(
    $input: DeleteTrainerInput!
    $condition: ModelTrainerConditionInput
  ) {
    deleteTrainer(input: $input, condition: $condition) {
      id
      username
      displayName
      bookings {
        items {
          id
          session {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          member {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          trainer {
            id
            username
            displayName
            createdAt
            updatedAt
          }
          status
          price
          startTime
          endTime
          location {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          notes
          rating
          review
          sessionMaxHR
          sessionAvgHR
          sessionCalories
          trainerNotes
          trainerSuggestions
          selectedLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          virtual
          createdAt
          updatedAt
          userBookingsId
          trainerBookingsId
          bookingSessionId
          bookingSelectedLocationId
        }
        nextToken
      }
      sessions {
        items {
          id
          title
          description
          inPerson
          virtual
          maxTimeAfterStart
          category
          city
          state
          defaultPrice
          indoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          outdoorLocation {
            id
            name
            address
            directions
            lat
            lng
            isIndoor
            createdAt
            updatedAt
          }
          commuteToMember
          maxCommuteDistance
          commuteFlatFee
          equipment {
            nextToken
          }
          cheerleader
          drillSargeant
          professor
          workoutBuddy
          availability {
            id
            daysAvailable
            maxSessionsPerDay
            minTimeBetweenSessions
            minTimeBeforeBooking
            startDate
            endDate
            isRepeat
            createdAt
            updatedAt
          }
          thrityMinsPrice
          sixtyMinsPrice
          ninetyMinsPrice
          oneTwentyMinsPrice
          coverPhotoIndex
          sessionPhotos
          instantBookingEnabled
          minTimeBeforeInstantBooking
          paused
          searchable
          createdAt
          updatedAt
          trainerSessionsId
          sessionIndoorLocationId
          sessionOutdoorLocationId
          sessionAvailabilityId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment {
          items {
            id
            name
            optional
            createdAt
            updatedAt
            sessionEquipmentId
          }
          nextToken
        }
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        availability {
          id
          daysAvailable
          maxSessionsPerDay
          minTimeBetweenSessions
          minTimeBeforeBooking
          startDate
          endDate
          isRepeat
          createdAt
          updatedAt
        }
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        coverPhotoIndex
        sessionPhotos
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        sessionAvailabilityId
      }
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        displayName
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      price
      startTime
      endTime
      location {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      notes
      rating
      review
      sessionMaxHR
      sessionAvgHR
      sessionCalories
      trainerNotes
      trainerSuggestions
      selectedLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      virtual
      createdAt
      updatedAt
      userBookingsId
      trainerBookingsId
      bookingSessionId
      bookingSelectedLocationId
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment {
          items {
            id
            name
            optional
            createdAt
            updatedAt
            sessionEquipmentId
          }
          nextToken
        }
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        availability {
          id
          daysAvailable
          maxSessionsPerDay
          minTimeBetweenSessions
          minTimeBeforeBooking
          startDate
          endDate
          isRepeat
          createdAt
          updatedAt
        }
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        coverPhotoIndex
        sessionPhotos
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        sessionAvailabilityId
      }
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        displayName
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      price
      startTime
      endTime
      location {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      notes
      rating
      review
      sessionMaxHR
      sessionAvgHR
      sessionCalories
      trainerNotes
      trainerSuggestions
      selectedLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      virtual
      createdAt
      updatedAt
      userBookingsId
      trainerBookingsId
      bookingSessionId
      bookingSelectedLocationId
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment {
          items {
            id
            name
            optional
            createdAt
            updatedAt
            sessionEquipmentId
          }
          nextToken
        }
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        availability {
          id
          daysAvailable
          maxSessionsPerDay
          minTimeBetweenSessions
          minTimeBeforeBooking
          startDate
          endDate
          isRepeat
          createdAt
          updatedAt
        }
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        coverPhotoIndex
        sessionPhotos
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        sessionAvailabilityId
      }
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        displayName
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      price
      startTime
      endTime
      location {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      notes
      rating
      review
      sessionMaxHR
      sessionAvgHR
      sessionCalories
      trainerNotes
      trainerSuggestions
      selectedLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      virtual
      createdAt
      updatedAt
      userBookingsId
      trainerBookingsId
      bookingSessionId
      bookingSelectedLocationId
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      title
      description
      inPerson
      virtual
      maxTimeAfterStart
      category
      city
      state
      defaultPrice
      indoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      outdoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      commuteToMember
      maxCommuteDistance
      commuteFlatFee
      equipment {
        items {
          id
          name
          optional
          createdAt
          updatedAt
          sessionEquipmentId
        }
        nextToken
      }
      cheerleader
      drillSargeant
      professor
      workoutBuddy
      availability {
        id
        daysAvailable
        maxSessionsPerDay
        minTimeBetweenSessions
        minTimeBeforeBooking
        startDate
        endDate
        isRepeat
        createdAt
        updatedAt
      }
      thrityMinsPrice
      sixtyMinsPrice
      ninetyMinsPrice
      oneTwentyMinsPrice
      coverPhotoIndex
      sessionPhotos
      instantBookingEnabled
      minTimeBeforeInstantBooking
      paused
      searchable
      createdAt
      updatedAt
      trainerSessionsId
      sessionIndoorLocationId
      sessionOutdoorLocationId
      sessionAvailabilityId
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      title
      description
      inPerson
      virtual
      maxTimeAfterStart
      category
      city
      state
      defaultPrice
      indoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      outdoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      commuteToMember
      maxCommuteDistance
      commuteFlatFee
      equipment {
        items {
          id
          name
          optional
          createdAt
          updatedAt
          sessionEquipmentId
        }
        nextToken
      }
      cheerleader
      drillSargeant
      professor
      workoutBuddy
      availability {
        id
        daysAvailable
        maxSessionsPerDay
        minTimeBetweenSessions
        minTimeBeforeBooking
        startDate
        endDate
        isRepeat
        createdAt
        updatedAt
      }
      thrityMinsPrice
      sixtyMinsPrice
      ninetyMinsPrice
      oneTwentyMinsPrice
      coverPhotoIndex
      sessionPhotos
      instantBookingEnabled
      minTimeBeforeInstantBooking
      paused
      searchable
      createdAt
      updatedAt
      trainerSessionsId
      sessionIndoorLocationId
      sessionOutdoorLocationId
      sessionAvailabilityId
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      title
      description
      inPerson
      virtual
      maxTimeAfterStart
      category
      city
      state
      defaultPrice
      indoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      outdoorLocation {
        id
        name
        address
        directions
        lat
        lng
        isIndoor
        createdAt
        updatedAt
      }
      commuteToMember
      maxCommuteDistance
      commuteFlatFee
      equipment {
        items {
          id
          name
          optional
          createdAt
          updatedAt
          sessionEquipmentId
        }
        nextToken
      }
      cheerleader
      drillSargeant
      professor
      workoutBuddy
      availability {
        id
        daysAvailable
        maxSessionsPerDay
        minTimeBetweenSessions
        minTimeBeforeBooking
        startDate
        endDate
        isRepeat
        createdAt
        updatedAt
      }
      thrityMinsPrice
      sixtyMinsPrice
      ninetyMinsPrice
      oneTwentyMinsPrice
      coverPhotoIndex
      sessionPhotos
      instantBookingEnabled
      minTimeBeforeInstantBooking
      paused
      searchable
      createdAt
      updatedAt
      trainerSessionsId
      sessionIndoorLocationId
      sessionOutdoorLocationId
      sessionAvailabilityId
    }
  }
`;
export const createAvailability = /* GraphQL */ `
  mutation CreateAvailability(
    $input: CreateAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    createAvailability(input: $input, condition: $condition) {
      id
      daysAvailable
      maxSessionsPerDay
      minTimeBetweenSessions
      minTimeBeforeBooking
      startDate
      endDate
      isRepeat
      createdAt
      updatedAt
    }
  }
`;
export const updateAvailability = /* GraphQL */ `
  mutation UpdateAvailability(
    $input: UpdateAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    updateAvailability(input: $input, condition: $condition) {
      id
      daysAvailable
      maxSessionsPerDay
      minTimeBetweenSessions
      minTimeBeforeBooking
      startDate
      endDate
      isRepeat
      createdAt
      updatedAt
    }
  }
`;
export const deleteAvailability = /* GraphQL */ `
  mutation DeleteAvailability(
    $input: DeleteAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    deleteAvailability(input: $input, condition: $condition) {
      id
      daysAvailable
      maxSessionsPerDay
      minTimeBetweenSessions
      minTimeBeforeBooking
      startDate
      endDate
      isRepeat
      createdAt
      updatedAt
    }
  }
`;
export const createEquipment = /* GraphQL */ `
  mutation CreateEquipment(
    $input: CreateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    createEquipment(input: $input, condition: $condition) {
      id
      name
      optional
      createdAt
      updatedAt
      sessionEquipmentId
    }
  }
`;
export const updateEquipment = /* GraphQL */ `
  mutation UpdateEquipment(
    $input: UpdateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    updateEquipment(input: $input, condition: $condition) {
      id
      name
      optional
      createdAt
      updatedAt
      sessionEquipmentId
    }
  }
`;
export const deleteEquipment = /* GraphQL */ `
  mutation DeleteEquipment(
    $input: DeleteEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    deleteEquipment(input: $input, condition: $condition) {
      id
      name
      optional
      createdAt
      updatedAt
      sessionEquipmentId
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      name
      address
      directions
      lat
      lng
      isIndoor
      createdAt
      updatedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      name
      address
      directions
      lat
      lng
      isIndoor
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      name
      address
      directions
      lat
      lng
      isIndoor
      createdAt
      updatedAt
    }
  }
`;
export const createMessageRoom = /* GraphQL */ `
  mutation CreateMessageRoom(
    $input: CreateMessageRoomInput!
    $condition: ModelMessageRoomConditionInput
  ) {
    createMessageRoom(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      messages {
        items {
          id
          content
          sender {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          read
          createdAt
          updatedAt
          messageRoomMessagesId
          messageSenderId
        }
        nextToken
      }
      createdAt
      updatedAt
      messageRoomMemberId
      messageRoomTrainerId
    }
  }
`;
export const updateMessageRoom = /* GraphQL */ `
  mutation UpdateMessageRoom(
    $input: UpdateMessageRoomInput!
    $condition: ModelMessageRoomConditionInput
  ) {
    updateMessageRoom(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      messages {
        items {
          id
          content
          sender {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          read
          createdAt
          updatedAt
          messageRoomMessagesId
          messageSenderId
        }
        nextToken
      }
      createdAt
      updatedAt
      messageRoomMemberId
      messageRoomTrainerId
    }
  }
`;
export const deleteMessageRoom = /* GraphQL */ `
  mutation DeleteMessageRoom(
    $input: DeleteMessageRoomInput!
    $condition: ModelMessageRoomConditionInput
  ) {
    deleteMessageRoom(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      messages {
        items {
          id
          content
          sender {
            id
            username
            pronouns
            profilePhotoUrl
            homeLocation
            about
            infoForTrainers
            fitnessGoals
            workoutPreferences
            trainingTypes
            trainingTimePreferences
            trainingDayPreferences
            createdAt
            updatedAt
            userStatsId
          }
          read
          createdAt
          updatedAt
          messageRoomMessagesId
          messageSenderId
        }
        nextToken
      }
      createdAt
      updatedAt
      messageRoomMemberId
      messageRoomTrainerId
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      sender {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      read
      createdAt
      updatedAt
      messageRoomMessagesId
      messageSenderId
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      content
      sender {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      read
      createdAt
      updatedAt
      messageRoomMessagesId
      messageSenderId
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      content
      sender {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      read
      createdAt
      updatedAt
      messageRoomMessagesId
      messageSenderId
    }
  }
`;
export const createFavoritedSession = /* GraphQL */ `
  mutation CreateFavoritedSession(
    $input: CreateFavoritedSessionInput!
    $condition: ModelFavoritedSessionConditionInput
  ) {
    createFavoritedSession(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment {
          items {
            id
            name
            optional
            createdAt
            updatedAt
            sessionEquipmentId
          }
          nextToken
        }
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        availability {
          id
          daysAvailable
          maxSessionsPerDay
          minTimeBetweenSessions
          minTimeBeforeBooking
          startDate
          endDate
          isRepeat
          createdAt
          updatedAt
        }
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        coverPhotoIndex
        sessionPhotos
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        sessionAvailabilityId
      }
      createdAt
      updatedAt
      userFavoriteSessionsId
      favoritedSessionSessionId
    }
  }
`;
export const updateFavoritedSession = /* GraphQL */ `
  mutation UpdateFavoritedSession(
    $input: UpdateFavoritedSessionInput!
    $condition: ModelFavoritedSessionConditionInput
  ) {
    updateFavoritedSession(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment {
          items {
            id
            name
            optional
            createdAt
            updatedAt
            sessionEquipmentId
          }
          nextToken
        }
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        availability {
          id
          daysAvailable
          maxSessionsPerDay
          minTimeBetweenSessions
          minTimeBeforeBooking
          startDate
          endDate
          isRepeat
          createdAt
          updatedAt
        }
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        coverPhotoIndex
        sessionPhotos
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        sessionAvailabilityId
      }
      createdAt
      updatedAt
      userFavoriteSessionsId
      favoritedSessionSessionId
    }
  }
`;
export const deleteFavoritedSession = /* GraphQL */ `
  mutation DeleteFavoritedSession(
    $input: DeleteFavoritedSessionInput!
    $condition: ModelFavoritedSessionConditionInput
  ) {
    deleteFavoritedSession(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      session {
        id
        title
        description
        inPerson
        virtual
        maxTimeAfterStart
        category
        city
        state
        defaultPrice
        indoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        outdoorLocation {
          id
          name
          address
          directions
          lat
          lng
          isIndoor
          createdAt
          updatedAt
        }
        commuteToMember
        maxCommuteDistance
        commuteFlatFee
        equipment {
          items {
            id
            name
            optional
            createdAt
            updatedAt
            sessionEquipmentId
          }
          nextToken
        }
        cheerleader
        drillSargeant
        professor
        workoutBuddy
        availability {
          id
          daysAvailable
          maxSessionsPerDay
          minTimeBetweenSessions
          minTimeBeforeBooking
          startDate
          endDate
          isRepeat
          createdAt
          updatedAt
        }
        thrityMinsPrice
        sixtyMinsPrice
        ninetyMinsPrice
        oneTwentyMinsPrice
        coverPhotoIndex
        sessionPhotos
        instantBookingEnabled
        minTimeBeforeInstantBooking
        paused
        searchable
        createdAt
        updatedAt
        trainerSessionsId
        sessionIndoorLocationId
        sessionOutdoorLocationId
        sessionAvailabilityId
      }
      createdAt
      updatedAt
      userFavoriteSessionsId
      favoritedSessionSessionId
    }
  }
`;
export const createFavoritedTrainer = /* GraphQL */ `
  mutation CreateFavoritedTrainer(
    $input: CreateFavoritedTrainerInput!
    $condition: ModelFavoritedTrainerConditionInput
  ) {
    createFavoritedTrainer(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        displayName
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userFavoriteTrainersId
      favoritedTrainerTrainerId
    }
  }
`;
export const updateFavoritedTrainer = /* GraphQL */ `
  mutation UpdateFavoritedTrainer(
    $input: UpdateFavoritedTrainerInput!
    $condition: ModelFavoritedTrainerConditionInput
  ) {
    updateFavoritedTrainer(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        displayName
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userFavoriteTrainersId
      favoritedTrainerTrainerId
    }
  }
`;
export const deleteFavoritedTrainer = /* GraphQL */ `
  mutation DeleteFavoritedTrainer(
    $input: DeleteFavoritedTrainerInput!
    $condition: ModelFavoritedTrainerConditionInput
  ) {
    deleteFavoritedTrainer(input: $input, condition: $condition) {
      id
      member {
        id
        username
        pronouns
        profilePhotoUrl
        homeLocation
        about
        infoForTrainers
        fitnessGoals
        workoutPreferences
        trainingTypes
        trainingTimePreferences
        trainingDayPreferences
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        medicalConditions {
          items {
            id
            title
            description
            createdAt
            updatedAt
            userMedicalConditionsId
          }
          nextToken
        }
        stats {
          id
          age
          height
          weight
          bmi
          fatPercentage
          stepsPerDay
          calsPerDay
          stairsPerDay
          milesPerWeekRunning
          milesPerWeekCycle
          goalStepsPerDay
          goalCalsPerDay
          goalStairsPerDay
          goalMilesPerWeekRunning
          goalMilesPerWeekCycle
          createdAt
          updatedAt
        }
        focusAreas {
          items {
            id
            injury
            locationX
            locationY
            createdAt
            updatedAt
            userFocusAreasId
          }
          nextToken
        }
        favoriteSessions {
          items {
            id
            createdAt
            updatedAt
            userFavoriteSessionsId
            favoritedSessionSessionId
          }
          nextToken
        }
        favoriteTrainers {
          items {
            id
            createdAt
            updatedAt
            userFavoriteTrainersId
            favoritedTrainerTrainerId
          }
          nextToken
        }
        createdAt
        updatedAt
        userStatsId
      }
      trainer {
        id
        username
        displayName
        bookings {
          items {
            id
            status
            price
            startTime
            endTime
            notes
            rating
            review
            sessionMaxHR
            sessionAvgHR
            sessionCalories
            trainerNotes
            trainerSuggestions
            virtual
            createdAt
            updatedAt
            userBookingsId
            trainerBookingsId
            bookingSessionId
            bookingSelectedLocationId
          }
          nextToken
        }
        sessions {
          items {
            id
            title
            description
            inPerson
            virtual
            maxTimeAfterStart
            category
            city
            state
            defaultPrice
            commuteToMember
            maxCommuteDistance
            commuteFlatFee
            cheerleader
            drillSargeant
            professor
            workoutBuddy
            thrityMinsPrice
            sixtyMinsPrice
            ninetyMinsPrice
            oneTwentyMinsPrice
            coverPhotoIndex
            sessionPhotos
            instantBookingEnabled
            minTimeBeforeInstantBooking
            paused
            searchable
            createdAt
            updatedAt
            trainerSessionsId
            sessionIndoorLocationId
            sessionOutdoorLocationId
            sessionAvailabilityId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userFavoriteTrainersId
      favoritedTrainerTrainerId
    }
  }
`;
