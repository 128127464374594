import * as React from 'react'
import Slider from 'react-slick'
import Grid from '@mui/material/Grid'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SessionCard from '../session_card/SessionCard'
import { useGetFeaturedTrainingQuery } from '../../redux/api/api'
const workouts = [
  {
    imgPath:
      'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318',
    title: 'Strength Training',
  },
  {
    imgPath:
      'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318',
    title: 'Yoga and Pilates',
  },
  {
    imgPath:
      'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318',
    title: 'Sports Coaching',
  },
  {
    imgPath:
      'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318',
    title: 'Adventure Training',
  },
  {
    imgPath:
      'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318',
    title: 'Sports Coaching',
  },
  {
    imgPath:
      'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318',
    title: 'Adventure Training',
  },
]

const settings = {
  className: 'center',
  centerMode: false,
  infinite: true,
  centerPadding: '0px',
  slidesToShow: 3,
  speed: 500,
}

export default function FeaturedSessionsRow() {
  // const { data, isLoading, isSuccess, isError, error } =
  //   useGetFeaturedTrainingQuery()
  const isSuccess = true
  if (isSuccess) {
    return (
      <Box sx={{ backgroundColor: 'gunMetal.main', p: 5 }}>
        <Typography sx={{ color: 'white', fontWeight: 700, fontSize: 24 }}>
          Featured Sessions
        </Typography>
        <Slider {...settings}>
          {/* {data.map((session) => (
            <SessionCard key={session.id} session={session}/>
          ))} */}
        </Slider>
      </Box>
    )
  }
}
