import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'

import { useSelector, useDispatch } from 'react-redux'

export default function ContainedSelectButton(props) {
  const { text, onClick } = props
  const theme = useTheme()
  const StyledButton = styled(Button)(({theme})=>({
    color: 'white',
    borderColor: theme.palette.gunMetal.main,
    fontWeight: 700,
    fontSize: 15,
    paddingLeft: 16,
    paddingRight: 16,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      border:'1px solid transparent'
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      border:'1px solid transparent'
    }
  }));
  return (
    <StyledButton
      variant="contained"
      onClick={onClick}
    >
      {text}
    </StyledButton>
  )
}
