import { Box, IconButton } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setUploadedImage,
  setUserProfilePicture,
} from '../../../redux/slices/formsSlice'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
} from '../../../redux/slices/uiSlice'
import { useGetImageQuery } from '../../../redux/api/api'
import { ReactComponent as UploadProfileIcon } from '../../../assets/icons/upload_profile.svg'

const ProfilePhotoUpload = (props) => {
  const dispatch = useDispatch()
  const cognitoUser = useSelector((state) => state.auth.cognitoUser.sub)
  const profileFormData = useSelector((state) => state.forms.userProfileModal)
  const { data, error, isLoading } = useGetImageQuery(`${cognitoUser}/Profile`)
  const [hovered, setHovered] = useState(false)
  const openFile = () => {
    document.getElementById('profile-photo').click()
  }
  const handleUpload = (e) => {
    console.log('setting Uploaded Image: ', e.target.files[0])
    dispatch(setUploadedImage(URL.createObjectURL(e.target.files[0])))
    dispatch(setUserProfilePicture(URL.createObjectURL(e.target.files[0])))
    if (props.onUpload) {
      props.onUpload()
    }
  }
  if (!data || !profileFormData.profilePicture) {
    return (
      <IconButton
        onClick={openFile}
        sx={{
          cursor: 'pointer',

          width: '200px',
          height: '200px',
          borderRadius: '50%',
          backgroundColor: 'gunMetal.main',

          zIndex: 50,
        }}
      >
        <input
          id="profile-photo"
          type="file"
          style={{ display: 'none' }}
          accept=".gif,.jpg,.jpeg,.png"
          onChange={handleUpload}
        />
        <UploadProfileIcon
          style={{
            width: '200px',
            height: '200px',
            zIndex: 100,
          }}
        />
      </IconButton>
    )
  }
  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        position: 'relative',
        width: '200px',
        height: '200px',
        borderRadius: '50%',
        backgroundSize: '200px 200px',
        backgroundImage: `url(${data})`,
      }}
    >
      <input
        id="profile-photo"
        type="file"
        style={{ display: 'none' }}
        accept=".gif,.jpg,.jpeg,.png"
        onChange={handleUpload}
      />
      {hovered && (
        <>
          <Box
            onClick={openFile}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '200px',
              height: '200px',
              borderRadius: '50%',
              backgroundColor: 'gunMetal.main',
              opacity: 0.5,
              zIndex: 50,
            }}
          >
            <UploadProfileIcon
              style={{
                width: '200px',
                height: '200px',
                zIndex: 100,
              }}
            />
          </Box>
          <UploadProfileIcon
            style={{
              width: '200px',
              height: '200px',
            }}
          />
        </>
      )}
    </Box>
  )
}

export default ProfilePhotoUpload
