import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Radio,
  FormControlLabel,
  Checkbox,
  TextField,
  Input,
  OutlinedInput,
  Button,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import * as React from 'react'
import { ReactComponent as FacilityIcon } from '../../assets/icons/facility.svg'
import CellphoneIcon from '../../assets/icons/cellphone.png'
import OutdoorIcon from '../../assets/icons/outdoor.png'
import ExampleLocation from '../../assets/icons/exampleLocation.png'

import { ReactComponent as CommuteIcon } from '../../assets/icons/commute.svg'

import { useSelector, useDispatch } from 'react-redux'
import {
  setGeneralModalComponent,
  setGeneralModalOpen,
  setTrainerAvailabilityModalVisible,
} from '../../redux/slices/uiSlice'
import { ArrowBack, Check } from '@mui/icons-material'
import Calendar from '../calendar/Calendar'
import ReviewStepper from './ReviewStepper'
import {
  useGetSessionDetailsByIdQuery,
  useGetTrainerByIdQuery,
} from '../../redux/api/api'
import { getSession } from '../../graphql/queries'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { createBooking } from '../../graphql/mutations'
import { useEffect } from 'react'
import { setCreateBookingField } from '../../redux/slices/formsSlice'
import axios from 'axios'
import { setStripeClientSecret } from '../../redux/slices/paymentsSlice'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import S3Image from '../S3Image'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export default function TrainerAvailabilityModal() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const trainerAvailabilityModalOpen = useSelector(
    (state) => state.ui.trainerAvailabilityModalOpen
  )
  const createNewSessionTime = useSelector(
    (state) => state.forms.createBookingTime
  )
  const currentUserId = useSelector((state) => state.auth?.cognitoUser?.sub)
  const viewTrainerId = useSelector((state) => state.ui.viewTrainerId)
  const bookingFormData = useSelector((state) => state.forms.createBookingForm)
  const createBookingTime = useSelector(
    (state) => state.forms.createBookingTime
  )
  const stripeCustomerId = useSelector((state) => state.auth.stripeCustomerId)

  const { data, isLoading, isSuccess, isError, error } =
    useGetTrainerByIdQuery(viewTrainerId)
  const getSessionByIdRequest = useGetSessionDetailsByIdQuery(
    bookingFormData.bookingSessionId,
    { skip: bookingFormData?.bookingSessionId === null }
  )
  useEffect(() => {
    console.log('bookingFormData: ', bookingFormData)
    if (currentUserId) {
      dispatch(
        setCreateBookingField({ field: 'userBookingsId', value: currentUserId })
      )
    }
  }, [bookingFormData])
  useEffect(() => {
    if (getSessionByIdRequest.data) {
      console.log('getSessionByIdRequest: ', getSessionByIdRequest.data)
    }
  }, [getSessionByIdRequest])

  const closeTrainerAvailability = () => {
    dispatch(setTrainerAvailabilityModalVisible(false))
  }

  // const handleTest = async () => {
  //   try {
  //     const sessionData = await API.graphql(
  //       graphqlOperation(getSession, {
  //         id: 'fd8da131-157d-4bcb-9aaf-d740fb395b45',
  //       })
  //     )
  //     const testData = sessionData.data.getSession
  //     console.log('testData: ', testData)
  //   } catch (error) {
  //     console.log('error fetching sesion:', error)
  //   }
  // }

  const handleRequestBooking = async () => {
    try {
      console.log('bookingFormData: ', bookingFormData)
      console.log('createBookingTime: ', createBookingTime)
      const { amPm, date, duration, month, time, year, zone } =
        createBookingTime
      const formattedStartTime = dayjs(
        `${month}/${date}/${year} ${time}${amPm}`,
        'M/D/YYYY hA'
      )
      const testzone = 'America/Chicago'
      const testerr = dayjs(formattedStartTime).tz(testzone)
      testerr.format('YYYY-MM-DDTHH:mm:ss.sssZ')
      const bookingData = await API.graphql(
        graphqlOperation(createBooking, {
          input: {
            ...bookingFormData,
            startTime: testerr.format('YYYY-MM-DDTHH:mm:ss.sssZ'),
          },
        })
      )
      const booking = bookingData.data.createBooking
      console.log('booking: ', booking)
      setPage(2)
    } catch (error) {
      console.log('error requesting booking: ', error)
    }
  }

  const handleGoToPayment = async () => {
    try {
      const stripeIntent = await axios.post(
        'https://auibul7a6pvvdywpofvw5rkkgy0kfduu.lambda-url.us-east-2.on.aws/',
        {
          //customerId: stripeCustomerId,
          customerId: stripeCustomerId,
        }
      )
      console.log('stripeIntent.data', stripeIntent.data)

      const secret = stripeIntent.data.client_secret
      console.log('secret', secret)
      //dispatch(setStripeIntent(stripeIntent.data.id))
      dispatch(setStripeClientSecret(secret))
      // dispatch(setGeneralModalComponent('payment_method'))
      // dispatch(setGeneralModalOpen())
    } catch (error) {
      console.log('error creating stripe intent: ', error)
    }
  }

  const [page, setPage] = React.useState(1)
  const avilabilityAvatarRow = (
    <Grid
      container
      style={{
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      {/* <Button onClick={handleTest}>Test</Button> */}
      <Grid
        item
        onClick={() => {
          dispatch(setTrainerAvailabilityModalVisible(false))
          navigate(`/trainer/${viewTrainerId}`)
        }}
      >
        <S3Image
          imagePath={`${data?.username}/Profile`}
          dimensions={{
            height: '130px',
            width: '130px',
            borderRadius: '300px',
          }}
        />
      </Grid>
      <Grid item sx={{ color: 'primary.main', textAlign: 'left', pl: 2 }}>
        <Grid container sx={{ justifyContent: 'flex-start' }}>
          <FacilityIcon />{' '}
          <Typography sx={{ fontWeight: 700, fontSize: '14px', pl: 2 }}>
            {' '}
            Teaches out of facility
          </Typography>
        </Grid>
        <Grid container sx={{ justifyContent: 'flex-start' }}>
          <img src={OutdoorIcon} />
          <Typography sx={{ fontWeight: 700, fontSize: '14px', pl: 2.5 }}>
            {' '}
            Meets Outdoors
          </Typography>
        </Grid>
        <Grid container sx={{ justifyContent: 'flex-start' }}>
          <CommuteIcon />
          <Typography sx={{ fontWeight: 700, fontSize: '14px', pl: 2 }}>
            {' '}
            Commutes to You
          </Typography>
        </Grid>
        <Grid container sx={{ justifyContent: 'flex-start' }}>
          <img src={CellphoneIcon} />
          <Typography sx={{ fontWeight: 700, fontSize: '14px', pl: 3.5 }}>
            {' '}
            Offers Virtual
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
  const availabilityHeaderBar = (
    <DialogTitle style={{ width: '90%' }}>
      <Grid
        container
        justifyContent={'space-between'}
        sx={{ p: 2, pl: 4, pr: 4, pb: 0 }}
      >
        <Grid item>
          {false && (
            <IconButton onClick={closeTrainerAvailability}>
              <CloseIcon />
            </IconButton>
          )}
        </Grid>

        <Grid item>
          <IconButton onClick={closeTrainerAvailability}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item>
          <Typography
            sx={{ fontWeight: 700, fontSize: '34px', lineHeight: '51px' }}
          >
            {' '}
            {data && data?.displayName}'s Availability
          </Typography>
        </Grid>
      </Grid>
    </DialogTitle>
  )
  const reviewHeaderBar = (
    <DialogTitle style={{ width: '90%' }}>
      <Grid
        container
        justifyContent={'space-between'}
        sx={{ p: 2, pl: 4, pr: 4, pb: 0 }}
      >
        <Grid item>
          {page > 1 && (
            <IconButton onClick={() => setPage(page - 1)}>
              <ArrowBack />
            </IconButton>
          )}
        </Grid>

        <Grid item>
          <IconButton onClick={closeTrainerAvailability}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  )
  const selectedTimeRow = (
    <Grid container style={{ justifyContent: 'space-between' }} sx={{ p: 4 }}>
      <Grid item style={{ justifyContent: 'flex-start' }}>
        <Typography
          sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '26px' }}
        >
          Choose a date and time
        </Typography>

        <Typography>When do you want to GET TRAIND?</Typography>
      </Grid>
      <Grid item>
        <Grid container justifyContent="end">
          <Typography> Selected:</Typography>
        </Grid>
        <Grid container justifyContent="end">
          <Typography
            sx={{ fontWeight: 'bold', color: 'primary.main', fontSize: '16px' }}
          >
            {createNewSessionTime.month}/{createNewSessionTime.date}/
            {createNewSessionTime.year} {createNewSessionTime.time}:00{' '}
            {createNewSessionTime.amPm} {createNewSessionTime.zone}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
  //const [selectedLocationId, setSelectedLocationId] = React.useState('')
  const handleRadioChange = (location) => {
    console.log('location', location)
    // dispatch(
    //   setCreateBookingField({
    //     field: 'selectedLocationId',
    //     value: location.id,
    //   })
    // )
  }
  const locationChoiceRow = (
    disabled,
    type,
    name,
    address,
    instructions,
    ownLocation,
    virtual,
    location
  ) => (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ p: 2, opacity: disabled ? 0.5 : 1 }}
    >
      <Grid item sx={{ p: 0, m: 0, pr: 2 }}>
        <Radio
          sx={{ p: 0, m: 0, color: 'primary.main' }}
          checked={bookingFormData.selectedLocationId === location.id}
          onChange={() => handleRadioChange(location)}
          value="a"
          name="radio-buttons"
          inputProps={{ 'aria-label': 'A' }}
        />
      </Grid>
      <Grid item xs={10} md={5}>
        <Grid container sx={{ justifyContent: 'left', pb: 2 }}>
          <Typography
            sx={{ fontWeight: 700, fontSize: '17px', color: 'gunMetal.main' }}
          >
            {type}
          </Typography>
        </Grid>
        <Grid container sx={{ justifyContent: 'left', pb: 1 }}>
          <Typography
            sx={{ fontWeight: 700, fontSize: '15px', color: 'gunMetal.main' }}
          >
            {name}
          </Typography>
        </Grid>
        {!ownLocation && (
          <Grid container sx={{ justifyContent: 'left' }}>
            <Typography>{address}</Typography>
          </Grid>
        )}
        {ownLocation && (
          <FormControlLabel
            control={<Checkbox sx={{ color: 'primary.main' }} defaultChecked />}
            label="Use saved location"
          />
        )}
        {ownLocation && (
          <Grid container sx={{ justifyContent: 'left' }}>
            <Typography>Location Address</Typography>
            <OutlinedInput
              fullWidth
              sx={{ borderRadius: '20px', height: '37px' }}
              label="location"
            />
          </Grid>
        )}
        {ownLocation && (
          <Grid container sx={{ justifyContent: 'left' }}>
            <Typography>
              More Directions - Describe location and use pin to specify exact
              meeting place
            </Typography>
            <OutlinedInput
              fullWidth
              sx={{ borderRadius: '20px', height: '37px' }}
              label="details"
            />
          </Grid>
        )}
      </Grid>
      {!virtual && (
        <Grid item xs={12} md={4} sx={{ marginLeft: 'auto' }}>
          <Grid container justifyContent="flex-start">
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '15px',
                color: 'gunMetal.main',
                pb: 2,
              }}
            >
              {instructions}
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-start">
            <Box
              sx={{
                borderRadius: '20px',

                width: '80%',
                height: 150,
              }}
            >
              <img
                src={ExampleLocation}
                style={{ width: '100%' }}
                alt="example location"
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
  const chooseLocationRow = (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '26px',
              lineHeight: '39px',
              color: 'gunMetal.main',
            }}
          >
            Choose A location
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '17px',
              lineHeight: '22.5px',
              color: 'gunMetal.main',
            }}
          >
            Where do you want to GET TRAIND?
          </Typography>
        </Grid>
      </Grid>
      {getSessionByIdRequest?.data?.indoorLocation?.address &&
        locationChoiceRow(
          false,
          'Train at Indoor Facility',
          getSessionByIdRequest?.data?.indoorLocation?.name,
          getSessionByIdRequest?.data?.indoorLocation?.address,
          getSessionByIdRequest?.data?.indoorLocation?.directions
        )}
      {getSessionByIdRequest?.data?.outdoorLocation &&
        locationChoiceRow(
          false,
          'Train at Outdoor Facility',
          getSessionByIdRequest?.data?.outdoorLocation?.name,
          getSessionByIdRequest?.data?.outdoorLocation?.address,
          getSessionByIdRequest?.data?.outdoorLocation?.directions,
          null,
          false,
          getSessionByIdRequest?.data?.indoorLocation
        )}
      {getSessionByIdRequest?.data?.commuteToMember &&
        locationChoiceRow(false, 'Choose your own location', '', '', '', true)}
      {getSessionByIdRequest?.data?.virtual &&
        locationChoiceRow(
          false,
          'Virtual',
          'Virtual meeting via our platform',
          '',
          '',
          false,
          true
        )}
    </>
  )
  const requestBookingActionRow = (
    <Grid container sx={{ p: 2 }}>
      <Typography
        sx={{ color: 'gunMetal.main', fontWeight: 700, fontSize: '15px' }}
      >
        {' '}
        GET YOUR PERFECT WORKOUT
      </Typography>
      <Grid container sx={{ p: 2 }}>
        <Grid item sx={{ p: 2 }}>
          <Button
            variant="outlined"
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              p: 2,
              pl: 3,
              pr: 3,
              fontSize: '15px',
            }}
          >
            {' '}
            Close
          </Button>
        </Grid>
        <Grid item sx={{ p: 2 }}>
          <Button
            onClick={handleRequestBooking}
            variant="contained"
            sx={{
              color: 'white',
              fontWeight: 700,
              p: 2,
              pl: 3,
              pr: 3,
              fontSize: '15px',
            }}
          >
            {' '}
            Request Booking
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
  const requestBookingPageOne = (
    <>
      {availabilityHeaderBar}
      <DialogContent
        style={{
          alignItems: 'center',
          width: '90%',
          justifyContent: 'center',
        }}
      >
        {avilabilityAvatarRow}
        {selectedTimeRow}
        <Calendar />
        {chooseLocationRow}
        {requestBookingActionRow}
      </DialogContent>
    </>
  )
  if (!isSuccess) {
    return (
      <Dialog>
        <DialogContent>
          <Grid container justifyContent="center">
            <Grid item xs={12} sx={{ p: 2 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '26px',
                  lineHeight: '39px',
                  color: 'gunMetal.main',
                }}
              >
                Loading
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog
      key={data.id}
      maxWidth={'lg'}
      open={trainerAvailabilityModalOpen}
      PaperProps={{
        sx: {
          width: '100%',

          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      {page === 1 && requestBookingPageOne}
      {page === 2 && (
        <>
          {reviewHeaderBar}
          <DialogContent
            style={{
              alignItems: 'center',
              width: '90%',
              justifyContent: 'center',
            }}
          >
            <ReviewStepper handleGoToPayment={handleGoToPayment} />
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
