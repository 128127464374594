import { Box, Button, Grid, IconButton, Paper, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import SubTitleB from '../text/SubTitleB'
import { ReactComponent as PencilLine } from '../../assets/icons/pencil_line.svg'
import { ReactComponent as Trash } from '../../assets/icons/trash.svg'
import { ReactComponent as FloppyDisk } from '../../assets/icons/floppy_disk.svg'
import { ReactComponent as Plus } from '../../assets/icons/plus.svg'

import InfoText from '../text/InfoText'
import TitleB from '../text/TitleB'
import SubTitleA from '../text/SubTitleA'
import {
  useCreateConditionMutation,
  useDeleteConditionMutation,
  useGetMemberConditionsByIdQuery,
  useUpdateConditionMutation,
} from '../../redux/api/api'
import { useSelector } from 'react-redux'

const Conditions = (props) => {
  const editMode = props.editMode
  const cognitoUserId = useSelector((state) => state.auth.cognitoUser.sub)
  const { data, isLoading, isSuccess, isError, error } =
    useGetMemberConditionsByIdQuery(cognitoUserId, {
      skip: !cognitoUserId,
    })
  const [conditions, setConditions] = useState([])
  const [addingNewCondition, setAddingNewCondition] = useState(false)
  const [newConditionTitle, setNewConditionTitle] = useState('')
  const [newConditionDescription, setNewConditionDescription] = useState('')
  const [
    createConditionTrigger, // This is the mutation trigger
    { isLoading: isCreating }, // This is the destructured mutation result
  ] = useCreateConditionMutation()

  const [
    updateConditionTrigger, // This is the mutation trigger
    { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useUpdateConditionMutation()

  const [
    deleteConditionTrigger, // This is the mutation trigger
    { isLoading: isDeleting }, // This is the destructured mutation result
  ] = useDeleteConditionMutation()

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (data) {
      console.log('data', data)
      const temp = data?.map((condition) => {
        return {
          condition: condition.title,
          description: condition.description,
          id: condition.id,
          edit: false,
        }
      })
      setConditions(temp)
    }
  }, [data])

  const handleAddCondition = async () => {
    console.log('handleAddCondition')
    const newCondition = {
      title: newConditionTitle,
      description: newConditionDescription,
      userMedicalConditionsId: cognitoUserId,
    }
    console.log('newCondition: ', newCondition)
    try {
      const resp = await createConditionTrigger(newCondition)
      console.log('resp', resp)
      const conditionsCopy = [...conditions]
      const successCondition = {
        condition: newConditionTitle,
        description: newConditionDescription,
        id: resp.data.id,
        edit: false,
      }
      conditionsCopy.push(successCondition)
      console.log('conditionsCopy', conditionsCopy)
      setConditions(conditionsCopy)
      setAddingNewCondition(false)
      setNewConditionTitle('')
      setNewConditionDescription('')
      return resp
    } catch (error) {
      console.log('error', error)
      return null
    }
    //return createConditionTrigger(newCondition)
  }

  const handleChangeNewConditionTitle = (e) => {
    setNewConditionTitle(e.target.value)
  }
  const handleChangeNewConditionDescription = (e) => {
    setNewConditionDescription(e.target.value)
  }

  async function editCondition(id, newCondition) {
    console.log('newcondition', newCondition)
    const temp = conditions.map((condition) => {
      if (condition.id === id) {
        console.log('Found Matching Condition: ', condition)
        if (condition.edit) {
          console.log('Condition is in Edit Mode Trying To Save: ', condition)
          const updatedCondition = {
            id: condition.id,
            title: newCondition.condition,
            description: newCondition.description,
            userMedicalConditionsId: cognitoUserId,
          }
          console.log('updatedCondition: ', updatedCondition)
          updateConditionTrigger(updatedCondition)
          return {
            ...newCondition,
            edit: false,
          }
        }
        if (!condition.edit) {
          return { ...condition, edit: true }
        } else {
          return { ...newCondition, edit: false }
        }
      } else {
        return condition
      }
    })
    setConditions(temp)
  }

  async function deleteCondition({ id }) {
    console.log('delete condition', id)
    deleteConditionTrigger({ id })
    const temp = conditions.filter((condition) => condition.id !== id)
    console.log('temp deleted', temp)
    setConditions(temp)
  }

  const ConditionItem = (props) => {
    const { condition } = props
    if (!condition.edit) {
      return (
        <li key={`condition${condition.id}`}>
          <Grid container spacing={1} sx={{ justifyContent: 'flex-start' }}>
            <Grid item>
              {' '}
              <SubTitleB text={condition.condition} /> - {condition.description}
            </Grid>
            <Grid item>
              <IconButton
                sx={{
                  backgroundColor: 'primary.main',
                  height: '32px',
                  width: '32px',
                }}
                onClick={() => editCondition(condition.id, condition)}
              >
                <PencilLine />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                sx={{ backgroundColor: 'primary.main', width: '32px' }}
                onClick={() =>
                  deleteCondition({
                    id: condition.id,
                  })
                }
              >
                <Trash />
              </IconButton>
            </Grid>
          </Grid>
        </li>
      )
    }
  }

  const ConditionItemEdit = (props) => {
    const { condition } = props
    const updatedCondition = { ...condition }

    const handleChangeDescription = (e) => {
      updatedCondition.description = e.target.value
    }

    const handleChangeCondition = (e) => {
      updatedCondition.condition = e.target.value
    }

    if (condition.edit) {
      console.log('CONDITION IN EDIT MODE: ', condition)
      return (
        <>
          <InfoText text="Health condition or injury" />{' '}
          <Grid container sx={{ justifyContent: 'flex-start', pb: '16px' }}>
            <Grid item xs={12}>
              {/* <RoundTextField
                width="300px"
                defaultValue={condition.condition}
                onChange={handleChangeCondition}
              ></RoundTextField> */}
              <TextField
                sx={{
                  pb: '16px',
                  width: '300px',
                  '& .MuiInputBase-input': {
                    p: 0,
                    pl: '10px',
                  },
                  '& fieldset': { borderRadius: '20px' },
                }}
                placeholder={'Condition'}
                defaultValue={condition.condition}
                onChange={handleChangeCondition}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoText text="Describe the Condition (details, severity, etc.)" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                defaultValue={condition.description}
                sx={{
                  pb: '16px',
                  '& fieldset': { borderRadius: '20px' },
                }}
                placeholder="More about your condition"
                onChange={handleChangeDescription}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<FloppyDisk />}
                style={{ color: 'gunMetal.main' }}
                onClick={() => {
                  editCondition(condition.id, updatedCondition)
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </>
      )
    }
  }

  return (
    <Grid container justifyContent="start" sx={{ pt: 2 }}>
      <TitleB text="What Your Trainer Should Know" />
      <Paper
        sx={{
          //height: 180,
          width: '100%',
          borderRadius: '16px',
          mt: '16px',
          p: 2,
        }}
      >
        <Box style={{ padding: '32px' }}>
          <SubTitleA text="Do you have any medical issues or conditions we should be aware of to better serve you?" />

          <ul>
            {conditions.map((condition) => {
              if (condition.edit) {
                return (
                  <ConditionItemEdit key={condition} condition={condition} />
                )
              } else {
                return <ConditionItem key={condition} condition={condition} />
              }
            })}
          </ul>

          {addingNewCondition ? (
            <Grid container style={{ justifyContent: 'flex-start' }}>
              <InfoText text="Health condition or injury" />
              <Grid item xs={12}>
                <TextField
                  onChange={handleChangeNewConditionTitle}
                  sx={{
                    pb: '16px',
                    width: '300px',
                    '& .MuiInputBase-input': {
                      p: 0,
                      pl: '10px',
                    },
                    '& fieldset': { borderRadius: '20px' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InfoText text="Describe the Condition (details, severity, etc.)" />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={handleChangeNewConditionDescription}
                  multiline
                  fullWidth
                  sx={{
                    pb: '16px',
                    '& fieldset': { borderRadius: '20px' },
                  }}
                  placeholder="More about your condition"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => handleAddCondition()}
                  variant="outlined"
                  startIcon={<FloppyDisk />}
                  style={{ color: 'gunMetal.main' }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Button
              variant="outlined"
              startIcon={<Plus />}
              style={{ color: 'gunMetal.main' }}
              onClick={() => setAddingNewCondition(true)}
            >
              Add Condition
            </Button>
          )}
        </Box>
      </Paper>
    </Grid>
  )
}

export default Conditions
