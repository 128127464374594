import { Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetMemberByIdQuery,
  useGetTimeDayPreferenceByIdQuery,
} from '../../redux/api/api'
import { setUserProfileModalField } from '../../redux/slices/formsSlice'
import BorderSelectButton from '../buttons/BorderSelectButton'
import TitleC from '../text/TitleC'

const PreferredTrainingDaysTime = (props) => {
  const dispatch = useDispatch()
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const formTimePreferences = useSelector(
    (state) => state.forms.userProfileModal.trainingTimePreferences
  )
  const formDayPreferences = useSelector(
    (state) => state.forms.userProfileModal.trainingDayPreferences
  )
  const { data, isLoading, isSuccess, isError, error } = useGetMemberByIdQuery(
    cognitoUser?.sub,
    { skip: !cognitoUser?.sub }
  )
  const { onboarding } = props
  const [localData, setLocalData] = useState([])
  useEffect(() => {
    if (onboarding) {
      setLocalData({
        timeDayPreference: {
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          sun: false,
          morning: false,
          afternoon: false,
          evening: false,
        },
      })
      return
    }
    setLocalData(data)
  }, [data])
  if (isLoading) {
    return <></>
  }
  if (localData) {
    return (
      <>
        <Grid item xs={12}>
          <TitleC text="When would you like to GET TRAIND?" />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
            }}
          >
            {' '}
            Days of the week
          </Typography>
          <Grid
            container
            style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
            spacing={'20px'}
          >
            {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => (
              <Grid item>
                <BorderSelectButton
                  text={day}
                  active={formDayPreferences.includes(day)}
                  onClick={() => {
                    dispatch(
                      setUserProfileModalField({
                        field: 'trainingDayPreferences',
                        value: formDayPreferences.includes(day)
                          ? [...formDayPreferences].filter((d) => d !== day)
                          : [...formDayPreferences, day],
                      })
                    )
                  }}
                />
              </Grid>
            ))}
            <Grid item>
              <BorderSelectButton
                text="ANY DAY"
                active={
                  localData.mon &&
                  localData.tue &&
                  localData.wed &&
                  localData.thu &&
                  localData.fri &&
                  localData.sat &&
                  localData.sun
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          <Typography
            sx={{
              color: 'gunMetal.main',
              fontWeight: 700,
              fontSize: 17,
              lineHeight: '25.5px',
            }}
          >
            {' '}
            Time of day
          </Typography>
          <Grid
            container
            style={{ paddingTop: '16px', justifyContent: 'flex-start' }}
            spacing={'20px'}
          >
            {['Mornings', 'Afternoons', 'Evenings', 'Any Time'].map((time) => (
              <Grid item>
                <BorderSelectButton
                  text={time}
                  active={formTimePreferences.includes(time)}
                  onClick={() => {
                    dispatch(
                      setUserProfileModalField({
                        field: 'trainingTimePreferences',
                        value: formTimePreferences.includes(time)
                          ? [...formTimePreferences].filter((d) => d !== time)
                          : [...formTimePreferences, time],
                      })
                    )
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>
    )
  }
}

export default PreferredTrainingDaysTime
