import { Grid } from '@mui/material'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import DashboardMessagesPage from '../components/member-dashboard.js/DashboardMessagesPage'

function Messages() {
  return (
    <Grid container>
      <Header />
      <DashboardMessagesPage />
      <Footer />
    </Grid>
  )
}

export default Messages
